import React from "react"

import {MC} from './MC.js'
import {Widget} from "./Widget.jsx"

class Repeater extends React.Component {

  buildSubFields(subFields, disabled, readOnly) {
    let resolution = this.props.resolution
    let hrows = null
    if (Array.isArray(subFields)) {
      let rows = MC.splitFieldsIntoRows(subFields, resolution)
      hrows = []
      for (var i = 0; i < rows.length; i++) {
        var hrow = []
        for (var ii = 0; ii < rows[i].length; ii++) {
          var subField = rows[i][ii]
          let offsetDiv
          var grid = MC.getFieldGrid(subField, resolution)
          if (grid.offset > 0) {
            var cls = "ui " + MC.getFieldWideClassFromInt(grid.offset) + " wide column field"
            offsetDiv = <div className={cls} key={subField.rbsid + 'gap'}/>
          }
          hrow.push(<Widget key={subField.rbsid} widget={subField} ref={subField.rbsid} disabled={disabled} readOnly={readOnly} resolution={resolution} offsetDiv={offsetDiv}/>)
        }
        hrows.push(<div key={i} className="ui row">{hrow}</div>)
      }
    }
    return hrows
  }

  render() {
    let field = this.props.data
    let count = MC.getRowsCount(Array.isArray(field.fields) && field.fields[0] ? field.fields[0] : field)
    let widgets = []
    if (MC.showAtLeastOneIteration(field) && count == 0) {
      count = 1
    }
    let inline = MC.getFieldParamBooleanValue(this.props.data.param, '@inline')
    let inlineAuto = MC.getFieldParamBooleanValue(this.props.data.param, '@inlineAuto')
    const cls = MC.getFieldParamValue(this.props.data.param, '@cssClass')
    for (let i = 0; i < count; i++) {
      let inlineCss = {}
      let disabled = this.props.disabled
      let readOnly = this.props.readOnly
      let clsRow
      let subfields = Array.isArray(field.fields) && field.fields.length > 0 && field.fields[0].fields ? field.fields[0].fields : []
      if (field.fields[0].rows) {
        let row = field.fields[0].rows[i].param
        if (false === MC.getFieldParamBooleanValue(row, '@visible')) {
          inlineCss.display = 'none'
        }
        if (false === MC.getFieldParamBooleanValue(row, '@enabled') || false === MC.getFieldParamBooleanValue(row, '@permitted')) {
          disabled = true
        }
        if (true === MC.getFieldParamBooleanValue(row, '@readonly')) {
          readOnly = true
        }
        subfields = field.fields[0].rows[i].fields
        clsRow = MC.getFieldParamValue(row, '@cssClass')
      }
      let resolution = this.props.resolution
      let wideClass = "twelve"
      if (inline) {
        let grid = MC.getFieldGrid(field, resolution)
        wideClass = MC.getFieldWideClassFromInt(grid.columns)
      } else if (inlineAuto) {
        wideClass = "auto-inline"
      }
      const className = MC.classes("ui", wideClass, "wide column", cls, clsRow)
      widgets.push(<div key={i} className={className} style={inlineCss}><div className="ui twelve column grid stackable">{this.buildSubFields(subfields, disabled, readOnly)}</div></div>)
    }
    return (
    <div className="ui twelve column grid stackable" data-widget-i-name={field.id}>
      {widgets}
    </div>)
  }

}

export {Repeater}