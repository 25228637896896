import React from "react"

class DateTimePickerYears extends React.Component {

  render() {
    let year = parseInt(this.props.viewDate.year / 10, 10) * 10
    return (
      <div className="rdtYears">
        <table key="a">
          <thead>
            <tr>
              <th key="prev" className="rdtPrev" onClick={this.props.subtractTime(10, 'years')}><span>‹</span></th>
              <th key="year" className="rdtSwitch" onClick={this.props.showView('years')} colSpan={2}>{year + '-' + (year + 9)}</th>
              <th key="next" className="rdtNext" onClick={this.props.addTime(10, 'years')}><span>›</span></th>
            </tr>
          </thead>
        </table>
        <table key="years"><tbody>{this.renderYears(year)}</tbody></table>
      </div>
    )
  }

  renderYears(year) {
    let self = this
    let years = []
    let i = -1
    let rows = []
    let selectedDate = this.props.selectedDate
    year--
    while (i < 11) {
      let classes = 'rdtYear'
      let currentYear = this.props.viewDate.set({ year: year, month: 1, day: 1 })
      let noOfDaysInYear = currentYear.endOf('year').toFormat('o')
      let daysInYear = Array.from({ length: noOfDaysInYear }, function (e, i) {
        return i + 1
      })
      let validDay = daysInYear.find(function (d) {
        let day = currentYear.set({ ordinal: d })
        return self.props.isValidDay(day)
      })
      let isDisabled = (validDay === undefined)
      if (isDisabled)
        classes += ' rdtDisabled'
      if (selectedDate && selectedDate.year === year)
        classes += ' rdtActive'
      let props = {
        key: year,
        'data-value': year,
        className: classes
      }
      if (!isDisabled)
        props.onClick = (this.props.updateOn === 'years' ? this.updateSelectedYear : this.props.setDate('year'))
      years.push(this.renderYear(props, year))
      if (years.length === 4) {
        rows.push(<tr key={i}>{years}</tr>)
        years = []
      }
      year++
      i++
    }
    return rows
  }

  updateSelectedYear = (event) => {
    this.props.updateSelectedDate(event, true)
  }

  renderYear(props, year) {
    return <td {...props}>{year}</td>
  }

}

export default DateTimePickerYears