import React from "react"

import {Interactive} from '../modeler/Interactive.js'

import {MC} from './MC.js'

class Link extends React.Component {

  handleClick = (e) => {
    const field = this.props.data
    const escapeTitleHtml = MC.getFieldParamBooleanValue(field.param, '@escapeTitleHtml')
    if (!escapeTitleHtml && e.target.nodeName !== 'A') {
      return
    }
    if (MC.isFunction(this.props.onClick) && !MC.isModelerActive(this.props.data)) {
      this.props.onClick()
    }
    if (this.props.handleEvents) {
      MC.handleReactEvent(this.props.data, e)
    }  
    const behavior = MC.getFieldParamValue(field.param, '@behavior')
    if (behavior == 'url') {
      const url = MC.getFieldParamValue(field.param, '@url')
      if (!MC.isNull(url)) {
        let target = MC.getFieldParamValue(field.param, '@target')
        if (['blank', 'parent', 'top'].indexOf(target) > -1) {
          return
        }
        field.flow.reactFlow().routeTo(e, url)
        return
      }
    } else if (behavior == 'dialog') {
      field.flow.callDialog(field)
    } else {
      if (MC.isFunction(this.props.data.onSubmit) && !MC.isModelerActive(this.props.data)) {
        this.props.data.onSubmit(this.props.data)
      }
    } 
  }

  render() {
    const field = this.props.data
    let title = this.props.value
    const labelPlacement = MC.getFieldParamValue(field.param, '@titlePlacement')
    if (labelPlacement == 'IN') {
      title = MC.getFieldParamValue(field.param, '@title')
    }
    let cls
    if (this.props.cls) {
      cls = this.props.cls
    } else {
      cls = MC.getFieldParamValue(field.param, '@cssClass')
      cls = cls ? 'ui link curpointer ' + cls : 'ui link curpointer'
    }
    let label
    let onClick = this.handleClick
    let href = null
    let behavior = MC.getFieldParamValue(field.param, '@behavior')
    let imageUrl = MC.getFieldParamValue(field.param, '@imageUrl')
    if (!MC.isNull(imageUrl)) {
      imageUrl = MC.rebaseUrl(field.flow.flow.model, imageUrl)
      label = <img src={imageUrl}/>
    } else {
      let icon = MC.getFieldParamValue(field.param, '@icon')
      if (!MC.isNull(icon) && icon != "") {
        label = <i className={'icon ' + icon} key="icon"></i>
      }
    }
    if (this.props.disabled) {
      return <span id={field.rbsid} className={cls} ref={field.rbsid}>{label}{title}</span>
    } else {  
      if (behavior == 'url') {
        href = MC.getFieldParamValue(field.param, '@url')
      }
      let target = MC.getFieldParamValue(field.param, '@target')
      if (['blank', 'parent', 'top'].indexOf(target) > -1) {
        target = '_' + target
      } else {
        target = null
      }
      let onMouseDown
      let onMouseMove
      if (MC.isModelerReactAvailable(field) && this.props.handleEvents) {
        let modelerReact = MC.getModelerReact(field)
        cls += Interactive.getInteractiveClasses.bind(modelerReact)(field)
        onMouseDown = Interactive.handleMouseDown.bind(modelerReact, field)
        onMouseMove = Interactive.handleMouseMove.bind(modelerReact, field, this)
      }
      var escapeTitleHtml = MC.getFieldParamBooleanValue(field.param, '@escapeTitleHtml')
      if (escapeTitleHtml) {
        if (this.props.handleEvents) {
          return <a className={cls} id={field.rbsid} ref={field.rbsid} onClick={this.handleClick} onMouseDown={onMouseDown} onMouseMove={onMouseMove} href={href} target={target}
               onFocus={MC.handleReactEvent.bind(this, field)} onBlur={MC.handleReactEvent.bind(this, field)} onMouseEnter={MC.handleReactEvent.bind(this, field)}
               onMouseLeave={MC.handleReactEvent.bind(this, field)} data-widget-i-name={field.id}>{label}{title}</a>
        } else {
          return <a className={cls} id={field.rbsid} ref={field.rbsid} onClick={onClick} onMouseDown={onMouseDown} href={href} target={target} data-widget-i-name={field.id}>{label}{title}</a>
        }
      } else {
        if (this.props.handleEvents) {
          return <div className={cls} id={field.rbsid} ref={field.rbsid} onClick={this.handleClick} onMouseDown={onMouseDown} onMouseMove={onMouseMove}
                      onFocus={MC.handleReactEvent.bind(this, field)} onBlur={MC.handleReactEvent.bind(this, field)} onMouseEnter={MC.handleReactEvent.bind(this, field)}
                      onMouseLeave={MC.handleReactEvent.bind(this, field)} dangerouslySetInnerHTML={{__html: MC.customHtml(title)}}  data-widget-i-name={field.id}></div>
        } else {
          return <div className={cls} id={field.rbsid} ref={field.rbsid} onClick={onClick} onMouseDown={onMouseDown} dangerouslySetInnerHTML={{__html: MC.customHtml(title)}} data-widget-i-name={field.id}></div>
        }
      }
    }
  }

}

export {Link}