import React from "react"

import {MC} from './MC.js'

class MenuButton extends React.Component {

  buildButtons(field) {
    let buttons = []
    let items = MC.getFieldParamValue(field.param, 'items')
    if (!items || !Array.isArray(items)) {
      return null
    }
    for (let item of items) {
      let icon = null
      if (!MC.isNull(item['@imageUrl'])) {
        let imageUrl = MC.rebaseUrl(field.flow.flow.model, item['@imageUrl'])
        icon = <img src={imageUrl} className={item['@icon']}/>
      } else if (!MC.isNull(item['@icon'])) {
        icon = <i className={item['@icon']}></i>
      }
      let btnCls = MC.classes("ui button", {"disabled": item['@enabled'] === false || item['@permitted'] === false})
      buttons.push(<button type="button" key={'b' + item['@key']} title={item['@tooltip']} className={btnCls} onClick={() => this.handleClick(item)}>{icon}{item['@title']}</button>)
    }
    return buttons
  }

  handleClick(item) {
    let field = this.props.data
    MC.putFieldParamValue(field.param, 'value', item['@key'])
    let behavior = MC.getFieldParamValue(field.param, '@behavior')
    if (behavior == 'url') {
      let url = item['@url']
      if (window && !MC.isNull(url) && url !== '') {
        let newWindow =  MC.getFieldParamBooleanValue(field.param, '@newWindow')
        if (newWindow) {
          window.open(url)
        } else {
          window.location.href = url
        }
      }
    } else if (behavior == 'dialog') {
      field.flow.callDialog(field)
    } else {
      if (MC.isFunction(field.onSubmit) && !MC.isModelerActive(field)) {
        field.onSubmit(field)
      }
    }
  }

  render() {
    let field = this.props.data
    let fitWidth = MC.getFieldParamBooleanValue(field.param, '@fitWidth')
    let type =  MC.getFieldParamValue(field.param, '@type')
    let cls = MC.getFieldParamValue(field.param, '@cssClass')
    cls = MC.classes('ui buttons', cls, {'fluid': fitWidth, 'vertical': type == 'vertical'})
    return (
      <div className={cls} data-widget-i-name={field.id}>
        {this.buildButtons(field)}
      </div>
    )
  }

}

export {MenuButton}