import React from "react";

class ErrorPage extends React.Component {

  render() {
    try {
      var error = this.props.app.getError();
      var mainClasses = 'ui container';
      if (typeof this.props.inline != 'undefined' && this.props.inline == true) {
        mainClasses = '';
      }
      let errMsg = "";
      if (this.props.app.getDebug()) {
        errMsg = <p>{error.msg}</p>;
      }
      return (
        <div className={mainClasses} style={{marginTop: '20px'}}>
          <div className="ui icon negative red large message own-error">
            <i className="bug icon"></i>
            <div className="content">
              <div className="header">
                Error occured!
              </div>
              {errMsg}
            </div>
          </div>
        </div>
      );
    } catch (err) {
      throw new Error('Error occured: ' + err.message);
    }
  }

}

export {ErrorPage};
