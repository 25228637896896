import React from "react"
import ReactDOM from "react-dom"

import {MC} from './MC.js'

class Upload extends React.Component {

  state = {drag: false}
  dropRef = React.createRef()

  handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const props = this.props
      const field = props.field
      const widget = props.widget
      field.param.value = []
      const files = e.target.files
      let i = 0
      for (let file of files) {
        let newRecord = {}
        newRecord['@fileName'] = file.name
        newRecord['@contentType'] = file.type
        newRecord['@size'] = file.size
        newRecord['@relativePath'] = file.webkitRelativePath ? file.webkitRelativePath : file.name
        let reader = new FileReader()
        reader.onload = function(e) {
          newRecord['@data'] = e.target.result.substring(e.target.result.indexOf(';base64,')+8)
          field.param.value[i] = newRecord
          if (i == files.length-1) {
            MC.handleEvent(field, 'change')
            if (field.flow && field.flow.context.data.env.cfg && field.flow.context.data.env.cfg['fl:validationStyle'] === 'blur') {
              widget.revalidate(true)
            } else {
              widget.revalidate()
            }
          }
          i++
        }
        reader.readAsDataURL(file)
      }  
    }
  }

  handleUploadClick = () => {
    ReactDOM.findDOMNode(this.refs.input).value = null
    ReactDOM.findDOMNode(this.refs.input).click()
  }

  handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  checkList(e) {
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      const props = this.props
      const field = props.field
      const multiple = MC.getFieldParamBooleanValue(field.param, '@multiple')
      const directory = MC.getFieldParamBooleanValue(field.param, '@directory') ? 'true' : null
      if (!multiple && e.dataTransfer.items.length > 1) {
        return false
      }
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        const entry = e.dataTransfer.items[i].webkitGetAsEntry()
        if (entry) {
          if (entry.isDirectory && !directory) {
            return false
          }
        }
      }
      return true
    }
    return false
  }

  traverseFileTree(item, iterationObj, path) {
    const props = this.props
    const field = props.field
    const widget = props.widget
    path = path || ""
    if (item.isFile) {
      item.file((file) => {
        let newRecord = {}
        newRecord['@fileName'] = file.name
        newRecord['@contentType'] = file.type
        newRecord['@size'] = file.size
        newRecord['@relativePath'] = path ? path + file.name : file.name
        const reader = new FileReader()
        reader.onload = function(e) {
          newRecord['@data'] = e.target.result.substring(e.target.result.indexOf(';base64,')+8)
          const iterationToSaveLocal = iterationObj.val
          iterationObj.val = iterationObj.val + 1
          field.param.value[iterationToSaveLocal] = newRecord
          MC.handleEvent(field, 'change')
          if (field.flow && field.flow.context.data.env.cfg && field.flow.context.data.env.cfg['fl:validationStyle'] === 'blur') {
            widget.revalidate(true)
          } else {
            widget.revalidate()
          }
        }
        reader.readAsDataURL(file)
      })
    } else if (item.isDirectory) {
      let dirReader = item.createReader()
      dirReader.readEntries((entries) => {
        for (let i=0; i<entries.length; i++) {
          this.traverseFileTree(entries[i], iterationObj, path + item.name + "/")
        }
      })
    }
  }

  handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter++
    if (this.checkList(e)) {
      this.setState({drag: true})
    }
  }

  handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter--
    if (this.dragCounter === 0) {
      this.setState({drag: false})
    }
  }

  handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({drag: false})
    if (this.checkList(e)) {
      const props = this.props
      const field = props.field
      field.param.value = []
      let items = e.dataTransfer.items
      let iterObj = {val: 0}
      for (let i=0; i<items.length; i++) {
        let item = items[i].webkitGetAsEntry()
        if (item) {
          this.traverseFileTree(item, iterObj)
        }
      }      
      e.dataTransfer.clearData()
      this.dragCounter = 0    
    }
  }

  render() {
    const props = this.props
    const field = props.field
    let innerDisabled = props.disabled
    if (MC.isModelerActive(field)) {
      innerDisabled = true
    }
    const buttonTitle = MC.getFieldParamValue(field.param, '@buttonTitle')
    let placeholder = props.placeholder
    let isSelectedFile = false
    let values = MC.getFieldParamValue(field.param, 'value')
    if (Array.isArray(values) && values.length > 0) {
      isSelectedFile = true
      if (values.length > 8) {
        placeholder = values.slice(0, 8).map((val) =>  val['@fileName']).join(', ') + ' ... (' +  values.length + ')'
      } else {
        placeholder = values.map((val) =>  val['@fileName']).join(', ')
      }
    }
    let icon = MC.getFieldParamValue(field.param, '@icon')
    if (!MC.isNull(icon) && icon !== "") {
      icon = <i className={icon}></i>
    } else {
      icon = null
    }
    const multiple = MC.getFieldParamBooleanValue(field.param, '@multiple')
    const accept = MC.getFieldParamValue(field.param, '@accept')
    const directory = MC.getFieldParamBooleanValue(field.param, '@directory') ? 'true' : null
    const showAsDropZone = MC.getFieldParamBooleanValue(field.param, '@showAsDropZone')
    if (showAsDropZone) {
      const dropZoneHeight = MC.getFieldParamValue(field.param, '@dropZoneHeight') || '200px'
      let style = {height: dropZoneHeight}
      let dragClass = this.state.drag ? ' drag' : ''
      if (isSelectedFile) {
        dragClass += ' selected'
      }
      return (
        <div ref={this.dropRef} style={style} className={'mnc-file-drop-zone' + dragClass} data-widget-i-name={field.id} onDragEnter={this.handleDragIn} onDragLeave={this.handleDragOut} 
          onDragOver={this.handleDrag} onDrop={this.handleDrop} onClick={this.handleUploadClick}>
          <div className={'mnc-file-drop-zone-placeholder' + dragClass}>{placeholder}</div>
          <input key="input" ref="input" type="file" onChange={this.handleFileChange} style={{display: 'none'}} multiple={multiple} accept={accept} webkitdirectory={directory}/>
        </div>)
    } else {
      return (
        <div>
          <button key="button" id={props.htmlId} className="upload-button ui button" onClick={this.handleUploadClick} disabled={innerDisabled} readOnly={props.readOnly}>{icon}{buttonTitle}</button>
          <span key="span" className="upload-file-name">{placeholder}</span>
          <input key="input" ref="input" data-widget-i-name={field.id} type="file" onChange={this.handleFileChange} style={{display: 'none'}} multiple={multiple} accept={accept} webkitdirectory={directory}/>
        </div>
      )
    }
  }

}

export {Upload}