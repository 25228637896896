import {FieldDef} from "./FieldDef.js"
import {MC} from "../client/MC.js"
import {ReactFlow} from "../client/ReactFlow.jsx"

const WidgetModel = {
    load: function(contextmodel, lang) {
      let p1 = MC.callServer('GET', ReactFlow.flowServerUrl + 'miniclientcfg?name=API_WidgetModelGet&inputdata=' + encodeURIComponent(JSON.stringify({model: contextmodel, lang: lang})), MC.getJsonType()).then(function (res) {
        let model =  JSON.parse(res.content)['rbs:item'][0]
        WidgetModel.ensureArray(model, ['def:WidgetProperty'])
        this.groups = model["def:group"] || []
        this.widgets = model["def:widget"]
      }.bind(this)).catch(function(errorThrown) {
        console.log(errorThrown)
      })
      let p2 = MC.callServer('GET', ReactFlow.flowServerUrl + 'miniclientcfg?name=API_StaticValueListList&inputdata=' + encodeURIComponent(JSON.stringify({model: contextmodel})), MC.getJsonType()).then(function (res) {
        this.staticValues = res.content && (res.status == 200 || res.status == 204) ? MC.asArray(JSON.parse(res.content).staticValueList) : []
      }.bind(this)).catch (function () {
        this.staticValues = []
      }.bind(this))
      return Promise.all([p1, p2])
    },
    getStaticValuesNames: function() {
      return this.staticValues.map(sv => sv.name);
    },
    getGroupByRef: function (groupRef) {
      var path = groupRef["rbs:ref"].split("/");
      var groupId = path[path.length - 1];
      var group = this.groups.find(function(group) {
        return group["def:id"] == groupId;
      });
      return group["def:title"];
    },
    getGroups: function() {
      return this.groups.map(function(group) {
        return group["def:id"];
      })
    },
    getGroupsNames: function() {
      var names = {basic: "Základní", grid: "Grid", column: "Sloupec", others: "Ostatní"};
      this.groups.forEach(function(group) {
        names[group["def:id"]] = group["def:title"];
      });
      return names;
    },
    getGroupId: function(groupRef) {
      var path = groupRef["rbs:ref"].split("/");
      return path[path.length - 1];
    },
    getParamOptionsHelp: function (path, properties) {
      var self = this;
      var result = [];
      properties.filter(function(property) {
        return property["def:modeled"] == "true";
      }).forEach(function(property){
        var optionName = property["def:name"];
        var propertyPath = path.concat([optionName]);
        var name = propertyPath.slice(1).join("/");
        var groupRef; // = property["def:group"];
        var group;
        if (typeof groupRef == "undefined") {
          group = "others";
        } else {
          group = self.getGroupId(groupRef);
        }
        var option = {path: propertyPath, name: name, enum: property["def:enum"], dataType: property["def:type"], group: group};

        var isPropertySimple = typeof property["def:WidgetProperty"] == "undefined";
        var type = property["def:type"];
        var subProperties = [];
        if (isPropertySimple || type == "staticValues") {
          result.push(option);
        }
        if (!isPropertySimple && ["rows*", "columns"].indexOf(optionName) == -1) {
            subProperties = property["def:WidgetProperty"];
            var options = self.getParamOptionsHelp(propertyPath, subProperties);
            result = result.concat(options);
        }
      });
      return result;
    },
    getParamOptions: function (field) {
      var paramDescription =  this.getFieldParams(field);
      return this.getParamOptionsHelp(["param"], paramDescription);
    },
    getColumnsOptions: function() {
      let paramDescription = this.getFieldParams({widget: 'table'})
      let columnsProp = paramDescription.find(fp => fp["def:name"] == "columns")
      if (columnsProp && columnsProp['def:WidgetProperty']) {
        let columnsPars = columnsProp['def:WidgetProperty'].filter(function(property) {
          return property["def:modeled"] == "true"
        })
        return columnsPars
      }
      return []
    },
    getFieldParams: function (field) {
      var fieldDescription = this.widgets.find(fp => fp["def:name"] == field.widget);
      if (!fieldDescription) {
          return [];
      }
      return fieldDescription["def:WidgetProperty"];
    },
    deleteUnsupportedParamsHelp: function(param, paramDefinition, paramDescription, field) {
      var self = this;

      var isParamDescribed = function(paramName) {
        return paramDescription.find(p => p["def:name"] == paramName);
      }
      if (param) {
        Object.keys(param).forEach(function (paramName) {
            if (!isParamDescribed(paramName)) {
              if (field.isInTable() && paramName == 'columns') {
                return
              }
              delete param[paramName]
            }
        })
      }
      if (paramDefinition) {
        var paramDefinitionCopy = paramDefinition.slice();
        paramDefinitionCopy.forEach(function(paramDef) {
          if (!isParamDescribed(paramDef.name)) {
            if (field.isInTable() && paramDef.name == 'columns') {
              return
            }
            var index = paramDefinition.indexOf(paramDef);
            paramDefinition.splice(index, 1);
          }
        });
      }

      paramDescription.forEach(function(paramDesc) {
        if (typeof paramDesc["def:WidgetProperty"] == "undefined") {
          return;
        }
        var innerParamDescription = paramDesc["def:WidgetProperty"];
        var innerParamDefinition;
        if (paramDefinition) {
          var parDef = paramDefinition.find(function(pd) {
            return paramDesc["def:name"] == pd.name;
          });
          if (parDef) {
            innerParamDefinition = parDef.FormFieldParam;
          }
        }
        var innerParam;
        if (param) {
          innerParam = param[paramDesc["def:name"]];
        }
        self.deleteUnsupportedParamsHelp(innerParam, innerParamDefinition, innerParamDescription, field);
      });
    },
    deleteUnsupportedParams: function(field) {
      var paramDescription = this.getFieldParams(field);
      this.deleteUnsupportedParamsHelp(field.param, field.FormField.FormFieldParam, paramDescription, field);
    },
    getNotAbstractWidgets: function() {
      return this.widgets.filter(item => item["def:abstract"] == "false");
    },
    getFieldList: function () {
        return this.getNotAbstractWidgets().map(item => item["def:name"]);
    },
    getDefaultProperties: function(property) {
      var self = this;
      var result = {};
      property["def:WidgetProperty"].filter(function(property) {
        return property["def:modeled"] == "true";
      }).forEach(function(p){
        var name = p["def:name"];
        if (typeof p["def:default"] != "undefined") {
          var value = p["def:default"];

          if (p["def:type"] == "boolean") {
            result[name] = (value == "true");
          } else {
            result[name] = value;
          }
        }
        if (name == "columns") { // Sloupce tabulky jsou výjimka.
          //result[name] = {};
        } else if (typeof p["def:WidgetProperty"] != "undefined") {
          var defprop = self.getDefaultProperties(p);
          if (Object.getOwnPropertyNames(defprop).length != 0) {
            result[name] = defprop;
          }
        }
      });
      return result;
    },
    getDefaultPropertiesDefinition: function(property) {
      var self = this;
      var result = [];
      property["def:WidgetProperty"].filter(function(property) {
        return property["def:modeled"] == "true";
      }).forEach(function(p){
        var name = p["def:name"];
        var value = p["def:default"];
        if (typeof value != "undefined") {
          result.push({name: name, val: value});
        }
        if (name == "columns") { // Sloupce tabulky jsou výjimka.
          //result.push({name: name, FormFieldParam: []});
        } else if (typeof p["def:WidgetProperty"] != "undefined") {
          var defprop = self.getDefaultPropertiesDefinition(p);
          var name = p["def:name"];
          if (defprop.length != 0) {
            result.push({ name: name, FormFieldParam: defprop});
          }
        }
      });
      return result;
    },
    getFieldsPalette: function() {
      var self = this;
      var widgets = this.getNotAbstractWidgets();
      return widgets.map(function(widget){
        var field = {};
        var properties = self.getDefaultProperties(widget);
        var propertiesDefinitions = self.getDefaultPropertiesDefinition(widget);
        field.param = properties;
        field.FormField = {};
        field.FormField.FormFieldParam = propertiesDefinitions;
        field.FormField.fwidget = widget["def:name"];
        field.widget = widget["def:name"];
        var nick = widget["def:nickname"];
        if (typeof nick == 'undefined') {
          nick = widget["def:name"].substring(0,3);
        }
        field.FormField.name = nick;
        field.id = nick;
        field.fields = [];
        field.grid = [{"columns":"3","index":"1","offset":"0","resolution":"medium","visible":"true","newLineAfter":"yes"},
                      {"columns":"12","index":"1","offset":"0","resolution":"x-small","visible":"true","newLineAfter":"yes"}];
        if (-1 != ["basictable", "loadabletable", "pageabletable", "scrollabletable", "repeater"].indexOf(field.widget)) {
          field.fields = [{id: "rows*", fields: [], rbsid: "dummy-rows", widget: "dummy", param: {}}];
        }
        FieldDef.setProto(field);
        field.setOption(["param", "@title"], widget["def:name"]);
        field.setOption(["param", "@enabled"], true);

        return field;
      })
    },
    findWidgetByName: function(name) {
      return this.widgets.find(w=> w["def:name"] == name);
    },
    findWidgetById: function(id) {
      return this.widgets.find(w=> w["rbs:id"] == id);
    },
    widgetGetProperty: function(widget, property) {
      if (typeof widget[property] == "undefined") {
        return [];
      }
      if (widget[property] instanceof Array) {
        return widget[property];
      }
      return [widget[property]];
    },
    widgetGetParents: function(widget) {
      var self = this;
      var parentIds = this.widgetGetProperty(widget, "def:extends");

      return parentIds.map(function(id) {
        return self.findWidgetById(id["rbs:ref"])
      });
    },
    isExtensionOfHelp: function(childs, parent) {
      if (childs.length == 0) {
        return false;
      }
      var child = childs[0];
      var rest = childs.slice(1);
      if (child == parent) {
        return true;
      }
      var childParents = this.widgetGetParents(child);
      return this.isExtensionOfHelp(childParents.concat(rest), parent);
    },
    isExtensionOf: function(childWidget, parentWidget) {
      return this.isExtensionOfHelp([childWidget], parentWidget);
    },
    getSupportedChilds: function(widget) {
      var self = this;
      var supportedChildIds = this.widgetGetProperty(widget,"def:supportedChild");
      var supportedChilds = supportedChildIds.map(function(id){
        return self.findWidgetById(id["rbs:ref"]);
      })
      return supportedChilds;
    },
    isSupportedChild: function(childName, parentName) {
      if (typeof parentName == "undefined") {
        return true;
      }
      if (["basictable", "loadabletable", "pageabletable", "scrollabletable"].indexOf(childName) > -1 && parentName == "repeater") {
        return false;
      }
      var self = this;
      var child = this.findWidgetByName(childName);
      var parent = this.findWidgetByName(parentName);
      if (typeof child == "undefined" || typeof parent == "undefined") {
        return false;
      }
      var supportedChilds = this.getSupportedChilds(parent);
      return supportedChilds.some(function (supportedChild) {
        return self.isExtensionOf(child,supportedChild);
      });
    },
    updateAfterWidgetWasChanged: function(field) {
      var parent = field.getTrueParent();
      if (!WidgetModel.isSupportedChild(field.widget, parent.widget)) {
        field.removeFromContainer();
        return undefined;
      }

      WidgetModel.deleteUnsupportedParams(field);

      var fieldWidget = WidgetModel.findWidgetByName(field.widget);
      var repeatable = WidgetModel.findWidgetByName("repeatable");
      var isRepeatable = WidgetModel.isExtensionOf(fieldWidget, repeatable);
      var hasDummy = field.hasDummy();
      if (isRepeatable && !hasDummy) {
        var fields = field.fields;
        field.fields = [FieldDef.makeDummy(field.flow)];
        field.setSubfields(fields)
      }
      if (!isRepeatable && hasDummy) {
        var fields = field.fields[0].fields
        field.fields = [];
        field.setSubfields(fields);
      }

      var subfields = field.getSubfields();
      field.setSubfields(subfields.filter(function(subfield) {
        return WidgetModel.isSupportedChild(subfield.widget, field.widget);
      }));

      var defaultProperties = WidgetModel.getDefaultProperties(fieldWidget);
      var defaultPropertiesDefinition = WidgetModel.getDefaultPropertiesDefinition(fieldWidget);

      field.param = MC.extend(true, defaultProperties, field.param);
      field.addMissingPropertiesDefinition(defaultPropertiesDefinition);

      if (["basictable", "loadabletable", "pageabletable", "scrollabletable"].indexOf(field.widget) > -1) {
        field.param.columns= {};
        var subfields = field.getSubfields();
        subfields.forEach(function(subfield) {
          let fieldColumn = this.getTableColumnDefaults(subfield.getOption(["param", "@title"]))
          subfield.param.columns = fieldColumn;
          field.param.columns[subfield.id] = fieldColumn;
        });
    }
  },
  ensureArray: function(object, checkKeys) {
    if (MC.isPlainObject(object)) {
      for (let key in object) {
        if (object.hasOwnProperty(key)) {
          if (MC.isPlainObject(object[key]) || Array.isArray(object[key])) {
            WidgetModel.ensureArray(object[key], checkKeys)
          }
          if (checkKeys.indexOf(key) >= 0 && !Array.isArray(object[key])) {
            object[key] = [object[key]]
          }
        }
      }
    } else if (Array.isArray(object)) {
      for (let i=0; i<object.length; i++) {
        WidgetModel.ensureArray(object[i], checkKeys)
      }
    }
  },
  getTableColumnDefaults: function(title) {
    let col = {"@title": title}
    for (let opt of this.getColumnsOptions()) {
      if (opt['def:modeled'] == 'true' && opt['def:default']) {
        if (opt['def:type'] == 'boolean') {
          col[opt['def:name']] = opt['def:default'] === 'true'
        } else {
          col[opt['def:name']] = opt['def:default']
        }
      }
    }
    return col
  }
}

export {WidgetModel}