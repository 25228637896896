import React from "react"
import ReactDOM from "react-dom"

import {MC} from './MC.js'

class Camera extends React.Component {

  stream = null
  state = {state: 'starting'}

  componentDidUpdate() {
    if (!MC.isModelerActive(this.props.data) && this.props.data.flow.reactFlow().state.start) {
      if (this.state.state == 'captured') {
        this.setState({state: 'video'})
      }
    }
  }

  componentDidMount() {
    var self = this
    if (MC.isModelerActive(this.props.data)) {
      return
    }
    if (MC.getFieldParamBooleanValue(this.props.data.param, '@focusArea')) {
      ReactDOM.findDOMNode(this.refs.video).addEventListener('loadeddata', function() { self.drawFocuser() }, false)
    }
    this.initVideo()
  }

  componentWillUnmount() {
    if (MC.isModelerActive(this.props.data)) {
      return
    }
    ReactDOM.findDOMNode(this.refs.video).pause()
    if (this.stream != null) {
      this.stream.getVideoTracks()[0].stop()
    }
  }

  initVideo() {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      this.setState({state: 'notSupportedInBrowser'})
      return
    }
    var self = this
    var video = ReactDOM.findDOMNode(this.refs.video)
    var videoProps = {facingMode: "environment", width: 1920}
    navigator.mediaDevices.getUserMedia({video: videoProps, audio: false}).then(function(stream) {
      self.stream = stream
      try {
        video.srcObject = stream
      } catch (error) {
        video.src = window.URL.createObjectURL(stream)
      }
      video.play()
      self.setState({state: 'video'})
    }).catch(function(e) {
      self.setState({state: 'error'})
    })
  }

  drawFocuser() {
    var ratioW = Number(MC.getFieldParamValue(this.props.data.param, '@focusAreaRatioWidth')).valueOf()
    var ratioH = Number(MC.getFieldParamValue(this.props.data.param, '@focusAreaRatioHeight')).valueOf()
    var video = ReactDOM.findDOMNode(this.refs.video)
    var canvasOver = ReactDOM.findDOMNode(this.refs.overlayCanvas)
    canvasOver.width = video.clientWidth
    canvasOver.height = video.clientHeight
    var rectHeight = Math.floor(canvasOver.height * 0.9)
    var rectY = Math.floor(canvasOver.height * 0.05)
    var rectWidth = Math.floor(ratioW / ratioH * rectHeight)
    var rectX = ( (canvasOver.width - rectWidth) / 2)
    if (rectWidth > canvasOver.width * 0.9) {
      rectWidth = Math.floor(canvasOver.width * 0.9)
      rectX = Math.floor(canvasOver.width * 0.05)
      rectHeight = Math.floor(ratioH / ratioW * rectWidth)
      rectY = ( (canvasOver.height - rectHeight) / 2)
    }
    var ctx = canvasOver.getContext("2d")
    /*ctx.strokeStyle = '#008000'
    ctx.lineWidth=3
    ctx.rect(rectX, rectY, rectWidth, rectHeight)
    ctx.stroke()
    */
    ctx.fillStyle='rgb(0, 0, 0)'
    ctx.globalAlpha = 0.5
    ctx.beginPath()
    ctx.moveTo(0, 0)
    ctx.lineTo(rectX, rectY)
    ctx.lineTo(rectX, rectY + rectHeight)
    ctx.lineTo(rectX + rectWidth, rectY + rectHeight)
    ctx.lineTo(rectX + rectWidth, rectY)
    ctx.lineTo(rectX, rectY)
    ctx.lineTo(0, 0)
    ctx.lineTo(canvasOver.width, 0)
    ctx.lineTo(canvasOver.width, canvasOver.height)
    ctx.lineTo(0, canvasOver.height)
    ctx.lineTo(0, 0)
    ctx.fill()
  }

  snapPhoto = () => {
    if (this.state.state == 'captured') {
      MC.putFieldParamValue(this.props.data.param, 'value', null)
      this.setState({state: 'video'})
    } else {
      var video = ReactDOM.findDOMNode(this.refs.video)
      var canvasFull = ReactDOM.findDOMNode(this.refs.canvasFull)
      canvasFull.width = video.videoWidth
      canvasFull.height = video.videoHeight
      var context = canvasFull.getContext('2d')
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
      var data = canvasFull.toDataURL('image/jpeg', 1.0)
      MC.putFieldParamValue(this.props.data.param, 'value', data.substring(data.indexOf(';base64,')+8))
      var canvas = ReactDOM.findDOMNode(this.refs.canvas)
      canvas.width = video.clientWidth
      canvas.height = video.clientHeight
      var destContext = canvas.getContext('2d')
      destContext.drawImage(canvasFull, 0, 0, video.clientWidth, video.clientHeight)
      if (MC.isFunction(this.props.data.onSubmit)) {
        this.props.data.onSubmit(this.props.data)
      }
      this.setState({state: 'captured'})
    }
    MC.handleEvent(this.props.data, 'change')
  }

  render() {
    var warning
    if (['starting', 'findingCamera', 'error', 'notSupportedInBrowser'].indexOf(this.state.state) > -1) {
      if (MC.getLang() == 'cs') {
        switch (this.state.state) {
          case 'starting':  warning = <div className="ui message">Probíhá inicializace...</div>; break;
          case 'notSupportedInBrowser':  warning = <div className="ui red message">Váš prohlížeč nepodporuje kameru!</div>; break;
          case 'error':  warning = <div className="ui red message">Chyba při inicializaci kamery!</div>; break;
        }
      } else {
        switch (this.state.state) {
          case 'starting':  warning = <div className="ui message">Initialization is in progress...</div>; break;
          case 'notSupportedInBrowser':  warning = <div className="ui red message">Your browser does not support the camera!</div>; break;
          case 'error':  warning = <div className="ui red message">Camera initialization error!</div>; break;
        }
      }
    }
    var canvasCss = {position: 'relative'}
    var videoCss = {position: 'relative'}
    if (this.state.state != 'video') {
      videoCss.display = 'none'
    }
    if (this.state.state != 'captured') {
      canvasCss.display = 'none'
    }
    var className = 'mnc video'
    if (MC.getFieldParamBooleanValue(this.props.data.param, '@mirrored')) {
      className += ' mirrored'
    }
    return (
      <div data-widget-i-name={this.props.data.id}>
        {warning}
        <div style={videoCss}>
          <video className={className} ref="video" autoPlay/>
          <canvas ref="overlayCanvas" style={{width: '100% !important', height: 'auto !important', position: 'absolute', top:0, left: 0}}></canvas>
          <div style={{position: 'absolute', right: '5px', bottom: '15px'}}>
            <button className="ui circular icon huge basic yellow button" type="button" onClick={this.snapPhoto}><i className="photo icon"/></button>
          </div>
        </div>
        <div style={canvasCss}>
          <canvas ref="canvas"></canvas>
          <canvas ref="canvasFull" style={{display: 'none'}}></canvas>
          <div style={{position: 'absolute', right: '5px', bottom: '15px'}}>
            <button className="ui circular icon huge basic yellow button" type="button" onClick={this.snapPhoto}><i className="record icon"/></button>
          </div>
        </div>
        <label htmlFor={this.props.htmlId} className={this.props.helpCls} key="error">{this.props.help}</label>
      </div>
    )
  }

}

export {Camera}