import React from "react"

import {MC} from './MC.js'
import {Modal} from "./Modal.jsx"
import {ReactFlow} from "./ReactFlow.jsx"

class Dialog extends React.Component {

  state = {flowName: null, input: null, start: false}

  componentDidMount() {
    this.checkStart()
  }

  componentDidUpdate() {
    this.checkStart()
  }

  checkStart() {
    const props = this.props
    if (props.dialog && MC.isPlainObject(props.dialog) && props.dialog.start) {
      let dialog = props.dialog
      dialog.start = false
      if (!MC.isNull(props.dialog.flowName)) {
        this.setState({flowName: props.dialog.flowName, input: props.dialog.input, size: props.dialog.size, start: true})
      } else {
        this.setState({flowName: null, input: null, start: false})
      }
    } else if (this.state.start) {
      this.setState({start: false})
    }
  }

  close = () => {
    if (this.state.flowName) {
      this.setState({flowName: null, input: null})
      this.props.dialog.parentFlow.closeDialog()
    }
  }

  onEnd = (output, message) => {
    if (this.state.flowName) {
      this.setState({flowName: null, input: null})
      document.querySelector('body').classList.remove('showing-modal')
      this.props.dialog.parentFlow.endDialog(output, message)
    }
  }

  render() {
    var flowHtml = null
    if (this.state.flowName && this.props.dialog) {
      var flow = this.props.dialog.parentFlow
      flowHtml = <ReactFlow configuration={flow.confPath} flowName={this.state.flowName} env={flow.env} input={this.state.input} start={this.state.start} onEndFunction={this.onEnd} embedded={true} 
        options={flow.reactFlow().props.options} parent={flow.reactFlow()} configurationObject={flow.context.data.env.cfg} debug={flow.wantedLogLevel} console={false} autoScrollUp={false}/>
      return <Modal show={true} onClose={this.close} cssClass={this.state.size}>{flowHtml}</Modal>
    } else {
      return null
    }
  }

}

export {Dialog}