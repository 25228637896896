import {FieldDef} from "./FieldDef.js";
import {WidgetModel} from "./WidgetModel.js";

const Clipboard = {
    paseFieldsFromClipboard: function(modeler) {
      var storageValue = localStorage.getItem('formModelerClipboard');
      if (storageValue) {
        var clipboard = JSON.parse(storageValue);
        clipboard.forEach(function(field) {
          FieldDef.setProto(field);
          field.setFlow(modeler.state.form.flow);
          field.setParents();
          field.generateRbsId();
        })
        if (clipboard.length != 0) {
          modeler.store(modeler.state.form);
        }
        var selectedField;
        if (modeler.state.selectedFieldIds.length == 1) {
          selectedField = modeler.selectedFields()[0];
        }
        clipboard.forEach(function(field) {
          let target = selectedField ? selectedField : modeler.state.form
          if (WidgetModel.isSupportedChild(field.widget, target.widget)) {
            let copy = field.getCopy()
            target.setSubfields(target.getSubfields().concat([copy]))
            Clipboard.checkFieldIdUniquness(modeler,copy)
            if (target.isTable()) {
              let fieldColumn = WidgetModel.getTableColumnDefaults(copy.getOption(["param", "@title"]))
              copy.param.columns = fieldColumn
              if (field.id !== copy.id) { // set renamed columns of origin field to origin name, was renamed by checkFieldIdUniquness
                target.param.columns[field.id] = target.param.columns[copy.id]
              }
              for (let prop in fieldColumn) {
                copy.setOption(["param", "columns", prop], fieldColumn[prop])
              }
            }
          }
        })
        modeler.formWasChanged()
      }
    },
    checkFieldIdUniquness: function(modeler,field) {
      var form = modeler.state.form;
      var fields = form.findFields(field.id);
      if (fields.length != 1) {
        var count = 1;
        while (form.findField(field.id + count)) {
          count++;
        }
        var newFieldId = field.id + count;
        field.setOption(["id"], newFieldId);
      }
      field.getSubfields().forEach(function(subfield) {
        Clipboard.checkFieldIdUniquness(modeler, subfield);
      })
    },
    copySelectedFields: function(modeler) {
      var clipboard = modeler.selectedFields().map(function(field) {return field.getCopy()})
      clipboard.forEach(function(field) {
        field.unsetParents();
        field.unsetFlow();
        field.deleteAllRbsIds();
        field.removeTableParams()
      });
      localStorage.setItem('formModelerClipboard', JSON.stringify(clipboard));
    },
    deleteSelectedFields: function(modeler) {
      var stored = false;
      modeler.state.selectedFieldIds.forEach(function(fieldId) {
        var field = modeler.state.form.findFieldByRbsId(fieldId);
        if (field) {
          if (!stored) {
            modeler.store(modeler.state.form);
            stored = true;
          }
          field.removeField();
        }});
      modeler.setState({selectedFieldIds: []});
    }
};

export {Clipboard}