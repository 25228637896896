import React from "react"
import ReactDOM from "react-dom"
import MaskedInput from 'react-maskedinput'
import {DateTime} from "luxon"
import tippy from "tippy.js"
import NumberFormat from 'react-number-format'

import Dropdown from './semantic-widgets/Dropdown.jsx'
import Accordion from './semantic-widgets/Accordion.jsx'
import Checkbox from './semantic-widgets/Checkbox.jsx'
import Transition from './semantic-widgets/Transition.jsx'
import {Field} from "../modeler/Field.jsx";
import {EditableLabel} from "../modeler/EditableLabel.jsx";
import {Dummy} from "../modeler/Dummy.jsx";
import {MC} from './MC.js';
import {MCHistory} from "./MCHistory.js";
import {Button} from "./Button.jsx";
import {Camera} from "./Camera.jsx";
import {Repeater} from "./Repeater.jsx";
import {Table} from "./Table.jsx";
import {TabPanel} from "./TabPanel.jsx";
import {Slider} from "./Slider.jsx";
import {Label} from "./Label.jsx";
import {Download} from "./Download.jsx";
import {Media} from "./Media.jsx";
import {Link} from "./Link.jsx";
import {EmbeddedDialog} from "./EmbeddedDialog.jsx";
import {WhisperBox} from "./WhisperBox.jsx";
import {MenuButton} from "./MenuButton.jsx";
import {Upload} from "./Upload.jsx";
import {JdateFormat} from "./JdateFormat.js";
import Datetime from "./datetime/DateTime.jsx"
import Menu from "./Menu.jsx"
import Carousel from './carousel/Carousel.jsx'
import Breadcrumb from './Breadcrumb.jsx'
import ObjectInspector from './ObjectInspector.jsx'

import "./datetime/datetime.css"
import 'tippy.js/dist/tippy.css'
import 'tippy.js/dist/themes/light-border.css'

class Widget extends React.Component {

  state = {focus: false, visible: MC.isNull(MC.getFieldParamValue(this.props.widget.param, '@transition')) ? true : false, wideClass: 'twelve'}
  widgetRef = React.createRef()
  comboAdds = []

  componentDidMount() {
    this.updateState(this.props)
    this.afterUpdate()
    this.scriptedWidget()
    this.updateTooltip()
    this.conditionalForceValidate()
    if (MC.getFieldParamBooleanValue(this.props.widget.param, '@sticky') && !MC.isModelerActive(this.props.widget)) {
      window.addEventListener('scroll', this.handleStickyScroll)
    }
  }

  updateState(props) {
    let field = props.widget
    let visible = true // default is true
    let grid = MC.getFieldGrid(field, props.resolution)
    let wideClass = MC.getFieldWideClassFromInt(grid.columns)
    if (grid.visible === 'false' || grid.visible === false) {
      visible = false
    }
    if (MC.getFieldParamBooleanValue(field.param, '@visible') === false) {
      visible = false
    }
    if (MC.isModelerActive(field)) {
      if (field.isInteractive) {
        wideClass = ""
      }
      if (MC.isModelerInEyeMode(field)) {
        visible = true
      }
    }
    let focus = MC.getFieldParamBooleanValue(field.param, '@focused')
    if (focus) { // set focused false, for re-render caused by formlogic
      MC.putFieldParamValue(field.param, '@focused', false)
    }
    if (this.state.visible !== visible || this.state.wideClass !== wideClass || this.state.focus !== focus) {
      this.setState({visible: visible, wideClass: wideClass, focus: focus})
    }
  }

  componentDidUpdate() {
    this.updateState(this.props)
    this.afterUpdate()
    this.scriptedWidget()
    this.updateTooltip()
    this.conditionalForceValidate()
  }

  componentWillUnmount() {
    if (MC.getFieldParamBooleanValue(this.props.widget.param, '@sticky') && !MC.isModelerActive(this.props.widget)) {
      window.removeEventListener('scroll', this.handleStickyScroll)
    }
  }

  updateTooltip() {
    let tooltip = MC.getFieldParamValue(this.props.widget.param, '@tooltip')
    if (tooltip && tooltip !== this.tooltip) {
      this.tooltip = tooltip
      let tippyNode = ReactDOM.findDOMNode(this.refs.widgetroot)
      if (tippyNode) {
        let toolTipHere = tippyNode.querySelector('.tooltip-here')
      if (toolTipHere) {
        tippyNode = toolTipHere
      }
      if (tippyNode._tippy) {
        tippyNode._tippy.destroy()
      }
      tippy(tippyNode, {content: tooltip, arrow: true, delay: 400, animation: 'scale', theme: 'light-border'})
      } 
    }
  }

  scriptedWidget() {
    var field = this.props.widget;
    if (MC.isModelerActive(field)) {
      return;
    }
    if (field.widget == 'panel' && field.scriptedWidget && field.scriptedWidget.script ) {
      let widget;
      if (this.scriptedWidgetObject) {
        widget = this.scriptedWidgetObject
      } else {
        eval(field.scriptedWidget.script);
      }
      // run scripted widget
      if (widget) {
        if (MC.isFunction(widget.setValue)) {
          var input = MC.extend(true, {}, this.props.widget.param);
          widget.setValue(input);
        }
        if (!this.scriptedWidgetObject && MC.isFunction(widget.onCreate)) {
          const options = {iteration: MC.getFieldParamValue(field.param, '@iteration')}
          widget.onCreate(this.widgetRef.current, field, options)
        }
        this.props.widget.scriptedWidgetObject = widget;
        this.scriptedWidgetObject = widget
      }
    }
  }

  handleStickyScroll = () => {
    var div = ReactDOM.findDOMNode(this.refs.widgetroot);
    var parentCol = MC.findAncestor(div, 'widget');
    if (parentCol) {
      if (!this.isSticked) {
        this.stickyTop = MC.getElemCoords(div).top;
        var fixedHeader = document.getElementsByClassName('fixed-header-size');
        if (fixedHeader && fixedHeader.length === 1) {
          this.fixedHeaderHeight = fixedHeader[0].getBoundingClientRect().height;
        } else {
          this.fixedHeaderHeight = 0;
        }
      }
      var y = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      y = y + this.fixedHeaderHeight;
      if (y >= this.stickyTop) {
        var yD = y + div.getBoundingClientRect().height + 5;
        var stickyBottom = MC.getElemCoords(parentCol).top + parentCol.getBoundingClientRect().height;
        if (yD < stickyBottom) {
          div.style.cssText = "position: relative; top: " + (y - this.stickyTop) + "px;";
        }
        this.isSticked = true;
      } else {
        div.style.cssText = "";
        this.isSticked = false;
      }
    }
  };

  panelOpenClose = (e, active) => {
    MC.putFieldParamValue(this.props.widget.param, '@collapsed', active)
    this.forceUpdate()
    if (MC.isModelerActive(this.props.widget)) {
      modelerChanged("resize")
    } else {
      MC.handleEvent(this.props.widget, 'change')
    }
  }

  afterUpdate() {
    if (this.state.focus) {
      var input = ReactDOM.findDOMNode(this.refs[this.props.widget.rbsid])
      if (input) {
        input.focus()
      } else {
        ReactDOM.findDOMNode(this.refs.widgetroot).scrollIntoView()
      }
      var fixedHeader = document.getElementsByClassName('fixed-header-size')
      if (fixedHeader && fixedHeader.length === 1) {
        scrollBy(0, -fixedHeader[0].getBoundingClientRect().height)
      }
      this.setState({focus: false})
    }
    if (this.state.focused) {
      const formatter = MC.getFieldParamValue(this.props.widget.param, '@formatType')
      const disableFormatOnEdit = MC.getFieldParamBooleanValue(this.props.widget.param, '@disableFormatOnEdit')
      if (!MC.isNull(formatter) && disableFormatOnEdit) { // texfield was probably replaced in DOM and must be refocused
        const input = ReactDOM.findDOMNode(this.refs[this.props.widget.rbsid])
        if (input) {
          input.focus()
        }  
      }
    }
  }

  conditionalForceValidate() {
    if (MC.isModelerActive(this.props.widget)) {
      return
    }
    const forceValidation = MC.getFieldParamBooleanValue(this.props.widget.param, 'validation/@forceValidation')
    if (forceValidation) {
      MC.putFieldParamValue(this.props.widget.param, 'validation/@forceValidation', false)
      this.props.widget.flow.focusedOnFirst = true // prevent focus on first invalid
      this.revalidate(true)
    }
  }

  handleDateChange = (value) => {
    var field = this.props.widget;
    if (value && value.isLuxonDateTime == true) {
      let withTimezone = MC.getFieldParamBooleanValue(field.param, '@outputTimezone')
      switch (field.basictype) {
        case 'dateTime': MC.putFieldParamValue(field.param, 'value', value.toFormat(withTimezone ? "yyyy-MM-dd'T'HH:mm:ss.SSSZZ" : "yyyy-MM-dd'T'HH:mm:ss.SSS")); break
        case 'time': MC.putFieldParamValue(field.param, 'value', value.toFormat(withTimezone ? "HH:mm:ssZZ" : "HH:mm:ss")); break
        default: MC.putFieldParamValue(field.param, 'value', value.toFormat(withTimezone ? "yyyy-MM-ddZZ" : "yyyy-MM-dd")); break
      }
      if (this.props.widget.flow &&  this.props.widget.flow.context && this.props.widget.flow.context.data.env.cfg && this.props.widget.flow.context.data.env.cfg['fl:validationStyle'] == 'blur') {
        field.flow.focusedOnFirst = true // prevent focus on first invalid
        this.revalidate(true)
      } else {
        this.revalidate()
      }
    } else {
      MC.putFieldParamValue(field.param, 'value', value)
      this.resetValidation()
      if (!value) {
        this.forceUpdate()
      }
    }
    MC.handleEvent(field, 'change')
  }

  handleTextChange = (e, v) => {
    var field = this.props.widget;
    switch (field.widget) {
      case "checkbox":
        MC.putFieldParamValue(field.param, 'value', v.checked)
        if (MC.isFunction(field.onSubmit)) {
          field.onSubmit(field)
        }
        break;
      case "combobox":
      case "multicombobox":
        MC.putFieldParamValue(field.param, 'value', v.value)
        let options = this.buildOptions(field, false, v.value, false)
        this.updateAdditions(v.value, options)
        if (options.simpleText) {
          MC.putFieldParamValue(field.param, 'text', options.simpleText)
        }
        if (MC.isFunction(field.onSubmit)) {
          field.onSubmit(field)
        }
        break;
      case "colorpalette":
        MC.putFieldParamValue(field.param, 'value', v.value)
        if (MC.isFunction(field.onSubmit)) {
          field.onSubmit(field)
        }
        break;  
      case "whisperbox":
        MC.putFieldParamValue(field.param, 'value', e)
        MC.putFieldParamValue(field.param, 'text', v)
        if (MC.isFunction(field.onSubmit)) {
          field.onSubmit(field)
        }
        break;
      case "radiobutton":
        if (typeof this.props.parent != "undefined") {
          var parent = this.props.parent.props.widget;
          for (var i=0; i<parent.fields.length; i++) {
            var radio = parent.fields[i];
            if (radio.widget == 'radiobutton') {
              if (field.rbsid == radio.rbsid) {
                MC.putFieldParamValue(radio.param, 'value', true)
              } else {
                MC.putFieldParamValue(radio.param, 'value', false)
              }
            }
          }
        }
        break;
      case "unitcombobox":
        if (!v) {
          if (e.target.value) {
            let newVal = e.target.value;
            if (['integer', 'int', 'long', 'short', 'byte'].indexOf(field.basictype) > -1) {
              newVal = newVal.replace(/(?!-)[^0-9, ]/g, "");
            } else if (['decimal', 'double', 'float'].indexOf(field.basictype) > -1) {
              newVal = newVal.replace(/(?!-)[^0-9., ]/g, "");
            }
            MC.putFieldParamValue(field.param, 'value',  newVal)
          } else {
            MC.putFieldParamValue(field.param, 'value', null)
          }
        } else {
          MC.putFieldParamValue(field.param, '@unit', v.value)
        }
        break;
      default:
        let formatter = MC.getFieldParamValue(field.param, '@formatType')
        const disableFormatOnEdit = MC.getFieldParamBooleanValue(field.param, '@disableFormatOnEdit')
        if (formatter == 'number' && (!disableFormatOnEdit || !this.state.focused)) {
          MC.putFieldParamValue(field.param, 'value', e.value)
        } else if (e.target.value) {
          var newVal = e.target.value;
          if (['integer', 'int', 'long', 'short', 'byte'].indexOf(field.basictype) > -1) {
            newVal = newVal.replace(/(?!-)[^0-9, ]/g, "");
          } else if (['decimal', 'double', 'float'].indexOf(field.basictype) > -1) {
            newVal = newVal.replace(/(?!-)[^0-9., ]/g, "");
          }
          MC.putFieldParamValue(field.param, 'value', newVal)
        } else {
          MC.putFieldParamValue(field.param, 'value', null)
        }
    }
    if (['checkbox', 'combobox', 'multicombobox', 'colorpalette', 'radiobutton'].indexOf(field.widget) > -1) {
      if (field.flow && field.flow.context.data.env.cfg && field.flow.context.data.env.cfg['fl:validationStyle'] == 'blur') {
        field.flow.focusedOnFirst = true // prevent focus on first invalid
        this.revalidate(true);
      } else {
        this.revalidate();
      }
    } else {
      this.resetValidation();
      this.forceUpdate();
    }
    MC.handleEvent(field, 'change')
  };

  revalidate = (blur) => {
    var field = this.props.widget
    if (MC.getFieldParamBooleanValue(field.param, 'validation/@disableValidation')) {
      return
    }
    this.resetValidation();
    var self = this;
    if (field.widget === "radiobutton") {
      self = this.props.parent;
      field = self.props.widget;
    }
    if (MC.getFieldParamBooleanValue(field.param, '@invalid') || blur) { // run only if invalid or blur mode
      MC.validateField(field).then(function () {
        self.forceUpdate();
      }).catch(function (exception) {
        if (MC.isPlainObject(exception) && !MC.isNull(exception.type)) {
          field.flow.endOperationException(exception.type, exception.message, exception.input, exception.output, exception.log);
        } else {
          field.flow.endOperationException('SYS_UnrecoverableRuntimeExc', exception);
        }
      });
    } else {
      self.forceUpdate();
    }
  }

  pillClick = (e, item) => {
    e.stopPropagation()
    const field = this.props.widget
    const behavior = MC.getFieldParamValue(field.param, '@behavior')
    if (!MC.isNull(item.url) && item.url !== '' || item.url === '' && behavior == 'url') {
      field.flow.reactFlow().routeTo(e, item.url)
      return
    } else {
      MC.putFieldParamValue(field.param, '@activeKey', item.key)
      MC.handleEvent(field, 'change')
      if (behavior == 'dialog') {
        field.flow.callDialog(field)
      } else {
        if (MC.isFunction(field.onSubmit) && !MC.isModelerActive(field)) {
          field.onSubmit(field)
        }
      }
    }
  }

  buildSubFields(field, disabled, readOnly, label, textMode) {
    var resolution = this.props.resolution
    var hrows = []
    if (field.widget == 'pillbox') {
      let mainItem = null
      let mainItemLeft = null
      let mainItemRight = null
      let pillMain = MC.getFieldParamValue(field.param, 'pillMain')
      let pillMainDetail = MC.getFieldParamValue(field.param, 'pillMainDetail')
      let activeKey = MC.getFieldParamValue(field.param, '@activeKey')
      if (pillMain && !MC.isNull(pillMain.title) && pillMain.title !== '') {
        mainItemLeft = <a href={pillMain.url} className="pill-header-main-left" onClick={(e) => this.pillClick(e, pillMain)}>{pillMain.title + (pillMainDetail && !MC.isNull(pillMainDetail.title) && pillMainDetail.title !== '' ? ': ' : '')}</a>
      }
      if (pillMainDetail && !MC.isNull(pillMainDetail.title) && pillMainDetail.title !== '') {
        mainItemRight = <a href={pillMainDetail.url} className="pill-header-main-right" onClick={(e) => this.pillClick(e, pillMainDetail)}>{pillMainDetail.title}</a>
      }
      if (!MC.isNull(mainItemLeft) || !MC.isNull(mainItemRight)) {
        mainItem = <div className="ui large label main-pill">{mainItemLeft}{mainItemRight}</div>
      }
      let pillsRendered = []
      let pills = MC.asArray(MC.getFieldParamValue(field.param, 'pill'))
      for (let pill of pills) {
        if (pill && !MC.isNull(pill.title) && pill.title !== '') {
          pillsRendered.push(<div className={MC.classes('ui label pill', {'active': pill.active || pill.key === activeKey})} key={pill.title}><a href={pill.url} onClick={(e) => this.pillClick(e, pill)}>{pill.title}</a></div>)
        }
      }
      hrows.push(<div key="pillRow" className="ui row pill-header">{mainItem}{pillsRendered}</div>)
    } else if (label) {
      hrows.push(<div key="label" className="ui row mc-label">{label}</div>);
    }
    if (field.fields) {
      var rows = MC.splitFieldsIntoRows(field.fields, resolution);
      for (var i = 0; i < rows.length; i++) {
        var hrow = [];
        for (var ii = 0; ii < rows[i].length; ii++) {
          var subField = rows[i][ii];
          let offsetDiv;
          var grid = MC.getFieldGrid(subField, resolution);
          if (grid.offset > 0) {
            var cls = "ui " + MC.getFieldWideClassFromInt(grid.offset) + " wide column field mobile-no-100";
            offsetDiv = <div className={cls} key={subField.rbsid + 'gap'}/>;
          }
          hrow.push(<Widget key={subField.id} widget={subField} parent={this} ref={subField.rbsid} disabled={disabled} readOnly={readOnly} resolution={resolution} offsetDiv={offsetDiv} textMode={textMode}/>)
        }
        var inlineCss = {};
        if (!MC.isRowVisible(rows[i], resolution)) {
          inlineCss.display = 'none';
        }
        hrows.push(<div key={i} className="ui row" style={inlineCss}>{hrow}</div>);
      }
    }
    return hrows;
  }

  buildHbox(field, disabled, readOnly) {
    var className = "";
    if (MC.getFieldParamBooleanValue(field.param, '@fitWidth')) {
      className += " flex-grow";
    }
    if (!MC.getFieldParamBooleanValue(field.param, '@grouped')) {
      className += " gapped";
    }
    var hcolumns = [];
    if (field.fields) {
      for (var i = 0; i < field.fields.length; i++) {
        var subField = field.fields[i];
        hcolumns.push(
          <div className={className} key={i}>
            <Widget key={subField.rbsid} widget={subField} parent={this} ref={subField.rbsid} inTable={true} disabled={disabled} readOnly={readOnly}/>
          </div>
        );
      }
    }
    return hcolumns;
  }

  buildVbox(field, disabled, readOnly) {
    var className = "";
    if (MC.getFieldParamBooleanValue(field.param, '@fitWidth')) {
      className += " flex-grow";
    }
    if (!MC.getFieldParamBooleanValue(field.param, '@grouped')) {
      className += " gapped";
    }
    var vrows = [];
    if (field.fields) {
      for (var i = 0; i < field.fields.length; i++) {
        var subField = field.fields[i];
        vrows.push(
          <div className={className} key={i}>
            <Widget key={subField.rbsid} widget={subField} parent={this} ref={subField.rbsid} inTable={true} disabled={disabled} readOnly={readOnly}/>
          </div>
        );
      }
    }
    return vrows;
  }

  buildOptions(field, required, defaultValue, clearable) {
    let options = []
    if (!required && field.widget !== 'multicombobox' && !clearable) {
      options.push({value: '', text: ''})
    }
    let items = MC.getFieldParamValue(field.param, 'items')
    if (!items && field.param['defaultItems'] && Array.isArray(field.param['defaultItems'])) {
      items = field.param['defaultItems']
    }
    let simpleText = null
    if (Array.isArray(items)) {
      let groupsSet = {}
      for (let item of items) {
        if (item['@group'] && !groupsSet[item['@group']]) {
          groupsSet[item['@group']] = true
        }
      }
      let data = []
      if (!MC.isEmptyObject(groupsSet)) {
        for (let groupName in groupsSet) {
          let group = {}
          group.title = groupName
          group.items = []
          for (let item of items) {
            if (item['@group'] == groupName) {
              group.items.push(item)
            }
          }
          data.push(group)
        }
      } else {        
        data.push({title: null, items: items})
      }
      for (let group of data) {
        if (group.title) {
          options.push({ value: '$$' + group.title, disabled: true, content: (<div className="headerText"><h5>{group.title}</h5></div>) })
        }
        for (let item of group.items) {
          if (MC.isNull(item['@key'])) {
            continue
          }
          let icon = null
          if (!MC.isNull(item['@imageUrl'])) {
            let imageUrl = MC.rebaseUrl(field.flow.flow.model, item['@imageUrl'])
            icon = <img src={imageUrl} className={item['@icon']}/>
          } else if (!MC.isNull(item['@icon'])) {
            icon = <i className={item['@icon']}></i>
          }
          let title = item['@title'] || item['@key']
          if (item['@key'] == defaultValue) {
            simpleText = title
          }
          options.push({value: item['@key'], text: title, content: (<React.Fragment>{icon}{title}</React.Fragment>), url: item['@url'], cssClass: item['@cssClass']})
        }
      }
    }
    if (MC.getFieldParamBooleanValue(field.param, '@allowAdditions') && this.comboAdds.length > 0) {
      for (let add of this.comboAdds) {
        options.unshift({value: add, text: add, content: add})
      }
    }
    return {options: options, simpleText: simpleText}
  }

  updateAdditions(valueArr, options) {
    let wasUpdated = false
    if (MC.getFieldParamBooleanValue(this.props.widget.param, '@allowAdditions')) {
      for (let value of MC.asArray(valueArr)) {
        if (value && !options.options.find(o => o.value === value) && this.comboAdds.indexOf(value) < 0) {
          this.comboAdds.push(value)
          wasUpdated = true
        }
      }
    }
    return wasUpdated
  }

  buildColorOptions(field, required, clearable) {
    let options = []
    if (!required && !clearable) {
      options.push({value: '', text: '', content: ( <div className="ui label delete-color" style={{backgroundColor: 'transparent'}}></div>)})
    }
    let items = MC.getFieldParamValue(field.param, 'items')
    if (Array.isArray(items)) {
      for (let item of items) {
        let color = item['@color']
        if (MC.isNull(color)) {
          continue
        }
        options.push({value: color, text: color, content: ( <div className="ui label" style={{backgroundColor: color}}></div>)})
      }
    }
    return {options: options}
  }

  focus = () => {
    this.setState({focused: true});
  }

  focusDate = () => {
    ReactDOM.findDOMNode(this.refs.widgetroot).setAttribute("data-focused", "true");
  }

  blur = () => {
    this.setState({focused: false})
    if (this.props.widget.flow && this.props.widget.flow.context && this.props.widget.flow.context.data.env.cfg && this.props.widget.flow.context.data.env.cfg['fl:validationStyle'] == 'blur') {
      this.props.widget.flow.focusedOnFirst = true // prevent focus on first invalid
      this.revalidate(true)
    } else {
      this.revalidate()
    }
  }

  blurDate = () => {
    ReactDOM.findDOMNode(this.refs.widgetroot).removeAttribute("data-focused")
  }
  
  checkWarning = () => {
    var field = this.props.widget;
    MC.putFieldParamValue(field.param, "@invalid", false)
    MC.putFieldParamValue(field.param, "@invalidState", 'validChecked')
    MC.putFieldParamValue(field.param, "@invalidmessage", null)
    this.forceUpdate();
  };

  resetValidation = () => {
    var field = this.props.widget;
    MC.putFieldParamValue(field.param, "@invalid", false)
    MC.putFieldParamValue(field.param, "@invalidState", null)
    MC.putFieldParamValue(field.param, "@invalidmessage", null)
  };

  getTextMode(value) {
    let field = this.props.widget
    if (field.widget == 'combobox' || field.widget == 'multicombobox') {
      let items = MC.asArray(MC.getFieldParamValue(field.param, 'items'))
      let values = MC.asArray(MC.getFieldParamValue(field.param, 'value'))
      let res = []
      for (let value of values) {
        for (let item of items) {
          if (!item) continue
          if (item['@key'] === value) {
            let title = item['@title'] || item['@key']
            if (item['@url']) {
              title = <a className={item['@cssClass']} href={item['@url']} key={item['@key']}>{title}</a>
            } else {
              title = <span className={item['@cssClass']} key={item['@key']}>{title}</span>
            }
            if (res.length > 0) {
              res.push(<React.Fragment key={item['@key'] + '-frg'}>, </React.Fragment>)
            }
            res.push(title)
            break
          }
        }
      }
      return res
    } else if (field.widget == 'datebox') {
      let {dateValue, dateFormat, timeFormat} = MC.prepeareDate(field, value)
      if ((!dateValue.isValid || !MC.isValidDateStringByType(value, field.basictype || 'date')) && !MC.isNull(value)) { // invalid value is not conveted to luxon
        return value
      } else {
        let format = field.basictype == 'time' ? timeFormat : field.basictype == 'date' ? dateFormat : dateFormat + ' ' + timeFormat
        return dateValue.toFormat(format)
      }
    } else if (field.widget == 'passwordbox') {
      return '******'
    } else if (field.widget == 'colorpalette') {  
      return <span className="ui label" style={{backgroundColor: value}} key="color"></span>
    } else {
      return value
    }
  }

  render() {
    var widget;
    var field = this.props.widget;
    var defaultValue =  MC.getFieldParamValue(field.param, 'value')
    let iteration = MC.getFieldParamValue(field.param, '@iteration')
    var htmlId = field.rbsid + (Array.isArray(iteration) ? iteration.join('-') : '')
    if (Array.isArray(defaultValue)) {
      defaultValue = defaultValue[0];
    }
    if (defaultValue == null) {
      defaultValue = "";
    }
    var formatter = MC.getFieldParamValue(field.param, '@formatType')
    if (!MC.isNull(formatter) && ['label'].indexOf(field.widget) > -1) {
      if (!MC.isModelerActive(field) || (!MC.isNull(defaultValue) && defaultValue !== '')) {
        defaultValue = MC.formatValue(defaultValue, formatter, field.basictype, MC.getFieldParamValue(field.param, '@formatPattern'), field)
      }
    } else if (typeof defaultValue !== 'string' && !MC.isNull(defaultValue)) {
      defaultValue = JSON.stringify(defaultValue);
    }
    var required = MC.getFieldParamBooleanValue(field.param, 'validation/@required')
    var labelStr = MC.getFieldParamValue(field.param, '@title')
    var titlePlacement = MC.getFieldParamValue(field.param, '@titlePlacement')
    var label = '';
    if (field.widget == 'checkbox' && !labelStr) {
      labelStr = '\u00a0';
    }
    let inputWidth
    if (!this.props.inTable) {
      if (titlePlacement == 'PI' && labelStr) {
        label = <h3 className="ui header editable" key="h3">{labelStr}</h3>;
      } else if (labelStr) {
        var inlineCss = {};
        if (['L', 'LL'].indexOf(titlePlacement) > -1) {
          if (titlePlacement == 'L') {
            inlineCss.textAlign = 'right';
          } else {
            inlineCss.textAlign = 'left';
          }
          var tWidth = MC.getFieldParamValue(field.param, '@titleWidth')
          if (!MC.isNull(tWidth) && MC.isNumeric(tWidth)) {
            inlineCss.width = tWidth + '%'
            inputWidth = (100 - tWidth - 2) + '%'
          }
        }
        var requiredStar = '';
        var escapeHtml = MC.getFieldParamValue(field.param, '@escapeTitleHtml')
        if (escapeHtml) {
          if (required) {
            requiredStar = <span className="rstar">*</span>;
          }
          label = <label className="editable" style={inlineCss} htmlFor={field.rbsid} key="wlabel">{labelStr}{requiredStar}</label>;
        } else {
          if (required) {
            requiredStar = '<span class="rstar">*</span>';
          }
          label = <label className="editable" style={inlineCss} htmlFor={field.rbsid} key="wlabel" dangerouslySetInnerHTML={{__html: MC.customHtml(labelStr + requiredStar)}}/>;
        }
      }
    }
    if (MC.isModelerActive(field)) {
      label = <EditableLabel field={field} widget={label} key="EditableLabel" path={["param", "@title"]}/>;
    }
    if (titlePlacement === 'IN') {
      label = '';
    }
    var readOnly = MC.getFieldParamBooleanValue(field.param, '@readonly')
    if (!readOnly && this.props.readOnly) {
      readOnly = true;
    }
    let disabled = false
    if (MC.getFieldParamValue(field.param, '@enabled') == false || MC.getFieldParamValue(field.param, '@permitted') == false) {
      disabled = true
    }
    if (!disabled && this.props.disabled) {
      disabled = true
    }
    if (['slider'].indexOf(field.widget) > -1 && readOnly) { // fallback for widgets not having readonly mode
      disabled = true
    }
    if (MC.isModelerInEyeMode(field)) {
      disabled = false
    }
    let textMode = MC.getFieldParamBooleanValue(field.param, '@textmode')
    if (!textMode && this.props.textMode) {
      textMode = true
    }
    var maxlength = MC.getFieldParamValue(field.param, 'validation/@maxLength')
    var placeholder = MC.getFieldParamValue(field.param, '@placeholder')
    var help = MC.getFieldParamValue(field.param, '@help')
    var invalid = MC.getFieldParamBooleanValue(field.param, '@invalid')
    var invalidState = MC.getFieldParamValue(field.param, '@invalidState')
    var helpCls = 'help';
    var helpCheckbox = null;
    if (invalid) {
      var errMsg = MC.getFieldParamValue(field.param, 'validation/@title')
      if (!MC.isNull(errMsg) && errMsg !== '') {
        help = errMsg;
      } else {
        var invalidMsg = MC.getFieldParamValue(field.param, '@invalidmessage')
        if (!MC.isNull(invalidMsg) && invalidMsg !== '') {
          help = invalidMsg;
        }
      }
      if (invalidState == 'warning') {
        helpCls = 'warning';
        helpCheckbox = <Checkbox key="warning-checkbox" onChange={this.checkWarning}/>;
      } else {
        helpCls = 'error';
      }
    }
    if ((MC.isNull(help) || help == '') && !MC.isModelerInStructuralMode(field) && !this.props.inTable) {
      help = '\u00a0';
    }
    var errLabel = this.props.inTable && (MC.isNull(help) || help == '') ? '' : <label htmlFor={htmlId} className={helpCls} ref="label-error" key="error" onClick={(e) => {e.stopPropagation(); e.preventDefault()}}>{helpCheckbox}<span className="text">{help}</span></label>;
    var cssclass = MC.getFieldParamValue(field.param, '@cssClassField')
    var cssin = MC.getFieldParamValue(field.param, '@cssClass')
    var collapsible = MC.getFieldParamBooleanValue(field.param, '@collapsible')
    if (this.comboAdds.length > 0 && this.props.widget.flow.reactFlow().state.runReady) {
      this.comboAdds = []
    }
    if (textMode && ['textbox', 'numberbox', 'textarea', 'checkbox', 'combobox', 'multicombobox', 'datebox', 'passwordbox', 'colorpalette'].indexOf(field.widget) > -1) {
      widget = [this.getTextMode(defaultValue), errLabel]
    } else {
      switch (field.widget) {
        case 'textbox':
        case 'numberbox':
        case 'unitbox':
        case 'unitcombobox':
          const disableFormatOnEdit = MC.getFieldParamBooleanValue(field.param, '@disableFormatOnEdit') // for numeric keyboard on mobile phone
          if (formatter == 'number' && (!disableFormatOnEdit || !this.state.focused)) {
            let decimalScale = Number(MC.getFieldParamValue(field.param, '@decimalScale'))
            if (MC.isNull(decimalScale)) {
              decimalScale = 2
            }
            const prefix = MC.getFieldParamValue(field.param, '@prefix')
            const suffix = MC.getFieldParamValue(field.param, '@suffix')
            widget = <NumberFormat name={field.rbsid} ref={field.rbsid} placeholder={placeholder} value={defaultValue} readOnly={readOnly} disabled={disabled} key="input" 
                      maxLength={maxlength} onFocus={this.focus} onBlur={this.blur} data-widget-i-name={field.id} className={cssin} prefix={prefix || ''} suffix={suffix || ''} 
                      isNumericString={true} decimalSeparator="," thousandSeparator=" " decimalScale={decimalScale} onValueChange={this.handleTextChange}/>
          } else if (formatter == 'mask') {
            var mask = MC.getFieldParamValue(field.param, '@formatPattern')
            widget = <MaskedInput mask={mask} name={field.rbsid}  id={htmlId} ref={field.rbsid} placeholder={placeholder} value={defaultValue} readOnly={readOnly} disabled={disabled}
                                  maxLength={maxlength} onChange={this.handleTextChange} key="input" onFocus={this.focus} onBlur={this.blur} data-widget-i-name={field.id} className={cssin}/>
          } else {
            const type = ['integer', 'int', 'long', 'short', 'byte', 'decimal', 'double', 'float'].indexOf(field.basictype) > -1 ? 'number' : 'text';
            const maxValue = type === 'number' ? MC.getFieldParamValue(field.param, 'validation/@maxValue') : null
            const minValue = type === 'number' ? MC.getFieldParamValue(field.param, 'validation/@minValue') : null
            const step = ['decimal', 'double', 'float'].indexOf(field.basictype) > -1 ? '0.01' : null;
            widget = <input type={type} step={step} name={field.rbsid} id={htmlId} ref={field.rbsid} placeholder={placeholder} value={defaultValue} readOnly={readOnly} disabled={disabled}
                            maxLength={maxlength} onChange={this.handleTextChange} key="input" onFocus={this.focus} onBlur={this.blur} data-widget-i-name={field.id} className={cssin}
                            min={minValue} max={maxValue}/>;
          }
          if (field.widget === 'unitbox') {
            var unit = MC.getFieldParamValue(field.param, '@unit')
            var placement = MC.getFieldParamValue(field.param, '@unitPlacement')
            if (placement === 'left') {
              widget = [<div className="ui labeled input" key="input"><div className="ui label">{unit}</div>{widget}</div>, errLabel];
            } else {
              widget = [<div className="ui right labeled input" key="input">{widget}<div className="ui label">{unit}</div></div>, errLabel];
            }
          } else if (field.widget === 'unitcombobox') {
            var unit = MC.getFieldParamValue(field.param, '@unit')
            var placement = MC.getFieldParamValue(field.param, '@unitPlacement')
            const clearable = MC.getFieldParamBooleanValue(field.param, '@clearable')
            let options = this.buildOptions(field, required, unit, clearable)
            if (placement === 'left') {
              widget = ([
                <div className="ui labeled input" key="field">
                  <Dropdown className="label" value={unit} options={options.options} onFocus={this.focus} onBlur={this.blur} field={field}
                    onChange={this.handleTextChange} key="dropdown" disabled={disabled} selectOnNavigation={false} selectOnBlur={false} clearable={clearable} />
                  {widget}
                </div>,
                errLabel,
                <label className="error" key="error2" style={{display: 'none'}}/>
              ]);
            } else {
              widget = ([
                <div className="ui right labeled input" key="field">
                  {widget}
                  <Dropdown className="label" value={unit} options={options.options} onFocus={this.focus} onBlur={this.blur} field={field} 
                   onChange={this.handleTextChange} key="dropdown" disabled={disabled} selectOnNavigation={false} selectOnBlur={false} clearable={clearable} />
                </div>,
                errLabel,
                <label className="error" key="error2" style={{display: 'none'}}/>
              ]);
            }
          } else {
            widget = [widget, errLabel];
          }
          break;
        case 'datebox':
          let {dateValue, dateFormat, timeFormat, withTimezone} = MC.prepeareDate(field, defaultValue)
          if (!MC.isNull(defaultValue) && defaultValue != '' && dateValue.isValid) {
            switch (field.basictype) { //normalize bad mapped value for validation
              case 'dateTime': MC.putFieldParamValue(field.param, 'value', dateValue.toFormat(withTimezone ? "yyyy-MM-dd'T'HH:mm:ss.SSSZZ" : "yyyy-MM-dd'T'HH:mm:ss.SSS")); break
              case 'time': MC.putFieldParamValue(field.param, 'value', dateValue.toFormat(withTimezone ? "HH:mm:ssZZ" : "HH:mm:ss")); break
              default: MC.putFieldParamValue(field.param, 'value', dateValue.toFormat(withTimezone ? "yyyy-MM-ddZZ" : "yyyy-MM-dd")); break
            }
          }
          if ((!dateValue.isValid || !MC.isValidDateStringByType(defaultValue, field.basictype || 'date')) && !MC.isNull(defaultValue)) { // invalid value is not conveted to luxon
            dateValue = defaultValue
          }
          const maxValue = MC.getFieldParamValue(field.param, 'validation/@maxValue')
          const minValue = MC.getFieldParamValue(field.param, 'validation/@minValue')
          const hideCalendar = field.flow.getCfgParameter("mini:hideCalendar") === 'true'
          if (field.basictype == 'time') {
            widget = <Datetime onChange={this.handleDateChange} onBlur={this.blurDate} onFocus={this.focusDate} value={dateValue} dateFormat={false} timeFormat={timeFormat} closeOnSelect={true} locale={MC.getLang()}
                               inputProps={{id: htmlId, name: field.rbsid, onBlur: this.blurDate, placeholder: placeholder, readOnly: readOnly, disabled: (disabled || readOnly), 'data-widget-i-name': field.id}} 
                               key="input" min={minValue} max={maxValue} modelerActive={MC.isModelerActive(field)} hideCalendar={hideCalendar}/>
          } else if (field.basictype == 'dateTime') {
            widget = <Datetime onChange={this.handleDateChange} onBlur={this.blurDate} onFocus={this.focusDate} value={dateValue} dateFormat={dateFormat} timeFormat={timeFormat} closeOnSelect={true} locale={MC.getLang()}
                               inputProps={{id: htmlId, name: field.rbsid, onBlur: this.blurDate, placeholder: placeholder, readOnly: readOnly, disabled: (disabled || readOnly), 'data-widget-i-name': field.id}} 
                               key="input" min={minValue} max={maxValue} modelerActive={MC.isModelerActive(field)} hideCalendar={hideCalendar}/>
          } else {
            widget = <Datetime onChange={this.handleDateChange} onBlur={this.blurDate} onFocus={this.focusDate} value={dateValue} dateFormat={dateFormat} timeFormat={false} closeOnSelect={true} locale={MC.getLang()}
                               inputProps={{id: htmlId, name: field.rbsid, onBlur: this.blurDate, placeholder: placeholder, readOnly: readOnly, disabled: (disabled || readOnly), 'data-widget-i-name': field.id}} 
                               key="input" min={minValue} max={maxValue} modelerActive={MC.isModelerActive(field)} hideCalendar={hideCalendar}/>
          }
          widget = [widget, errLabel];
          break;
        case 'passwordbox':
          var autocomplete = 'on';
          if (MC.isModelerActive(field)) {
            autocomplete = 'off';
          }
          widget = <input type="password" name={field.rbsid} id={htmlId} ref={field.rbsid} placeholder={placeholder} value={defaultValue} readOnly={readOnly}
                          disabled={disabled} onChange={this.handleTextChange} key="input" onFocus={this.focus} onBlur={this.blur} autoComplete={autocomplete} data-widget-i-name={field.id}/>;
          widget = [widget, errLabel];
          break;
        case 'textarea':
          var rows = MC.getFieldParamValue(field.param, '@rows')
          if (MC.isNull(rows) || !MC.isNumeric(rows)) {
            rows = null;
          }
          var resize = MC.getFieldParamBooleanValue(field.param, '@resize')
          var txtStyle = {};
          if (!resize) {
            txtStyle.resize = 'none';
          }
          widget = <textarea type="text" name={field.rbsid} id={htmlId} ref={field.rbsid} placeholder={placeholder} value={defaultValue} readOnly={readOnly}
                             disabled={disabled} maxLength={maxlength} onChange={this.handleTextChange} key="input" rows={rows} style={txtStyle} data-widget-i-name={field.id}/>;
          widget = [widget, errLabel];
          break;
        case 'upload':
          widget = <Upload field={field} widget={this} key="input" htmlId={htmlId} placeholder={placeholder} readOnly={readOnly} disabled={disabled}/>
          widget = [widget, errLabel];
          break;
        case 'checkbox':
          const checked = defaultValue == true || defaultValue == 'true'
          if (!checked && defaultValue !== false && defaultValue !== 'false') {
            MC.putFieldParamValue(field.param, 'value', false)
          }
          if (typeof cssclass == 'string' && cssclass.indexOf("toggle") > -1) {
            cssin += " toggle"
          }
          let inLabel = <label key="chlabel">&nbsp;</label>
          if (titlePlacement == 'R') {
            inLabel = label
            label = ''
          }
          widget = [<Checkbox key="input" label={inLabel} checked={checked} onChange={this.handleTextChange} className={cssin} disabled={disabled} readOnly={readOnly} id={htmlId}/>, errLabel]
          break;
        case 'radiobutton':
          const parent = this.props.parent
          if (typeof parent != "undefined") {
            widget = <Checkbox radio key="input" name={parent.props.widget.rbsid} checked={defaultValue == true || defaultValue == 'true'} 
                       onChange={this.handleTextChange} className={cssin} disabled={disabled} readOnly={readOnly} label={label} id={htmlId}/>
          }
          break;
        case 'label':
          widget = <Label id={htmlId} ref={field.rbsid} field={field} inTable={this.props.inTable} value={defaultValue} key="label"/>
          if (MC.isModelerActive(field)) {
            widget = <EditableLabel key="editableLabel" field={field} widget={widget} path={["param", "@title"]}/>;
          }
          widget = [widget, errLabel];
          break;
        case 'button':
          widget = [<Button button={field} ref={field.rbsid} value={defaultValue} disabled={disabled} key="button"/>, errLabel]
          if (MC.isModelerActive(field)) {
            widget = <EditableLabel key="editableLabel" field={field} widget={widget} path={["param", "@title"]}/>;
          }
          break;
        case 'link':
          widget = <Link id={htmlId} data={field} ref={field.rbsid} value={defaultValue} disabled={disabled} key="link" inTable={this.props.inTable}/>
          widget = [widget, errLabel];
          if (MC.isModelerActive(field)) {
            widget = <EditableLabel key="editableLabel" field={field} widget={widget} path={["param", "@title"]}/>;
          }
          break;
        case 'combobox':
        case 'multicombobox':
        case 'colorpalette':
          const	allowAdditions = MC.getFieldParamBooleanValue(field.param, '@allowAdditions')	
          const whisper = MC.getFieldParamBooleanValue(field.param, '@whisper') || allowAdditions
          const clearable = MC.getFieldParamBooleanValue(field.param, '@clearable')
          let options = field.widget === 'colorpalette' ? this.buildColorOptions(field, required, clearable) : this.buildOptions(field, required, defaultValue, clearable)
          let disabledCmb = false
          if (!MC.isModelerInEyeMode(field) && (disabled || readOnly)) {
            disabledCmb = true
          }
          let multiple = false
          let values = defaultValue
          if (field.widget === 'multicombobox') {
            values = MC.getFieldParamValue(field.param, 'value')
            if (MC.isNull(values)) {
              values = []
            }
            multiple = true
          } else {
            if (!MC.isNull(options.simpleText)) {
              MC.putFieldParamValue(field.param, 'text', options.simpleText)
            }
          }
          if ((field.widget === 'combobox' || field.widget === 'multicombobox') && this.updateAdditions(values, options)) {
            options = this.buildOptions(field, required, defaultValue, clearable)
          }
          let cls = MC.classes('fluid selection', {'colorpalette': field.widget === 'colorpalette'})
          widget = [<Dropdown className={cls} multiple={multiple} key="dropdown" onChange={this.handleTextChange} search={whisper} value={values} options={options.options} selectOnNavigation={false} field={field}
                      selectOnBlur={false} disabled={disabledCmb} onFocus={this.focus} onBlur={this.blur} placeholder={placeholder} allowAdditions={allowAdditions} clearable={clearable}/>, errLabel]
          break
        case 'panel':
        case 'pillbox':
        case 'radiogroup':
          if (field.widget == 'panel' && field.scriptedWidget) {
            widget =  <div className="ui twelve wide column" dangerouslySetInnerHTML={{__html: field.scriptedWidget.html}} ref={this.widgetRef}/>
          } else {
            var labelToSend = field.widget == 'panel' && titlePlacement == 'PI' && !collapsible ? label : null
            widget = this.buildSubFields(field, disabled, readOnly, labelToSend, textMode)
          }
          if (field.widget == 'radiogroup') {
            widget = [widget, errLabel]
          }
          break;
        case 'hbox':
          widget = this.buildHbox(field, disabled, readOnly)
          break;
        case 'vbox':
          widget = this.buildVbox(field, disabled, readOnly)
          break;
        case 'tabpanel':
          widget = <TabPanel key="tabpanel" data={field} ref={field.rbsid} disabled={disabled} readOnly={readOnly} resolution={this.props.resolution}/>;
          break;
        case 'repeater':
          widget = <Repeater key="repeater" data={field} ref={field.rbsid} disabled={disabled} readOnly={readOnly} resolution={this.props.resolution}/>
          break;
        case 'basictable':
        case 'loadabletable':
        case 'pageabletable':
        case 'scrollabletable':
          widget = <Table key="table" data={field} ref={field.rbsid} disabled={disabled} readOnly={readOnly} textMode={textMode} resolution={this.props.resolution}/>
          break;
        case 'download':
          if (MC.isModelerActive(field)) {
            widget = <Dummy key="widget" field={field}/>
          } else {
            widget = <Download key="download" data={field} ref={field.rbsid}/>
          }
          break;
        case 'imageviewer':
        case 'defaultviewer':
        case 'pdfviewer':
          widget = [<Media key="media" data={field} ref={field.rbsid}/>, errLabel]
          break;
        case 'icon':
            var icon = MC.getFieldParamValue(field.param, '@icon')
            if (!MC.isNull(icon)) {
              var cls = 'icon ' + icon;
              widget = <i key="icon" className={cls} id={htmlId} ref={field.rbsid} data-widget-i-name={field.id}></i>;
            } else if (MC.isModelerActive) {
              widget = <Dummy field={field}/>
            }
          break;
        case 'embeddeddialog':
          if (MC.isModelerActive(field)) {
            widget = <Dummy key="widget" field={field}/>;
          } else {
            widget = <EmbeddedDialog key="dialog" data={field} ref={field.rbsid}/>
          }
          break;
        case 'camera':
          widget = <Camera key="camera" data={field} ref={field.rbsid} htmlId={htmlId} help={help} helpCls={helpCls}/>
          break;
        case 'slider':
          widget = [<Slider key="slider" data={field} onFocus={this.focus} onBlur={this.blur} focused={this.state.focused}/>, errLabel]
          break;
        case 'whisperbox':
          widget = [<WhisperBox key="slider" data={field} placeholder={placeholder} widget={this} readOnly={readOnly} disabled={disabled}/>, errLabel]
          break;
        case 'menubutton':
          widget = [<MenuButton key="menubutton" data={field}/>, errLabel]
          break;
        case 'dynamicPanel':
          widget = this.buildSubFields(field, disabled, readOnly, null, textMode)
          break;
        case 'menu':
          widget = [<Menu key="menu" data={field}/>, errLabel]
          break;
        case 'breadcrumb':
          widget = [<Breadcrumb key="breadcrumb" data={field}/>, errLabel]
          break;  
        case 'carousel':
          const autoplay = MC.getFieldParamBooleanValue(field.param, '@autoplay')
          let autoplayInterval = MC.getFieldParamValue(field.param, '@autoplayInterval')
          if (MC.isNumeric(autoplayInterval)) {
            autoplayInterval = autoplayInterval * 1000
          } else {
            autoplayInterval = 3000
          }
          const wrapAround = MC.getFieldParamBooleanValue(field.param, '@wrapAround')
          widget = <Carousel key="carousel" data={field} autoplay={autoplay} autoplayInterval={autoplayInterval} wrapAround={wrapAround}/>
          break;
        case 'objectinspector':
          const name = MC.getFieldParamValue(field.param, '@name')
          const data = MC.getFieldParamValue(field.param, '@value')
          widget = <ObjectInspector key="obi" data={data} name={name}/> 
          break
        default:
          let CustomComponent = MC.getReactRomponent(field.widget)
          let extendedProps = {}
          if ('piechart' == field.widget) {
            extendedProps.type = 'pie'
            CustomComponent = MC.getReactRomponent('chart')
          }
          if ('chart' == field.widget) {
            extendedProps.type = 'basic'
          }
          if (CustomComponent) {
            widget = <CustomComponent key={field.rbsid} data={field} ref={field.rbsid} placeholder={placeholder} readOnly={readOnly} disabled={disabled} 
                        value={defaultValue} handleTextChange={this.handleTextChange} {...extendedProps}/>
          } else {
            if (MC.isModelerActive(field)) {
              widget = this.buildSubFields(field, disabled, readOnly, null, textMode)
            } else {
              widget = <span key="unrecognized">Unsupported, or not loaded widget: {field.widget}</span>
            }
          }
          break;
      }
    }
    cssclass = cssclass ? ' ' + cssclass : '';
    if (!MC.isModelerInEyeMode(field) && disabled) {
      cssclass += ' disabled';
    }
    if (['L', 'LL'].indexOf(titlePlacement) > -1) {
      cssclass += ' inline';
    } 
    var halign = MC.getFieldParamValue(field.param, '@horizontalAlignment')
    var inlineCss = {};
    if (this.state.visible === false) {
      inlineCss.display = 'none';
    }
    if (halign == 'center') {
      inlineCss['textAlign'] = 'center';
    } else if (halign == 'right') {
      inlineCss['textAlign'] = 'right';
    }
    var valign = MC.getFieldParamValue(field.param, '@verticalAlignment') + ' '
    if (valign == 'bottom ') {
      valign = 'bottom aligned ';
    } else {
      valign = '';
    }
    var appendCss = '';
    if (invalid) {
      if (invalidState == 'warning') {
        appendCss = ' warning';
      } else {
        appendCss = ' error';
      }
    } else {
      if (invalidState == 'valid' || invalidState == 'validChecked') {
        appendCss = ' valid';
      }
    }
    if (this.state.focused) {
      appendCss += ' focused';
    }
    if (!MC.isNull(defaultValue) && defaultValue !== '' || field.widget === 'upload') {
      if (["checkbox", "radiogroup", "radiobutton"].indexOf(field.widget) < 0) {
        appendCss += ' valued';
      }
    }
    let collapsed = MC.getFieldParamBooleanValue(field.param, '@collapsed')
    let resField;
    let inputWrapperInlineCss = {}
    if (inputWidth) {
      inputWrapperInlineCss.width = inputWidth
      inputWrapperInlineCss.display = 'inline-grid'
    }
  	if (["radiogroup"].indexOf(field.widget) > -1) {
      var cls = "ui " + valign + this.state.wideClass + " wide column field widget radiogroup"  + cssclass + appendCss;
      var clsGrid = "ui twelve column grid stackable";
      widget = [label,
                    <div key="kd" className={clsGrid} style={inputWrapperInlineCss} data-widget-i-name={field.id}>
                        {widget}
                    </div>];
      resField = <Field key="field" ref="widgetroot" field={field} className={cls} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>;
    } else if (['panel', 'dynamicPanel'].indexOf(field.widget) > -1) {
      let cls = "ui " + valign + this.state.wideClass + " wide column field widget" + cssclass
      let clsGrid = "ui twelve column grid stackable widgetContainer"
      label = field.widget == 'panel' && titlePlacement == 'PI' ? null : label
      if (collapsible) {
        let activeIndex = collapsed ? -1 : 0
        let panel = [{
          key: 'panel',
          title: <span className="ui header">{labelStr}</span>,
          content: <div key="kd" className={clsGrid} data-widget-i-name={field.id}>{widget}</div>
        }]
        widget = <Accordion fluid panels={panel} onTitleClick={this.panelOpenClose} activeIndex={activeIndex}/>
      } else {
        widget = [label,
          <div key="kd" className={clsGrid} style={inputWrapperInlineCss} data-widget-i-name={field.id}>
            {widget}
          </div>]
      }
      resField = <Field key="field" ref="widgetroot" field={field} className={cls} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>
    } else if ('pillbox' == field.widget ) {
      let cls = MC.classes('ui', valign, this.state.wideClass, 'wide column widget segment pillbox' + cssclass)
      widget = <div key="kd" style={inputWrapperInlineCss} className="ui twelve column grid stackable widgetContainer" data-widget-i-name={field.id}>{widget}</div>
      resField = <Field key="field" ref="widgetroot" field={field} className={cls} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>  
    } else if (["hbox"].indexOf(field.widget) > -1) {
      var cls = "ui " + valign + this.state.wideClass + " wide column" + cssclass;
      var className = "hbox flex-row";
      if (halign == 'center') {
        className += ' flex-center';
      } else if (halign == 'right') {
        className += ' flex-right';
      }
      widget = [label, <div  key="kd" className={className} style={{...inlineCss, ...inputWrapperInlineCss}} data-widget-i-name={field.id}>{widget}</div>];
      resField = <Field key="field" ref="widgetroot" field={field} className={cls} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>;
    } else if (["vbox"].indexOf(field.widget) > -1) {
      if (MC.getFieldParamBooleanValue(field.param, '@fitWidth')) {
        inlineCss['height'] = '100%';
      }
      var cls = "ui " + valign + this.state.wideClass + " wide column" + cssclass;
      widget = [label, <div  key="kd" className="vbox flex-column" style={{...inlineCss, ...inputWrapperInlineCss}} data-widget-i-name={field.id}>{widget}</div>];
      resField = <Field key="field" ref="widgetroot" field={field} className={cls} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>;
    } else if (field.widget == "repeater") {
      var cls = "ui " + valign + this.state.wideClass + " wide column widget" + cssclass;
      var inline = MC.getFieldParamBooleanValue(field.param, '@inline')
      if (inline) {
        cls = "ui " + valign + " twelve wide column widget" + cssclass;
      }
      if (collapsible) {
        let activeIndex = collapsed ? -1 : 0
        let panel = [{
          key: 'panel',
          title: <span className="ui header">{labelStr}</span>,
          content:  widget
        }]
        widget =  <Accordion panels={panel} onTitleClick={this.panelOpenClose} activeIndex={activeIndex}/>
        resField = <Field key="field" ref="widgetroot" field={field} className={cls} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>;
      } else {
        if (inputWidth) {
          widget = <div style={inputWrapperInlineCss}>{widget}</div>
        }
        widget = [label, widget];
        resField = <Field key="field" ref="widgetroot" field={field} className={cls} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>;
      }
    } else {
      if (this.props.inTable) {
        let cls = "ui " + valign + " wide column field widget" + cssclass + appendCss
        resField = <Field key="field" ref="widgetroot" field={field} className={cls} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>;
      } else {
        var cls = "ui " + valign + this.state.wideClass + " wide column field widget" + cssclass + appendCss;
        if (field.widget == 'checkbox') {
          cls += ' widget-checkbox';
          if (MC.isModelerActive(field)) {
            cls += " read-only";
          }
        } else if (field.widget == 'radiobutton') {
          cls += ' widget-radio';
          if (MC.isModelerActive(field)) {
            cls += " read-only";
          }
          if (defaultValue == true || defaultValue == 'true') {
            cls += " checked";
          }
          inlineCss['paddingTop'] = '15px';
        } else if (field.widget == 'upload') {
          cls += " upload";
        }
        if (inputWidth) {
          widget = <div style={inputWrapperInlineCss} key="inwrap">{widget}</div>
        }
        if (field.widget == 'radiobutton') { 
          widget = widget 
        } else {
          if (MC.isModelerActive(field) && Array.isArray(field.fields) && field.fields.length > 0) { // add grid to unknown widgets with children
            widget = <div key="kd" className="ui twelve column grid stackable widgetContainer" data-widget-i-name={field.id}>{widget}</div>
          }
          widget = [label, widget]
        }
        resField = <Field key="field" ref="widgetroot" field={field} className={cls} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>;
      }
    }
    let toReturn = null
    if (this.state.visible && this.props.offsetDiv) {
      toReturn = [this.props.offsetDiv, resField]
    } else {
      toReturn = resField
    }
    const transition = MC.getFieldParamValue(this.props.widget.param, '@transition')
    if (MC.isNull(transition)) {
      return toReturn
    } else {
      return <Transition visible={this.state.visible} animation={transition} duration={1000}>{toReturn}</Transition>
    }
  }

}

export {Widget}