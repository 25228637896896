import React from "react"

import {MC} from '../client/MC.js'
import {ReactFlow} from "../client/ReactFlow.jsx"

class MiniclientConsole extends React.Component {

  constructor(props) {
    super(props)
    let configuration = MC.getParameterByName("configuration") || mncStartConfiguration && mncStartConfiguration['configuration'] || null
    let flowName = MC.getParameterByName("flowName") || mncStartConfiguration && mncStartConfiguration['flowName'] || null
    let start = (configuration && flowName) ? true : false
    let debug = MC.getParameterByName("debug") || MC.getParameterByName("jsi.debug")
    if (!debug && mncStartConfiguration && mncStartConfiguration['debug']) {
      debug = mncStartConfiguration['debug']
    }
    if (debug == 'false') {
      debug = null
    }
    var input = MC.getParameterByName("input")
    if (input) {
      input = JSON.parse(decodeURIComponent(input))
    } else {
      input = null
    }
    this.state = {flowName: flowName, configuration: configuration, start: start, debug: debug, input: input}
  }

  afterRenderFormFunction = (form, initial) => {
    if (parent) {
      let urlarr = window.location.href.split("/")
      parent.postMessage(initial ? {height: "initial", width: "initial"} : {height: document.body.scrollHeight, width: document.body.scrollWidth}, urlarr[0] + "//" + urlarr[2])
    }
  }

  render() {
    return <ReactFlow configuration={this.state.configuration} flowName={this.state.flowName} debug={this.state.debug} console={true} start={this.state.start}
                      configurationChanger={true} input={this.state.input} afterRenderFormFunction={this.afterRenderFormFunction} loader="none"/>
  }

}

export default MiniclientConsole