import React from 'react'

// easeExpOut from d3-ease
export const easeExpOut = (t) => {
  return 1 - Math.pow(2, -10 * t)
}

export const addAccessibility = (children, slidesToShow, currentSlide) => {
  let needsTabIndex;
  if (slidesToShow > 1) {
    return React.Children.map(children, (child, index) => {
      // create a range from first visible slide to last visible slide
      const firstVisibleSlide = index >= currentSlide;
      const lastVisibleSlide = index < slidesToShow + currentSlide;
      needsTabIndex = firstVisibleSlide && lastVisibleSlide;
      // if the index of the slide is in range add ariaProps to the slide
      const ariaProps = needsTabIndex
        ? { 'aria-hidden': 'false', tabIndex: 0 }
        : { 'aria-hidden': 'true' };
      return React.cloneElement(child, {
        ...child.props,
        ...ariaProps
      });
    });
  } else {
    // when slidesToshow is 1
    return React.Children.map(children, (child, index) => {
      needsTabIndex = index !== currentSlide;
      const ariaProps = needsTabIndex
        ? { 'aria-hidden': 'true' }
        : { 'aria-hidden': 'false', tabIndex: 0 };
      return React.cloneElement(child, {
        ...child.props,
        ...ariaProps
      });
    });
  }
};

const findMaxHeightSlide = slides => {
  let maxHeight = 0;
  for (let i = 0; i < slides.length; i++) {
    if (slides[i].offsetHeight > maxHeight) {
      maxHeight = slides[i].offsetHeight;
    }
  }
  return maxHeight;
};

export const getSlideHeight = (props, state, childNodes = []) => {
  const { heightMode, vertical, initialSlideHeight } = props;
  const { slidesToShow, currentSlide } = state;
  const firstSlide = childNodes[0];

  if (firstSlide && heightMode === 'first') {
    return vertical
      ? firstSlide.offsetHeight * slidesToShow
      : firstSlide.offsetHeight;
  }
  if (heightMode === 'max') {
    return findMaxHeightSlide(childNodes);
  }
  if (heightMode === 'current') {
    return childNodes[currentSlide].offsetHeight;
  }
  return initialSlideHeight || 100;
};

export const addEvent = function(elem, type, eventHandle) {
  if (elem === null || typeof elem === 'undefined') {
    return;
  }
  if (elem.addEventListener) {
    elem.addEventListener(type, eventHandle, false);
  } else if (elem.attachEvent) {
    elem.attachEvent(`on${type}`, eventHandle);
  } else {
    elem[`on${type}`] = eventHandle;
  }
};

export const removeEvent = function(elem, type, eventHandle) {
  if (elem === null || typeof elem === 'undefined') {
    return;
  }
  if (elem.removeEventListener) {
    elem.removeEventListener(type, eventHandle, false);
  } else if (elem.detachEvent) {
    elem.detachEvent(`on${type}`, eventHandle);
  } else {
    elem[`on${type}`] = null;
  }
};

export const swipeDirection = (x1, x2, y1, y2, vertical) => {
  const xDist = x1 - x2;
  const yDist = y1 - y2;
  const r = Math.atan2(yDist, xDist);
  let swipeAngle = Math.round((r * 180) / Math.PI);

  if (swipeAngle < 0) {
    swipeAngle = 360 - Math.abs(swipeAngle);
  }
  if (swipeAngle <= 45 && swipeAngle >= 0) {
    return 1;
  }
  if (swipeAngle <= 360 && swipeAngle >= 315) {
    return 1;
  }
  if (swipeAngle >= 135 && swipeAngle <= 225) {
    return -1;
  }
  if (vertical === true) {
    if (swipeAngle >= 35 && swipeAngle <= 135) {
      return 1;
    } else {
      return -1;
    }
  }
  return 0;
};

export const shouldUpdate = (curr, next, keys) => {
  let update = false;

  for (let i = 0; i < keys.length; i++) {
    if (curr[keys[i]] !== next[keys[i]]) {
      update = true;
      break;
    }
  }

  return update;
};

export const calcSomeInitialState = props => {
  const { slidesToScroll, slidesToShow, cellAlign } = props

  const slideWidth = props.vertical
    ? props.initialSlideHeight || 0
    : props.initialSlideWidth || 0;
  const slideHeight = props.vertical
    ? (props.initialSlideHeight || 0) * props.slidesToShow
    : props.initialSlideHeight || 0;

  const frameHeight = slideHeight + props.cellSpacing * (slidesToShow - 1);

  const frameWidth = props.vertical ? frameHeight : '100%';
  return {
    slideWidth,
    slideHeight,
    frameWidth,
    slidesToScroll,
    slidesToShow,
    cellAlign
  };
};

export const getDecoratorStyles = position => {
  switch (position) {
    case 'TopLeft': {
      return {
        position: 'absolute',
        top: 10,
        left: 0
      };
    }
    case 'TopCenter': {
      return {
        position: 'absolute',
        top: 10,
        left: '50%',
        transform: 'translateX(-50%)',
        WebkitTransform: 'translateX(-50%)',
        msTransform: 'translateX(-50%)'
      };
    }
    case 'TopRight': {
      return {
        position: 'absolute',
        top: 10,
        right: 0
      };
    }
    case 'CenterLeft': {
      return {
        position: 'absolute',
        top: '50%',
        left: 0,
        transform: 'translateY(-50%)',
        WebkitTransform: 'translateY(-50%)',
        msTransform: 'translateY(-50%)'
      };
    }
    case 'CenterCenter': {
      return {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        WebkitTransform: 'translate(-50%, -50%)',
        msTransform: 'translate(-50%, -50%)'
      };
    }
    case 'CenterRight': {
      return {
        position: 'absolute',
        top: '50%',
        right: 0,
        transform: 'translateY(-50%)',
        WebkitTransform: 'translateY(-50%)',
        msTransform: 'translateY(-50%)'
      };
    }
    case 'BottomLeft': {
      return {
        position: 'absolute',
        bottom: 10,
        left: 0
      };
    }
    case 'BottomCenter': {
      return {
        position: 'absolute',
        bottom: 10,
        left: '50%',
        transform: 'translateX(-50%)',
        WebkitTransform: 'translateX(-50%)',
        msTransform: 'translateX(-50%)'
      };
    }
    case 'BottomRight': {
      return {
        position: 'absolute',
        bottom: 10,
        right: 0
      };
    }
    default: {
      return {
        position: 'absolute',
        top: 10,
        left: 0
      };
    }
  }
};

export const getSliderStyles = (propWidth, propHeight) => {
  return {
    boxSizing: 'border-box',
    display: 'block',
    height: propHeight,
    MozBoxSizing: 'border-box',
    position: 'relative',
    width: propWidth
  };
};

export const getFrameStyles = (
  propFrameOverFlow,
  propVertical,
  propFramePadding,
  stateFrameWidth
) => {
  return {
    boxSizing: 'border-box',
    display: 'block',
    height: propVertical ? stateFrameWidth || 'initial' : '100%',
    margin: propFramePadding,
    MozBoxSizing: 'border-box',
    msTransform: 'translate(0, 0)',
    overflow: propFrameOverFlow,
    padding: 0,
    position: 'relative',
    touchAction: `pinch-zoom ${propVertical ? 'pan-x' : 'pan-y'}`,
    transform: 'translate3d(0, 0, 0)',
    WebkitTransform: 'translate3d(0, 0, 0)'
  };
};

export const getTransitionProps = (props, state) => {
  return {
    animation: props.animation,
    cellSpacing: props.cellSpacing,
    currentSlide: state.currentSlide,
    dragging: props.dragging,
    isWrappingAround: state.isWrappingAround,
    left: state.left,
    slideCount: state.slideCount,
    slideHeight: state.slideHeight,
    slideOffset: props.slideOffset,
    slidesToShow: state.slidesToShow,
    slideWidth: state.slideWidth,
    top: state.top,
    vertical: props.vertical,
    wrapAround: props.wrapAround,
    zoomScale: props.zoomScale,
    opacityScale: props.opacityScale,
    slideListMargin: props.slideListMargin
  };
};