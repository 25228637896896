import React from "react"

import {ReactFlow} from "../client/ReactFlow.jsx"

class MiniApp extends React.Component {

  state = {readyToRender: false, cssClass: undefined}

  onEndFlow = (output, error, redirect) => {
    this.props.app.onEndFlow(output, error, redirect)
  }

  afterRenderFormFunction = (formData) => {
    if (!this.state.readyToRender || formData.cssclass !== this.state.cssClass) {
      this.setState({readyToRender: true, cssClass: formData.cssclass})
    }
  }

  render() {
    const app = this.props.app
    const conf = app.getConfigurationObject()
    let layoutStyle = {}
    if (conf['mini:loader'] == "none" && !this.state.readyToRender) {
      layoutStyle = {display: 'none'}
    }
    let input = app.getInputObject(true)
    if (this.props.flowName) {
      input.flowName = this.props.flowName
    }
    const menu = app.getMenuByName("SideMenu")
    if (menu) {
      input.menu = menu
    }
    return (
      <div id="page" style={layoutStyle} className={this.state.cssClass}>
        <ReactFlow configuration={app.getConfiguration()} flowName={app.getMainFlowName()} debug={app.getDebug()} console={true} start={app.obtainStart()} configurationChanger={true} input={input}
          onEndFunction={this.onEndFlow} afterRenderFormFunction={this.afterRenderFormFunction} options={{ripple: false}} configurationObject={conf} app={app}/>
      </div>
    )
  }

}

export {MiniApp}