import React from "react";
import ReactDOM from 'react-dom';

import {MC} from "../client/MC.js";
import {Widget} from '../client/Widget.jsx';
import {FieldDef} from "./FieldDef.js";
import {WidgetModel} from "./WidgetModel.js";

/* Paleta polí. Paleta je uložená ve formuláři form-modeler-palette v appmodelerref/fe. */

class Palette extends React.Component {

  state = {search: ""};

  handleSearchChange = (event) => {
    this.setState({search: event.target.value});
  };

  handleClick = (event) => {
    event.preventDefault();
  };

  componentDidMount() {
    var $node = $(ReactDOM.findDOMNode(this));
    $node.find("#searchInput").focus();
  }

  render() {
    var self = this;
      if (!this.props.widgetModelLoaded) {
          return <div className="palette">
              <div className="ui segment basic loading">
              </div>
          </div>;
      } else {
          var labelField = {"widget":"label", "labelplacement":"IN",
              "grid": [{"columns":"12","index":"1","offset":"0","resolution":"x-small","visible":"true","newLineAfter":"never"}],
              "fields": [],
              "param": {"@autoHeight": true,
                          "@enabled": true,
                          "@escapeHtml": true,
                          "@escapeTitleHtml": true,
                          "@escapeTooltipHtml": true,
                          "@invalid": false,
                          "@noPermissionBehavior": "disabled",
                          "@permitted": true,
                          "@scrollable":false,
                          "@verticalAlignment":"bottom",
                          "@visible":true}};
          var fields=[];
          var fieldsPalette = WidgetModel.getFieldsPalette();
          var fieldsDef = fieldsPalette.filter(field => field.param["@title"].toUpperCase().includes(this.state.search.toUpperCase()));
          fieldsDef.forEach(function (field) {
            var displayedField;
            var displayedField = deepCopy(labelField);
            displayedField.id = field.id;
            displayedField.param["@title"] = field.param["@title"];
            if (-1 != ["basictable", "loadabletable", "pageabletable", "scrollabletable" , "repeater"].indexOf(field.widget)) {
              field.fields = [{id: "rows*", fields: [], flow: self.props.flow, rbsid: "dummy-rows", widget: "dummy", param: {}}];
            }
            field.flow = self.props.flow;
            displayedField.flow = self.props.flow;
            displayedField.isInPalette = true;
            FieldDef.setProto(displayedField);
            fields.push(<div key={field.widget} className="ui segment basic grid"><Widget  widget={displayedField} ref={displayedField.id} resolution="x-small"/></div>);
          });
          var style = {top: this.props.position.y, left: this.props.position.x};
          return <div id="palette" className="meta column" style={style} onClick={this.handleClick}>
                   <div className="meta menu top component">
                     <div className="ui icon input menu-item meta grow">
                       <input id="searchInput" name="widgetsearch" type="text" placeholder="Hledej..." onChange={this.handleSearchChange}/>
                       <i className="search icon"/>
                     </div>
                   </div>
                   <div className="inner meta grow">
                     {fields}
                   </div>
                 </div>;
          }
  }
}

function deepCopy (object) {
  return MC.extend(true, {}, object);
}

export {Palette};