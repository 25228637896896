import React from "react"

import {MC} from './MC.js'

class Paginator extends React.Component {

  state = {page: this.props.page || 1, textValue: this.props.page}

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.page !== nextProps.page) {
      return {page: nextProps.page, textValue: nextProps.page}
    }
    return null
  }

  onChange = (pageNumber) => {
    if (MC.isFunction(this.props.onChange)) {
      this.props.onChange(pageNumber)
    } else {
      MC.error('onChage function for paginator is not set!')
    }
  }

  onChangeText = (e) => {
    this.setState({textValue: e.target.value})
  }

  onBlurText = (e) => {
    var newVal = e.target.value
    if (MC.isNumeric(newVal)) {
      newVal = Number(newVal).valueOf()
      if (Number.isInteger(newVal) && newVal >= 1 && newVal <= this.props.totalPages) {
        this.onChange(newVal)
      }
    }
    this.setState({textValue: this.state.page})
  }

  handleKeyUp = (e) => {
    if (e.key == 'Enter') {
      e.stopPropagation()
      this.onBlurText(e)
    }
  }

  render() {
    var self = this
    var totalPages = this.props.totalPages
    if (totalPages && totalPages > 1) {
      var current = this.state.page
      var children = [1]
      if (current !== 1 && current != totalPages) {
        children.push(current)
      }
      children.push(totalPages)
      children = children.map(function(child) {
        if (child === current) {
          var itemStyle = {padding: '0.1em 0.2em'}
          var inputStyle = {padding: '0.2em 0.3em', lineHeight: '1.9em'}
          return (<div className="active item" key={child} style={itemStyle}>
                   <input type="number" name="page" min={1} max={totalPages} value={self.state.textValue} onChange={self.onChangeText} onBlur={self.onBlurText} style={inputStyle} onKeyUp={self.handleKeyUp}/>
                  </div>)
        } else {
          return <a className="item" key={child} onClick={self.onChange.bind(self, child)}>{child}</a>
        }
      })
      let NodeName, className
      if (current === 1) {
        NodeName = 'div'
        className = 'icon disabled item'
      } else {
        NodeName = 'a'
        className = 'icon item'
      }
      children.splice(0, 0, <NodeName className={className} key="leftArrow2" onClick={current > 1 ? self.onChange.bind(self, current - 1) : undefined}><i className="left arrow icon"/></NodeName>)
      if (current === totalPages) {
        NodeName = 'div'
        className = 'icon disabled item'
      } else {
        NodeName = 'a'
        className = 'icon item'
      }
      children.splice(children.length, 0, <NodeName className={className} key="rightArrow2" onClick={current < totalPages ? self.onChange.bind(self, current + 1) : undefined}><i className="right arrow icon"/></NodeName>)
      if (this.props.rowsSelector) {
        children.unshift(<div className="item" key="gap" style={{padding: '0.1em 0.2em'}}></div>)
        children.unshift(<div className="item rows-per-page" key="rowsSelector" style={{padding: '0.1em 0.2em'}}>{this.props.rowsSelector}</div>)
      }
      return <div className="ui right floated pagination menu">{children}</div>
    } else {
      if (this.props.rowsSelector) {
        return <div className="ui right floated pagination menu"><div className="item rows-per-page" style={{padding: '0.1em 0.2em'}}>{this.props.rowsSelector}</div></div>
      } else {
        return null
      }
    }
  }

}

export {Paginator}