import React from "react"
import tippy from "tippy.js"

import {MC} from './MC.js'

class Menu extends React.Component {

  state = {openedIndex: null}

  static getDerivedStateFromProps(props, state) {
    if (MC.getFieldParamBooleanValue(props.data.param, '@dropdownSubMenu')) {
      return null
    }
    if (state.openedIndex == null) {
      let activeKey = MC.getFieldParamValue(props.data.param, '@activeKey')
      let items = MC.getFieldParamValue(props.data.param, 'items')
      if (Array.isArray(items) && items.length > 0 && activeKey) {
        for (let i=0; i<items.length; i++) {
          let item = items[i]
          let activeItem = false
          if (Array.isArray(item['items'])) {
            activeItem = item['items'].find((subitem) => {
              return subitem.key && subitem.key == activeKey
            })
          }
          if (item.key && item.key == activeKey || activeItem) {
            return {openedIndex: i}
          }
        }  
      }
    }
    return null
  }

  componentDidMount() {
    tippy('[data-tippy-content]', {arrow: true, delay: 400, animation: 'scale', theme: 'light-border'})
  }

  toggleRootItem = (index) => {
    const asDropdown = MC.getFieldParamBooleanValue(this.props.data.param, '@dropdownSubMenu')
    if (asDropdown) {
      return
    }
    let actualIndex = this.state.openedIndex
    if (actualIndex === null || actualIndex != index) {
      this.setState({openedIndex: index})
    } else {
      this.setState({openedIndex: -1})
    }
  }

  onclick = (e, item, i) => {
    e.stopPropagation()
    const field = this.props.data
    const behavior = MC.getFieldParamValue(field.param, '@behavior')
    if (Array.isArray(item['items']) && item['items'].length > 0 && item['items'][0] && item['items'][0].title) {
      this.toggleRootItem(i)
    } else if (!MC.isNull(item.url) && item.url !== '' || item.url === '' && behavior == 'url') {
      this.props.data.flow.reactFlow().routeTo(e, item.url)
      return
    } else {
      MC.putFieldParamValue(this.props.data.param, '@activeKey', item.key)
      MC.handleEvent(field, 'change')
      if (behavior == 'dialog') {
        field.flow.callDialog(field)
      } else {
        if (MC.isFunction(field.onSubmit) && !MC.isModelerActive(field)) {
          field.onSubmit(field)
        }
      }
    }
  }

  handleRootMouseEnter = (index) => {
    const asDropdown = MC.getFieldParamBooleanValue(this.props.data.param, '@dropdownSubMenu')
    if (asDropdown) {
      this.setState({openedIndex: index})
    }
  }

  handleRootMouseLeave = () => {
    const asDropdown = MC.getFieldParamBooleanValue(this.props.data.param, '@dropdownSubMenu')
    if (asDropdown) {
      this.setState({openedIndex: -1})
    }
  }

  render() {
    let params = this.props.data.param
    let activeKey = MC.getFieldParamValue(params, '@activeKey')
    let propItems = MC.getFieldParamValue(params, 'items')
    let vertical = MC.getFieldParamBooleanValue(params, '@vertical')
    let asDropdown = MC.getFieldParamBooleanValue(params, '@dropdownSubMenu')
    let iconPlacement = MC.getFieldParamValue(params, '@iconPlacement') || 'left'
    let items = []
    if (Array.isArray(propItems) && propItems.length > 0) {
      const behavior = MC.getFieldParamValue(params, '@behavior')
      for (let i=0; i<propItems.length; i++) {
        let propItem = propItems[i]
        let iconLeft = null
        let iconRight = null
        if (typeof propItem.icon == 'string') {
          if (iconPlacement == 'right') {
            iconRight = <span className="icon-span-right"><i className={propItem.icon}/></span>
          } else {
            iconLeft = <span className="icon-span-left"><i className={propItem.icon}/></span>
          }
        }
        let ItemTag = "a"
        let subitems = []
        let active = propItem.key && activeKey && propItem.key == activeKey
        let submenu
        if (Array.isArray(propItem['items']) && propItem['items'].length > 0 && propItem['items'][0] && propItem['items'][0].title) {
          if (this.state.openedIndex == i) {
            ItemTag = "div"
            active = this.state.openedIndex == i ? true : false
            for (let si=0; si<propItem['items'].length; si++) {
              let subPropItem = propItem['items'][si]
              let siconLeft = null
              let siconRight = null
              if (typeof subPropItem.icon == 'string') {
                if (iconPlacement == 'right') {
                  siconRight = <span className="icon-span-right"><i className={subPropItem.icon}/></span>
                } else {
                  siconLeft = <span className="icon-span-left"><i className={subPropItem.icon}/></span>
                }
              }
              let subcls = subPropItem.key && activeKey && subPropItem.key == activeKey ? 'item active' : 'item'
              if (subPropItem.cssclass) {
                subcls += ' ' + subPropItem.cssclass
              }
              let subtooltip = subPropItem.tooltip ? subPropItem.tooltip : null
              let href = null
              if (!MC.isNull(subPropItem.url) && subPropItem.url !== '' || subPropItem.url === '' && behavior == 'url') {
                href = subPropItem.url
              }
              subitems.push(
                <a className={subcls} href={href} onClick={(e) => this.onclick(e, subPropItem)} key={'item' + i + si} data-tippy-content={subtooltip}>
                  {siconLeft}
                  {subPropItem.title}
                  {siconRight}
                </a>)
            }
            let subCls = MC.classes('menu transition', active ? 'visible' : 'hidden')
            submenu = <div className={subCls}>{subitems}</div>
          }  
        }
        let cls = MC.classes('ui' ,{'active': active, 'visible': active && (asDropdown || !vertical), 'opened': subitems.length > 0 && !asDropdown, 'dropdown': subitems.length > 0 && (asDropdown || !vertical)}, propItem.cssclass, 'item curpointer')
        let tooltip = propItem.tooltip ? propItem.tooltip : null
        let href = null
        if (!MC.isNull(propItem.url) && propItem.url !== '' || propItem.url === '' && behavior == 'url') {
          href = propItem.url
        }
        items.push(
          <ItemTag className={cls} href={href} onClick={(e) => this.onclick(e, propItem, i)} key={i} data-tippy-content={tooltip} onMouseEnter={() => this.handleRootMouseEnter(i)} onMouseLeave={() => this.handleRootMouseLeave()}>
            {iconLeft}
            {propItem.title}
            {iconRight}
            {submenu}
          </ItemTag>) 
      }
    }
    let fitWidth = MC.getFieldParamBooleanValue(params, '@fitWidth')
    let cssclass = MC.getFieldParamValue(params, '@cssClass')
    let css = MC.classes('ui menu', fitWidth ? 'fluid' : 'compact', {'vertical': vertical}, cssclass)
    return (<div className={css}>{items}</div>)
  }
}

export default Menu