import React from "react"

import {EditableLabel} from "../modeler/EditableLabel.jsx"

import Dropdown from './semantic-widgets/Dropdown.jsx'
import Datetime from "./datetime/DateTime.jsx"
import {Widget} from "./Widget.jsx"
import {Paginator} from "./Paginator.jsx"
import {MC} from './MC.js'
import {MCHistory} from "./MCHistory.js"

class Table extends React.Component {

  tableRef = React.createRef()
  state = {}

  componentDidMount() {
    this.setState({width: this.tableRef.current.offsetWidth})
  }

  componentDidUpdate() {
    if (this.state.width !== this.tableRef.current.offsetWidth) {
      this.setState({width: this.tableRef.current.offsetWidth})
    }
  }

  buildTableRow(field, subFields, irow, disabled, readOnly, columns, selector, selected) {
    var row = []
    if (selector == 'left') {
      row.push(<td key="select" className="selector"><input checked={selected} type="checkbox" title={MC.formatMessage("selectRow")} onChange={(e) => {this.handleSelectRow(irow, e)}}/></td>)
    }
    if (Array.isArray(subFields)) {
      for (let i = 0; i< subFields.length; i++) {
        let subField = subFields[i]
        let halign =  MC.getFieldParamValue(subField.param, '@horizontalAlignment')
        let cls = MC.classes(MC.getFieldParamValue(subField.param, '@cssClass'), {'right aligned': halign == 'right', 'center aligned': halign == 'center', 
                    'ellipsis':  MC.getFieldParamBooleanValue(columns[subField.id], '@ellipsisText'), 'collapsing': MC.getFieldParamBooleanValue(columns[subField.id], '@collapsing')})
        var layoutVisible = MC.getFieldParamValue(columns[subField.id], '@layoutVisible')
        if (!MC.isNull(layoutVisible) && layoutVisible !== '') {
          layoutVisible = layoutVisible.split(' ')
        } else {
          layoutVisible = false
        }
        var columnVisible = MC.getFieldParamBooleanValue(columns[subField.id], '@visible')
        if(subField.flow.modelerReact && subField.flow.modelerReact.state.ghostMode) {
          columnVisible = true
        }
        if (!columnVisible || Array.isArray(layoutVisible) && !MC.isNull(this.props.resolution) && layoutVisible.indexOf(this.props.resolution) == -1) {
          continue
        }
        var visible = MC.getFieldParamBooleanValue(subField.param, '@visible')
        if (subField.flow.modelerReact && subField.flow.modelerReact.state.ghostMode) {
          visible = true
        }
        let inline = {}
        const percentWidth = MC.getFieldParamValue(columns[subField.id], '@percentWidth')
        if (percentWidth && MC.isNumeric(percentWidth) && this.state.width) {
          if (window.innerWidth >= 768) {
            let width = this.state.width * (parseInt(percentWidth)/100) + 'px'
            inline.width = width
            inline.maxWidth = width
          }
        }
        const minWidth = MC.getFieldParamValue(columns[subField.id], '@minWidth')
        if (minWidth && MC.isNumeric(minWidth) && this.state.width) {
          inline.minWidth = minWidth + 'px'
        }
        if (visible) {
          row.push(<td className={cls} key={i} style={inline}><Widget key={subField.rbsid} ref={subField.rbsid} widget={subField} resolution={this.props.resolution} inTable={true} disabled={disabled} readOnly={readOnly} textMode={this.props.textMode}/></td>)
        } else {
          row.push(<td className={cls} key={i}>&nbsp;</td>)
        }
      }
    }
    let dynamicColumns = field.param.dynamicColumns
    if (Array.isArray(dynamicColumns) && dynamicColumns.length > 0) {
      if (!this.dynamicColWarning) {
        MCHistory.log(MCHistory.T_WARNING, 'Mapping into "dynamicColumns" is deprecated and will be removed. Use FORM_FieldTableSimpleCreate operation nad map ouput into dynamic panel.', field.flow.debug())
        this.dynamicColWarning = true
      }
      for (let i = 0; i < dynamicColumns.length; i++) {
        let value = MC.getFieldParamValue(field.fields[0].rows[irow].param, '@dynamicValue')
        value = Array.isArray(value) && !MC.isNull(value[i]) ? value[i] : ''
        row.push(<td key={'d'+ i}>{value}</td>)
      }
    }
    if (selector == 'right') {
      row.push(<td key="select" className="selector"><input checked={selected} type="checkbox" title={MC.formatMessage("selectRow")} onChange={(e) => {this.handleSelectRow(irow, e)}}/></td>)
    }
    return row
  }

  runTableUpdate() {
    const field = this.props.data
    const opts = field.flow.getDataActionOpts(field.param)
    if (opts.action) {
      if (this.props.data.flow && MC.isFunction(this.props.data.flow.paginateForm)) {
        this.props.data.flow.paginateForm(opts)
      }
    } else {
      if (this.props.data.flow) {
        this.props.data.flow.submitForm.call(this.props.data.flow, this.props.data, true, null)
      }
    }
  }

  onPaginate = (page) => {
    MC.putFieldParamValue(this.props.data.param, 'settings/@page', page)
    this.runTableUpdate()
  }

  onRowsPerPage = (e, dropdown) => {
    MC.putFieldParamValue(this.props.data.param, 'settings/@defaultRowsPerPage', dropdown.value === "*" ? null : dropdown.value)
    MC.putFieldParamValue(this.props.data.param, 'settings/@page', dropdown.value === "*" ? null : 1)
    this.runTableUpdate()
  }

  onSort = (column) => {
    // clear old sort setting
    if (this.props.data.param.columns) {
      for (const colKey in this.props.data.param.columns) {
        if (this.props.data.param.columns[colKey] == column) {
          continue
        }  
        this.props.data.param.columns[colKey]['@sorted'] = false
        this.props.data.param.columns[colKey]['@ascending'] = true
      }
    }
    if (this.props.data.param.dynamicColumns) {
      for (const dynamicCol of this.props.data.param.dynamicColumns) {
        if (dynamicCol == column) {
          continue
        }  
        dynamicCol['@sorted'] = false
        dynamicCol['@ascending'] = true
      }
    }
    MC.putFieldParamValue(this.props.data.param, 'settings/@page', null)
    if (!column['@sorted']) {
      column['@sorted'] = true
      column['@ascending'] = true
      MC.putFieldParamValue(this.props.data.param, 'settings/@sortAscending', true)
    } else if (column['@ascending']) {
      column['@ascending'] = false
      MC.putFieldParamValue(this.props.data.param, 'settings/@sortAscending', false) 
    } else {
      column['@sorted'] = false
      MC.putFieldParamValue(this.props.data.param, 'settings/@sortColumn', null)
      MC.putFieldParamValue(this.props.data.param, 'settings/@sortAscending', null)
    }
    this.runTableUpdate()
  }

  onChangeFilter = (column, e) => {
    column['@filterValue'] = [e.target.value]
    this.forceUpdate()
  }

  handleFilterKeyUp = (e) => {
    if (e.key == 'Enter') {
      this.runTableUpdate()
    }
  }

  onChangeComboFilter = (e, combo) => {
    combo.column['@filterValue'] = combo.value
    this.runTableUpdate()
  }

  onChangeDateFilter = (field, fcolumn, value) => {
    if (value && value.isLuxonDateTime == true) {
      let withTimezone = MC.getFieldParamBooleanValue(field.param, '@outputTimezone')
      switch (field.basictype) {
        case 'dateTime': value = value.toFormat(withTimezone ? "yyyy-MM-dd'T'HH:mm:ss.SSSZZ" : "yyyy-MM-dd'T'HH:mm:ss.SSS"); break
        case 'time': value = value.toFormat(withTimezone ? "HH:mm:ssZZ" : "HH:mm:ss"); break
        default: value = value.toFormat(withTimezone ? "yyyy-MM-ddZZ" : "yyyy-MM-dd"); break
      }
      fcolumn['@filterValue'] = value
      this.runTableUpdate()
    } else {
      fcolumn['@filterValue'] = value
    }
  }

  buildFilterOptions(field) {
    let options = []
    options.push({value: '', text: ''})
    let items = MC.asArray(MC.getFieldParamValue(field, 'item'))
    for (let item of items) {
      if (!item) break
      let key = item['@key']
      let title = item['@title'] !== undefined ? item['@title'] : key
      options.push({value: key, text: title, content: title})
    }
    return options
  }

  handleSelectAll(e) {
    MC.putFieldParamValue(this.props.data.param, 'settings/@selectedAll', e.currentTarget.checked)
    if (Array.isArray(this.props.data.fields[0].rows) && this.props.data.fields[0].rows.length > 0) {
      for (let row of this.props.data.fields[0].rows) {
        MC.putFieldParamValue(row.param, '@selected', e.currentTarget.checked)
      }
    }
    this.forceUpdate()
    MC.handleEvent(this.props.data, 'change')
  }

  handleSelectRow(i, e) {
    MC.putFieldParamValue(this.props.data.fields[0].rows[i].param, '@selected', e.currentTarget.checked)
    this.forceUpdate()
    MC.handleEvent(this.props.data, 'change')
  }

  render() {
    var field = this.props.data
    var headAlign =  MC.getFieldParamValue(field.param, 'settings/@headAlign')
    var pageable = MC.getFieldParamBooleanValue(field.param, 'settings/@pageable')
    let rowsPerPageSelector = MC.getFieldParamBooleanValue(field.param, 'settings/@rowsPerPageSelector')
    let selector = MC.getFieldParamValue(field.param, 'settings/@selector')
    let selectedAll = MC.getFieldParamBooleanValue(field.param, 'settings/@selectedAll')
    let hideHeader = MC.getFieldParamBooleanValue(field.param, 'settings/@hideHeader')
    let groupsHeader = null
    let header = null
    let isTableSortable = false
    let lastGroup = ''
    let lastGroupEnd = 0
    if (!hideHeader) {
      header = []
      groupsHeader = []
      if (selector == 'left') {
        header.push(<th key="select" className="selector"><input checked={selectedAll} type="checkbox" title={MC.formatMessage("selectAllRows")} onChange={(e) => {this.handleSelectAll(e)}}/></th>)
      }
      if (Array.isArray(field.fields) && field.fields.length > 0 && field.fields[0].fields) {
        for (var i = 0; i < field.fields[0].fields.length; i++) {
          let column = field.param.columns[field.fields[0].fields[i].id]
          if (column) {
            var layoutVisible = MC.getFieldParamValue(column, '@layoutVisible')
            if (!MC.isNull(layoutVisible) && layoutVisible !== '') {
              layoutVisible = layoutVisible.split(' ')
            } else {
              layoutVisible = false
            }
            var columnVisible = MC.getFieldParamBooleanValue(column, '@visible')
            if (field.flow.modelerReact && field.flow.modelerReact.state.ghostMode) {
              columnVisible = true
            }
            if (!columnVisible || Array.isArray(layoutVisible) && !MC.isNull(this.props.resolution) && layoutVisible.indexOf(this.props.resolution) == -1) {
              continue
            }
            var sortable = MC.getFieldParamBooleanValue(column, '@sortable') === true
            var title = MC.getFieldParamValue(column, '@title')
            var cls = ''
            if (headAlign) {
              if (headAlign == 'right') {
                cls = 'right aligned'
              } else if (headAlign == 'center') {
                cls = 'center aligned'
              }
            }
            var inline = {}
            var onclick = !MC.isModelerActive(field) ? this.onSort.bind(this, column) : null
            if (!sortable) {
              inline.cursor = 'auto'
              onclick = null
            } else {
              isTableSortable = true
              if (column['@sorted']) {
                if (column['@ascending']) {
                  cls += ' sorted ascending'
                } else {
                  cls += ' sorted descending'
                }
              } else {
                cls += ' sorted sortable'
              }
            }  
            if (MC.isModelerActive(field)) {
              title = <EditableLabel field={field.fields[0].fields[i]} widget={title} path={["param", "columns", "@title"]}/>
            }
            const percentWidth = MC.getFieldParamValue(column, '@percentWidth')
            if (percentWidth && MC.isNumeric(percentWidth) && this.state.width) {
              if (window.innerWidth >= 768) {
                inline.width = this.state.width * (parseInt(percentWidth)/100) + 'px'
              }
            }
            const minWidth = MC.getFieldParamValue(column, '@minWidth')
            if (minWidth && MC.isNumeric(minWidth) && this.state.width) {
              inline.minWidth = minWidth + 'px'
            }
            cls = MC.classes({'collapsing': MC.getFieldParamBooleanValue(column, '@collapsing')}, cls)
            header.push(<th key={field.fields[0].fields[i].rbsid} className={cls} style={inline} onClick={onclick}>{title}</th>)
            let group = MC.getFieldParamValue(column, '@group')
            if ((group || groupsHeader.length > 0) && (lastGroup !== group || i == field.fields[0].fields.length-1)) {
              if (lastGroup !== group) {
                groupsHeader.push(<th key={i} colSpan={i-lastGroupEnd} className={lastGroup ? 'group' : 'group-empty'}>{lastGroup}</th>)
                lastGroupEnd = i
                lastGroup = group
              } 
              if (i == field.fields[0].fields.length-1) {
                groupsHeader.push(<th key="l" colSpan={i-lastGroupEnd+1} className={lastGroup ? 'group' : 'group-empty'}>{lastGroup}</th>)
              } 
            }
          } else {
            header.push(<th key={i}>&nbsp;</th>)
          }
        }
      }
      if (selector == 'left' && groupsHeader.length > 0) {
        groupsHeader.unshift(<th key="selector" className="group-empty">&nbsp;</th>)
      } else if (selector == 'right' && groupsHeader.length > 0) {
        groupsHeader.push(<th key="selector" className="group-empty">&nbsp;</th>)
      }
      let dynamicColumns = field.param.dynamicColumns
      if (Array.isArray(dynamicColumns) && dynamicColumns.length > 0) {
        for (let i = 0; i < dynamicColumns.length; i++) {
          let dynamicColumn = dynamicColumns[i]
          let title = MC.getFieldParamValue(dynamicColumn, '@title')
          let sortable = MC.getFieldParamValue(dynamicColumn, '@sortable') === true
          let sorted = MC.getFieldParamValue(dynamicColumn, '@sorted') === true
          let sortedAscending = MC.getFieldParamValue(dynamicColumn, '@ascending') === true
          let cls = ''
          if (headAlign) {
            if (headAlign == 'right') {
              cls = 'right aligned'
            } else if (headAlign == 'center') {
              cls = 'center aligned'
            }
          }
          let inline = {}
          let onclick = !MC.isModelerActive(field) ? this.onSort.bind(this, dynamicColumn) : null
          if (!sortable) {
            inline.cursor = 'auto'
            onclick = null
          } else {
            isTableSortable = true
            if (sorted) {
              if (sortedAscending) {
                cls += ' sorted ascending'
              } else {
                cls += ' sorted descending'
              }
            } else {
              cls += ' sorted sortable'
            }
          }
          header.push(<th key={'dynamicCol' + i} className={cls} style={inline} onClick={onclick}>{title}</th>)
        }    
      }
      if (selector == 'right') {
        header.push(<th key="select" className="selector"><input checked={selectedAll} type="checkbox" title={MC.formatMessage("selectAllRows")} onChange={(e) => {this.handleSelectAll(e)}}/></th>)
      }
      header = header.length > 0 ? <tr>{header}</tr> : null
      groupsHeader = groupsHeader.length > 0 ? <tr>{groupsHeader}</tr> : null
    }
    let filter = null
    const showFilterRow = MC.getFieldParamBooleanValue(field.param, 'settings/@showFilterRow')
    if (showFilterRow) {
      filter = []
      if (selector == 'left') {
        filter.push(<th key="select">&nbsp;</th>)
      }
      if (Array.isArray(field.fields) && field.fields.length > 0 && field.fields[0].fields) {
        for (let i=0; i < field.fields[0].fields.length; i++) {
          let colfield = field.fields[0].fields[i]
          let fcolumn = field.param.columns[colfield.id]
          if (fcolumn) {
            let layoutVisible = MC.getFieldParamValue(fcolumn, '@layoutVisible')
            if (!MC.isNull(layoutVisible) && layoutVisible !== '') {
              layoutVisible = layoutVisible.split(' ')
            } else {
              layoutVisible = false
            }
            let columnVisible = MC.getFieldParamBooleanValue(fcolumn, '@visible')
            if (field.flow.modelerReact && field.flow.modelerReact.state.ghostMode) {
              columnVisible = true
            }
            if (!columnVisible || Array.isArray(layoutVisible) && !MC.isNull(this.props.resolution) && layoutVisible.indexOf(this.props.resolution) == -1) {
              continue
            }
            if (MC.getFieldParamBooleanValue(fcolumn, '@filterable') === false) {
              filter.push(<th key={colfield.rbsid}>&nbsp;</th>)
            } else {
              const filType = MC.getFieldParamValue(fcolumn, '@filterType') || 'textbox'
              let filValue = MC.getFieldParamValue(fcolumn, '@filterValue') || ''
              if (filType == 'datebox') {
                filValue = MC.castToScalar(filValue, 'string')
                let {dateValue, dateFormat, timeFormat, withTimezone} = MC.prepeareDate(colfield, filValue)
                if (!MC.isNull(filValue) && filValue != '' && dateValue.isValid) {
                  switch (colfield.basictype) { //normalize bad mapped value for validation
                    case 'dateTime': fcolumn['@filterValue'] = dateValue.toFormat(withTimezone ? "yyyy-MM-dd'T'HH:mm:ss.SSSZZ" : "yyyy-MM-dd'T'HH:mm:ss.SSS"); break
                    case 'time': fcolumn['@filterValue'] = dateValue.toFormat(withTimezone ? "HH:mm:ssZZ" : "HH:mm:ss"); break
                    default: fcolumn['@filterValue'] = dateValue.toFormat(withTimezone ? "yyyy-MM-ddZZ" : "yyyy-MM-dd"); break
                  }
                }
                if ((!dateValue.isValid || !MC.isValidDateStringByType(filValue, colfield.basictype || 'date')) && !MC.isNull(filValue)) { // invalid value is not conveted to luxon
                  dateValue = filValue
                }
                filter.push(<th key={'filter' + i}><Datetime onChange={this.onChangeDateFilter.bind(this, colfield, fcolumn)} value={dateValue} dateFormat={dateFormat} timeFormat={timeFormat} closeOnSelect={true} locale={MC.getLang()}
                               modelerActive={MC.isModelerActive(colfield)}/></th>)
              } else if (filType == 'combobox') {
                let options = this.buildFilterOptions(fcolumn)
                filter.push(<th key={'filter' + i}>
                              <Dropdown className="fluid selection" multiple={true} key={colfield.rbsid} onChange={this.onChangeComboFilter} value={filValue} options={options} 
                              selectOnNavigation={false} selectOnBlur={false} closeOnChange={true} column={fcolumn} search={true}/>
                            </th>)
              } else {
                filter.push(<th key={'filter' + i}><input type="text" onChange={this.onChangeFilter.bind(this, fcolumn)} value={filValue} onKeyUp={this.handleFilterKeyUp}/></th>)
              }
            }
          }
        }
      }
      let dynamicColumns = field.param.dynamicColumns
      if (Array.isArray(dynamicColumns) && dynamicColumns.length > 0) {
        for (let i = 0; i < dynamicColumns.length; i++) {
          filter.push(<th key={'dynamicFilter' + i}>&nbsp;</th>)
        }
      }
      if (selector == 'right') {
        filter.push(<th key="select">&nbsp;</th>)
      }
      if (filter.length > 0) {
        filter = <tr>{filter}</tr>
      }
    }
    let count = MC.getRowsCount(Array.isArray(field.fields) && field.fields[0] ? field.fields[0] : field)
    if (MC.showAtLeastOneIteration(field) && count == 0) {
        count = 1
    }
    let body = []
    for (let i=0; i<count; i++) {
      let inlineCss = {}
      let disabled = this.props.disabled
      let readOnly = this.props.readOnly
      let cssClass = null
      let subfields = Array.isArray(field.fields) && field.fields.length > 0 && field.fields[0].fields ? field.fields[0].fields : []
      let selected = false
      if (field.fields[0].rows) {
        let row = field.fields[0].rows[i].param
        if (false === MC.getFieldParamBooleanValue(row, '@visible')) {
          inlineCss.display = 'none'
        }
        if (false === MC.getFieldParamBooleanValue(row, '@enabled') || false === MC.getFieldParamBooleanValue(row, '@permitted')) {
          disabled = true
        }
        if (true === MC.getFieldParamBooleanValue(row, '@readonly')) {
          readOnly = true
        }
        cssClass = MC.getFieldParamValue(row, '@cssClass')
        subfields = field.fields[0].rows[i].fields
        selected = MC.getFieldParamBooleanValue(row, '@selected')
      }
      cssClass = MC.classes(cssClass, {'selected': selected})
      body.push(<tr key={i} className={cssClass} style={inlineCss}>{this.buildTableRow(field, subfields, i, disabled, readOnly, field.param.columns, selector, selected)}</tr>)
    }
    if (body.length == 0) {
      let noRowText = MC.getFieldParamValue(field.param, 'settings/@noRowText')
      if (!MC.isNull(noRowText)) {
        body.push(<tr key="noRow"><td className="center aligned" colSpan={999}>{noRowText}</td></tr>)
      }
    }
    let fixed = MC.getFieldParamBooleanValue(field.param, 'settings/@fixed') || window.innerWidth < 768
    const tcls = MC.classes('ui table', {'sortable': isTableSortable, 'fixed': fixed, 'collapsing': MC.getFieldParamBooleanValue(field.param, 'settings/@collapsing')}, MC.getFieldParamValue(field.param, '@cssClass'))
    let pag = null
    // paging
    if (pageable) {
      var perPage = 20
      var defaultRowsPerPage = MC.getFieldParamValue(field.param, 'settings/@defaultRowsPerPage')
      if (MC.isNumeric(defaultRowsPerPage)) {
        perPage = parseInt(defaultRowsPerPage)
      }
      var rows = MC.getFieldParamValue(field.param, 'settings/@rows')
      var pPage = MC.getFieldParamValue(field.param, 'settings/@page')
      if (MC.isNumeric(rows)) {
        var totalCount = parseInt(rows)
        var page = 1
        if (MC.isNumeric(pPage)) {
          page = parseInt(pPage)
        } else if (Array.isArray(pPage) && MC.isNumeric(pPage[0])) {
          page = parseInt(pPage[0])
        }
        var totalPages = Math.ceil(totalCount/perPage)
        let rowsSelector
        if (rowsPerPageSelector) {
          let rowsOptions = MC.getFieldParamValue(field.param, 'settings/@rowsPerPageItems')
          if (MC.isNull(rowsOptions) || !Array.isArray(rowsOptions)) {
            rowsOptions = [5, 10, 20, 50]
          }
          let options = rowsOptions.map(v => {return {value: v, text: v}})
          if (MC.getFieldParamBooleanValue(field.param, 'settings/@rowsPerPageSelectorAll')) {
            options.push({value: "*", text: MC.formatMessage("all")})
          }
          rowsSelector = <Dropdown className="compact selection" onChange={this.onRowsPerPage} value={MC.isNull(defaultRowsPerPage) ? '*' : perPage} options={options}/>
        }
        pag = <Paginator totalPages={totalPages} page={page} onChange={this.onPaginate} key="paginator" rowsSelector={rowsSelector}/>
      }
    }
    let wrapStyle = {}
    if (MC.getFieldParamBooleanValue(field.param, 'settings/@scrollableHorizontal')) {
      wrapStyle.overflowX = 'auto'
    }
    let thead = groupsHeader || header || filter ? <thead>{groupsHeader}{header}{filter}</thead> : null
    return (
      <div className="tableWrapper" style={wrapStyle}>
        <table ref={this.tableRef} className={tcls} data-widget-i-name={field.id}>
          {thead}
          <tbody>{body}</tbody>
        </table>
        {pag}
      </div>
    )
  }

}

export {Table}