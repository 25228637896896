import {FieldDef} from "../modeler/FieldDef.js"
import {MC} from './MC.js'
import {MCCache} from "./MCCache.js"
import {MCHistory} from "./MCHistory.js"
import {MCBrws} from "./MCBrws.js"
import {Expression} from "./Expression.js"
import {ReactFlow} from "./ReactFlow.jsx"

let Flow = function(reactFlow) {

  var self = this;
  this.opStart = performance.now()
  this.opStartDate = Date.now()
  this.instanceId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
  if (reactFlow) {
    this.reactFlowObj = reactFlow
  }
  this.parentFlow = null;
  this.flowId = null;
  this.flowName = null;
  this.lang = null;
  this.flow = null;
  this.input = {};
  this.inputMapTrace = null;
  this.env = {};
  this.onEndFunction = null;
  this.afterRenderForm = null;
  this.lazyAction = null;
  this.serverSide = false;
  this.cache = false;
  this.confPath = null;
  this.confNsMap = null;
  this.context = {data: {}};
  this.wantedLogLevel = null;
  this.logLevel = null;
  this.logicTimers = {};
  this.lazyActionLogic = null;

  this.setParentFlow = function(pFlow) {
    this.parentFlow = pFlow;
    return this;
  };

  this.setFlowId = function(idval) {
    this.flowId = idval;
    return this;
  };

  this.setFlowConfiguration = function(vConfiguration, fConFlowName) {
    this.confPath = vConfiguration;
    this.flowName = fConFlowName;
    return this;
  };

  this.setFlowConfigurationProps = function(conf, fConfPath, fConFlowName, cNsMap) {
    this.conf = MC.extend(true, {}, conf);
    this.confPath = fConfPath;
    this.flowName = fConFlowName;
    this.confNsMap = cNsMap;
    return this;
  };

  this.setConfPath = function(fConfPath) {
    this.confPath = fConfPath;
    return this;
  };

  this.setLang = function(val) {
    this.lang = val;
    return this;
  };

  this.setInput = function(val) {
    this.input = val || {};
    return this;
  };

  this.setInstanceId = function(val) {
    this.instanceId = val
    return this
  }

  this.setLazyAction = function(actionCode, logic, altMapping) {
    this.lazyAction = actionCode
    this.lazyActionLogic = logic
    this.lazyActionAltMapping = altMapping
    return this
  };

  this.setServerSide = function(iface) {
    this.serverSide = true;
    if (!MC.isNull(iface)) {
      var flow = {};
      flow.id = this.flowName;
      flow.model = iface.model
      flow.kind = iface.kind
      if (iface.input) {
        flow.input = iface.input;
      }
      if (iface.output) {
        flow.output = iface.output;
      }
      if (iface.exception) {
        flow.exception = iface.exception;
      }
      this.flow = flow;
      if (iface.cache === true) {
        this.cache = true;
      }
    }
    return this;
  };

  this.setOnEndFunction = function(val) {
    if (MC.isFunction(val)) {
      this.onEndFunction = val;
    } else {
      this.endOperationException('SYS_UnrecoverableRuntimeExc', "Parameter of called 'setOnEndFunction' has to be function!");
    }
    return this;
  };

  this.setAfterRenderFormFunction = function(val) {
    if (MC.isFunction(val)) {
      this.afterRenderForm = val;
    } else {
      this.endOperationException('SYS_UnrecoverableRuntimeExc', "Parameter of called 'setAfterRenderFormFunction' has to be function!");
    }
    return this;
  };

  this.setEnv = function(env) {
    this.env = env;
  };

  this.setWantedLogLevel = function(level) {
    if (!level) {
      this.wantedLogLevel = null
      return
    }
    if (level == 'true' || level == true) {
      level = 'AUTO'
    }
    const knownParams = ['AUTO', 'MINIMAL', 'BASIC', 'DETAIL', 'TRACE']
    if (knownParams.indexOf(level) < 0) {
      this.endOperationException('SYS_UnrecoverableRuntimeExc', `Parameter of called 'setWantedLogLevel' must be one of ${knownParams} or true!`)
    }
    this.wantedLogLevel = level
  }

  this.loadAndStart = function(input, inputMapTrace) {
    var self = this;
    if (!this.confPath) {
      this.endOperationException('SYS_UnrecoverableRuntimeExc', 'Configuration path must be set!');
      return;
    }
    if (this.confPath && !this.flowId && !this.flowName) {
      this.endOperationException('SYS_UnrecoverableRuntimeExc', 'Id or name of flow must be set when model is not empty!');
      return;
    }
    if (!this.lang) {
      this.endOperationException('SYS_UnrecoverableRuntimeExc', 'Lang must be set!');
      return;
    }
    if (!this.parentFlow && this.reactFlow()) {
      this.reactFlow().setState({dimmer: true, runReady: false});
    }
    this.input = input || {};
    this.inputMapTrace = inputMapTrace;
    let origConf = this.conf || this.env.cfg
    if (!origConf && this.context.data.env) {
      origConf = this.context.data.env.cfg
    }
    if (origConf) {
      this.loadAndStartStep1(origConf);
    } else {
      MC.getConfiguration(this.confPath, this.wantedLogLevel, this).then(function (conf) {
        self.loadAndStartStep1(conf);
      });
    }
  };

  this.getFlowDefinition = function() {
    var self = this;
    return new Promise(function(resolve, reject) {
      if (!MC.isNull(self.flow)) {
        resolve(self.flow);
      } else {
        var url = ReactFlow.flowTemplate.replace('{configuration}', self.confPath).replace('{flowId}', self.flowId || '').replace('{flowName}', self.flowName || '').replace('{lang}', self.lang);
        var data = MCCache.get(url);
        if (data != null) {
          resolve(data);
        } else {
          MC.callServer('GET', url, MC.getJsonType()).then(function (result) {
            if (result.status == 200) {
              var def = JSON.parse(result.content);
              MCCache.put(url, def);
              resolve(def);
            } else {
              reject('Error in flow definition at RI ' + url  + '\n' + result.content);
            }
          }).catch(function (err) {
            if (navigator.onLine) {
              self.endOperationException('SYS_IntegrationExc', 'Reading flow definition failed for url ' + url + ': ' + err.message);
              return;
            } else {
              self.endOperationException('SYS_SystemUnavailableExc', 'Internet connection is not available for url ' + url + ': ' + err.message);
              return;
            }
          });
        }
      }
    });
  };

  this.loadAndStartStep1 = function(conf) {
    if (this.reactFlow() && !this.reactFlow().props.parent) {
      if (conf && ['init', 'none'].indexOf(conf['mini:loader']) > -1) {
        if (this.reactFlow().state.loader !== conf['mini:loader']) {
          this.reactFlow().setState({loader: conf['mini:loader']})
        }
      } else {
        if (this.reactFlow().state.loader !== 'all') {
          this.reactFlow().setState({loader: 'all'})
        }
      }
    }
    self = this;
    this.getFlowDefinition().then(function (data) {
      self.flow = data;
      self.selectLogLevel(conf)
      if (self.serverSide || self.flow.kind == 'integration') {
        if (!self.parentFlow) {
          MCHistory.history(self, null, 'OPERATION START', {'Input': self.input}, {end: this.opStartDate});
        }
        self.runOnServer();
      } else {
        if (!self.parentFlow) {
          self.env.system = {};
          self.env.system.flowId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
            return v.toString(16);
          });
          self.env.system.correlationId = self.env.system.flowId
          self.env.system.nodeName =  navigator.userAgent
          self.env.system.flowConfig = self.confPath
          self.env.system.language = self.lang
          if (!MC.isNull(rbUserLoginId)) {
            self.env.system.userLoginId = rbUserLoginId
          }
          if (!MC.isNull(conf)) {
            MC.prepareNamespaces(conf, self.confNsMap)
            MC.translateNamespaces(conf, self.flow.ns)
            self.env.cfg = conf
          }
        }
        self.env.operation = {};
        self.env.operation.operationName = self.flow.id;
        self.env.operation.rootOperationName = self.parentFlow ? self.parentFlow.flow.id : self.flow.id;
        self.env.ns = self.flow.ns;
        if (MC.isNull(self.env.context)) {
          MC.getEnvironmentContext(self.confPath, conf['fl:environmentOperation']).then(function(context) {
            self.env.context = context;
            if (!MC.isNull(context) && MC.isPlainObject(context)) {
              if (MC.isPlainObject(context.request) && !MC.isNull(context.request.language)) {
                self.setLang(context.request.language);
              }
              if (MC.isPlainObject(context.internalUser) && !MC.isNull(context.internalUser.internalUserId)) {
                self.env.system.userLoginId = context.internalUser.internalUserId;
              }
            }
            self.loadAndStartStep2();
          });
        } else {
          self.loadAndStartStep2();
        }
      }
    }).catch(function (err) {
      self.endOperationException('SYS_IntegrationExc', 'Reading flow definition failed: ' + err)
      return;
    })
  };

  this.loadAndStartStep2 = function() {
    this.addToContext(self.context.data, 'env', this.env);
    if (!this.flow.action && !this.parentFlow) {
      MCHistory.history(this, null, 'OPERATION START', {'Input': this.input, 'Environment': this.debug('TRACE') ? this.env : null}, {end: this.opStartDate});
    }
    if (!MC.isNull(this.flow.svl)) {
      this.addToContext(self.context.data, 'svl', this.flow.svl);
    }
    if (!MC.isNull(this.flow.vmt)) {
      this.addToContext(self.context.data, 'vmt', this.flow.vmt);
    }
    this.progress({'Input': this.input, 'Trace': this.inputMapTrace});
  };

  this.selectLogLevel = function(conf) {
    if (this.wantedLogLevel === 'AUTO') {
      const knownParams = ['MINIMAL', 'BASIC', 'DETAIL', 'TRACE']
      if (conf['fl:componentLoggingThreshold']) {
        for (let cmpSetting of MC.asArray(conf['fl:componentLoggingThreshold'])) {
          if (cmpSetting['fl:name'] && (cmpSetting['fl:level'] || cmpSetting['fl:operationLoggingThreshold'])) {
            let cmpName = cmpSetting['fl:name'].startsWith('/') ? cmpSetting['fl:name'].substring(1) : cmpSetting['fl:name']
            if (cmpName === this.flow.model) {
              if (cmpSetting['fl:operationLoggingThreshold']) {
                for (let opSetting of MC.asArray(cmpSetting['fl:operationLoggingThreshold'])) {
                  if (MC.asArray(opSetting['fl:name']).indexOf(this.flow.id) >= 0) {
                    if (knownParams.indexOf(opSetting['fl:level']) < 0) {
                      this.endOperationException('SYS_UnrecoverableRuntimeExc', `Value of configuration parameter 'fl:componentLoggingThreshold/fl:operationLoggingThreshold/fl:level' must be one of ${knownParams}!`);
                    } else {
                      this.logLevel = opSetting['fl:level']
                    }
                  }
                }
              }
              if (!this.logLevel && cmpSetting['fl:level']) {
                if (knownParams.indexOf(cmpSetting['fl:level']) < 0) {
                  this.endOperationException('SYS_UnrecoverableRuntimeExc', `Value of configuration parameter 'fl:componentLoggingThreshold/fl:level' must be one of ${knownParams}!`);
                } else {
                  this.logLevel = cmpSetting['fl:level']
                }
              }
            }
          }
        }
      }
      if (!this.logLevel) {
        if (conf['fl:defaultLoggingThreshold']) {
          if (knownParams.indexOf(conf['fl:defaultLoggingThreshold']) < 0) {
            this.endOperationException('SYS_UnrecoverableRuntimeExc', `Value of configuration parameter 'fl:defaultLoggingThreshold' must be one of ${knownParams}!`);
          } else {
            this.logLevel = conf['fl:defaultLoggingThreshold']
          }
        } else {
          this.logLevel = 'TRACE'
        }
      }
    } else if (this.wantedLogLevel) {
      this.logLevel = this.wantedLogLevel
    } else {
      this.logLevel = null
    }
  }

  this.progress = function(logObject) {
    if (this.flow.mock && this.context.data.env && this.context.data.env.cfg && this.context.data.env.cfg["fl:useMocks"] == "true") {
      var mockOutput = this.runMock();
      if (mockOutput !== false) {
        this.endOperation(mockOutput);
        return;
      }
    }
    if (this.flow.kind == 'function') {
      this.runFunctionOperation();
    } else if (this.flow.kind == 'framework') {
      this.runFrameworkOperation();
    } else if (this.flow.kind == 'decisiontable') {
      this.runDecisionTableOperation()
    } else if (this.flow.action) {
      if (!this.context.nextAction) {
        this.context.nextAction = this.getStartAction();
      } else {
        let text = null
        if (logObject.isException) {
          text = 'EXCEPTION END'
          delete logObject.isException
        }        
        MCHistory.history(this, this.context.action, text, logObject, {start: this.context.actionStartDate, end: Date.now(), duration: performance.now() - this.context.actionStart})
      }
      this.context.action = this.context.nextAction;
      this.initActionStartTime()
      this.context.altmapping = this.context.nextAltmapping
      this.context.nextAction = this.getActionById(this.context.action.nextaction)
      this.context.nextAltmapping = this.context.action.altmapping
      if (!this.context.nextAction && this.context.action.kind != 'end' && this.context.action.kind != 'decision' && !(this.context.action.kind == 'call' && this.context.action.leaveFlow)) {
        this.endOperationException('SYS_InvalidModelExc', this.context.action.kind + " action must have next action defined!");
        return;
      }
      var state = this.context.action.kind;
      switch (state) {
        case "start":
          this.startAction();
          break;
        case "form":
          this.formAction();
          break;
        case "call":
          this.callAction();
          break;
        case "decision":
          this.decisionAction();
          break;
        case "end":
          this.endAction();
          break;
        case "transform":
          this.transformAction();
          break;
        case "feedback":
          this.feedbackAction();
          break;
        default:
          this.endOperationException('SYS_InvalidModelExc', 'Unsupported action kind: "' + state + '"!');
          break;
      }
    } else {
      this.endOperationException('SYS_InvalidModelExc', "Operation has no action or has not supported operation type '" + this.flow.kind + "'!");
    }
  };

  this.progressLazyForm = function(formData, logObject, lazyAction, lazyActionLogic, altMapping) {
    if (!MC.isNull(lazyAction)) {
      MCHistory.history(this, lazyAction, null, logObject, {start: this.context.actionStartDate, end: Date.now(), duration: performance.now() - this.context.actionStart})
      this.initActionStartTime()
    }
    if (self.context.action.kind !== 'form' || lazyActionLogic && lazyActionLogic.formExecutionId && lazyActionLogic.formExecutionId != self.formExecutionId) {
      return
    }
    if (!MC.isNull(this.context.dataActions)) {
      let [input, trace] = this.mapToResultObject(this.selectMapping(self.context.action, altMapping), self.context)
      if (input) {
        self.setFormFields(formData, input)
      } else {
        this.endOperationException('SYS_MappingExc', trace[0], null, null, null, trace[1])
        return
      }
      MCHistory.history(self, self.context.action, 'FORM PRE-RENDER', {'Input': input, 'Trace' : trace, target: formData}, {start: this.context.actionStartDate, end: Date.now(), duration: performance.now() - this.context.actionStart})
      this.formData = formData
      this.initActionStartTime()
      self.callAction(this.context.dataActions.pop())
    } else {
      delete this.context.dataActions;
      let [input, trace] = this.mapToResultObject(this.selectMapping(self.context.action, altMapping), self.context)
      if (input) {
        self.setFormFields(formData, input)
      } else {
        this.endOperationException('SYS_MappingExc', trace[0], null, null, null, trace[1])
        return
      }
      let formTitle = formData.title
      if (formData.param && formData.param['@title']) {
        formTitle = formData.param['@title']
      }
      if (formTitle) {
        document.title = formTitle
      }
      if (MC.isFunction(self.afterRenderForm)) {
        self.afterRenderForm(formData, true)
      }
      if (!MC.isNull(self.context.feedback)) {
        formData.feedback = self.context.feedback;
        self.context.feedback = [];
      }
      if(formData.flow && formData.flow.modelerReact) {
        formData.flow.modelerReact.resetStacks();
      }
      if (this.reactFlow()) {
        if (this.reactFlow().props.autoScrollUp !== false && this.hardRenderMode) { // it called only in form action and not in dialogs
          // scroll up must be called before setState for working focus
          window.scrollTo(0, 0);
        }
        let runReady = false;
        if (this.hardRenderMode) {
          runReady = true;
          this.hardRenderMode = false;
        }
        MCHistory.history(self, self.context.action, 'FORM RENDER', {'Input': input, 'Trace' : trace, target: formData}, {start: this.context.actionStartDate, end: Date.now(), duration: performance.now() - this.context.actionStart});
        this.reactFlow().setState({state: 'form', formData: formData, dimmer: false, runReady: runReady, firstFormRendered: true});
      }
      if (lazyActionLogic) {
        this.initLogicTimer(lazyActionLogic)
        if (lazyActionLogic.event.find(e => e.e == 'beforeunload')) {
          this.reactFlow().resolveUnload(formData)
        }
      }
    }
  };

  this.getStartAction = function() {
    if (this.flow.action) {
      for (var i=0; i < this.flow.action.length; i++) {
        if (this.flow.action[i].code == 'start') {
          return this.flow.action[i];
        }
      }
    }
    this.endOperationException('SYS_InvalidModelExc', "Can not find start action!");
    return null;
  };

  this.getActionById = function(id) {
    if (this.flow.action) {
      for (var i=0; i < this.flow.action.length; i++) {
        if (this.flow.action[i].id == id) {
          return this.flow.action[i];
        }
      }
    }
    return null;
  };

  this.getActionByCode = function(code) {
    if (this.flow.action) {
      for (var i=0; i < this.flow.action.length; i++) {
        if (this.flow.action[i].code == code) {
          return this.flow.action[i];
        }
      }
    }
    return null;
  };

  this.convertInputTreeData = function(inputTree, valueTree, contextTree) {
    if (Array.isArray(valueTree) && valueTree.length > 0) {
      valueTree = valueTree[0]
    }
    for (let i=0; i<inputTree.input.length; i++) {
      let param = inputTree.input[i]
      let key = param.name
      if (MC.isNull(key)) {
        if (param.basictype == 'anyType' && !MC.isNull(valueTree)) {
          MC.extend(true, contextTree, valueTree)
        }
      } else {
        if (key.endsWith('*')) {
          key = key.substring(0, key.length-1)
        }
        if (valueTree && !MC.isNull(valueTree[key])) {
          try {
            let values
            let inValues = valueTree[key]
            if (param.name.endsWith('*')) {
              if (!Array.isArray(inValues)) {
                let arr = []
                arr.push(inValues)
                inValues = arr
              }
              values = []
              for (let m=0; m<inValues.length; m++) {
                if (typeof inValues[m] === 'undefined') continue
                let value
                if (param.input && inValues[m] !== '') {
                  value = {}
                  if (!this.convertInputTreeData(param, inValues[m], value)) {
                    return false
                  }
                } else {
                  if (inValues[m].content) {
                    value = MC.normalizeValue(inValues[m].content, param.basictype)
                  } else {
                    value = MC.normalizeValue(inValues[m], param.basictype)
                  }
                }
                values.push(value)
              }
            } else {
              if (Array.isArray(inValues)) {
                inValues = inValues[0]
              }
              if (param.input && inValues !== '') {
                values = {}
                if (!this.convertInputTreeData(param, inValues, values)) {
                  return false
                }
              } else {
                if (inValues.content) {
                  values = MC.normalizeValue(inValues.content, param.basictype)
                } else {
                  values = MC.normalizeValue(inValues, param.basictype)
                }
              }
            }
            contextTree[key] = values
          } catch (e) {
            e.message = 'Error parsing operation input: ' + e.message
            this.endOperationException('SYS_MappingExc', e)
            return false
          }
        } else {
          if (param.mandat) {
            this.endOperationException('SYS_MappingExc', "Input parameter '" + param.name + "' must have value!")
            return false
          }
        }
      }
    }
    return true
  }

  this.addToContext = function(context, actionCode, object) {
    if (MC.isNull(object)) {
      delete context[actionCode]
    } else {
      context[actionCode] = object
    } 
  }

  this.startAction = function() {
    if (this.flow.input && Array.isArray(this.flow.input)) {
      var result = {};
      if (this.convertInputTreeData(this.flow, this.input, result)) {
        this.addToContext(self.context.data, 'start', result);
        this.progress({'Input': this.input, 'Output': result, 'Environment': this.debug('TRACE') ? this.env : null});
      }
    } else {
      this.progress({'Input': this.input, 'Environment': this.debug('TRACE') ? this.env : null});
    }
  };

  this.findAllLazyDataActions = function(field, result) {
    if (!result) {
      result = []
    }
    if ('embeddeddialog' == field.widget) {
      return result
    }
    const opts = this.getDataActionOpts(field.param)
    if (!MC.isNull(opts.action)) {
      result.push(opts)
    } else if (field.fields) {
      for (let i = 0; i < field.fields.length; i++) {
        result = this.findAllLazyDataActions(field.fields[i], result)
      }
    }
    return result 
  }

  this.getDataActionOpts = function(param) {
    const dataAction = MC.getFieldParamValue(param, '@dataAction')
    const dataEvent = MC.getFieldParamValue(param, '@dataEvent')
    let opts = {} 
    if (dataAction) {
      opts.action = this.getActionByCode(dataAction)
      MCHistory.log(MCHistory.T_WARNING, 'Using "@dataAction" is deprecated. Use "@dataEvent" with corresponding form event instead.', this.debug())
    } else if (dataEvent) {
      if (Array.isArray(this.context.action.actionLink)) {
        let event = this.context.action.actionLink.find(el => el.name == dataEvent)
        if (event && event.nextaction) {
          opts.altMapping = event.altmapping
          opts.altMappingBack = event.altmappingback
          opts.action = this.getActionById(event.nextaction)
        }
      }
    }
    return opts
  }

  this.formAction = function() {
    if (this.getRootFlow().isRenderingInterupted) {
      return
    }
    let formId = this.context.action.form
    this.context.data['@lastFormAction'] = this.context.action.code
    if (!formId) {
      this.endOperationException('SYS_InvalidModelExc', "Form action '" + this.context.action.code + "' has no form selected!")
      return
    }
    let form = this.flow.form[formId]
    let dataActions = this.findAllLazyDataActions(form)
    if (!MC.isNull(dataActions)) {
      this.context.dataActions = dataActions
    }
    this.hardRenderMode = true
    this.formExecutionId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8); return v.toString(16);})
    this.progressLazyForm(this.prepareFormData(MC.extend(true, {}, form), formId), null, null, null, null)
  }

  this.prepareFormData = function(formData, formId) {
    formData.formId = formId;
    formData.model = this.flow.model;
    formData.lang = this.lang;
    MC.setPropertyRecusively(formData, 'fields', 'flow', this)
    MC.setPropertyRecusively(formData, 'fields', 'onSubmit', this.handleSubmit)
    if (this.flow.progressBar) {
      formData.progressBar = this.flow.progressBar;
      formData.progressBar.active = this.context.action.code;
    }
    return formData;
  };

  this.handleSubmit = (field) => {
    if (MC.isModelerActive(field)) {
      return
    }
    let self = this
    let allValues = true
    let submit = false
    if (['submit', 'submitbydefault'].indexOf(field.param['@behavior']) > -1) {
      self.focusedOnFirst = false
      self.eventForm(field, 'beforesubmit')
      MC.validateFieldTree(self.reactFlow().state.formData, field, 0).then(function (valid) {
        if (valid) {
          self.submitForm(field, allValues, null)
        } else {
          self.reactFlow().setState({runReady: false})
        }
      }).catch(function (exception) {
        if (MC.isPlainObject(exception) && !MC.isNull(exception.type)) {
          self.endOperationException(exception.type, exception.message, exception.input, exception.output, exception.log)
        } else {
          self.endOperationException('SYS_UnrecoverableRuntimeExc', exception)
        }
      })
    } else if (['cancel', 'cancelbydefault'].indexOf(field.param['@behavior']) > -1) {
      submit = true
      allValues = false
    } else if (field.param['@behavior'] == 'store') {
      submit = true
    }
    if (submit) {
      self.submitForm(field, allValues, null)
    }
  }

  this.submitForm = function(triggeredByField, allValues, submitAction) {
    this.clearLogicTimers();
    if (triggeredByField && MC.getFieldParamBooleanValue(triggeredByField.param, '@confirm')) {
      let message = MC.getFieldParamValue(triggeredByField.param, '@confirmMessage')
      if (!message) {
        message = MC.formatMessage("confirm");
      }
      this.reactFlow().setState({message: {heading: message, size: 'tiny', onClose: this.cancelSubmitForm, buttons: [
            {title: "OK", class: "green", icon: "checkmark icon", action: this.confirmSubmitForm.bind(this, triggeredByField, allValues, submitAction)},
            {title: MC.formatMessage("cancel"), class: "orange",  icon: "cancel icon", action: this.cancelSubmitForm}
          ]}, runReady: false});
    } else {
      this.confirmSubmitForm(triggeredByField, allValues, submitAction)
    }
  };

  this.initActionStartTime = function() {
    this.context.actionStart = performance.now()
    this.context.actionStartDate = Date.now()
  }

  this.confirmSubmitForm = function(triggeredByField, allValues, submitAction) {
    this.initActionStartTime()
    var action = this.context.action;
    var output = {};
    let repeaterRows = triggeredByField ? MC.getFieldParamValue(triggeredByField.param, '@iteration') : null
    this.formExecutionId = null
    this.mapFormOutput(this.reactFlow().state.formData, output, triggeredByField, allValues, 'no', repeaterRows, submitAction);
    this.addToContext(this.context.data, action.code, output);
    this.reactFlow().setState({dimmer: this.reactFlow().state.loader == 'all', runReady: false, message: null});
    this.progress({'Output': output, target: this.reactFlow().state.formData})
  }

  this.cancelSubmitForm = () => {
    this.reactFlow().setState({message: null});
  };

  this.eventForm = function(triggeredByField, event, target, receivedData, options) {
    var self = this;
    var formData = self.reactFlow().state.formData;
    var logic = [];
    let resolveUnload = 'beforeunload' == event
    if (!MC.isNull(formData) && Array.isArray(formData.logic)) {
      for (var i = 0; i < formData.logic.length; i++) {
        var actl =  formData.logic[i];
        if (Array.isArray(actl.event)) {
          for (var e = 0; e < actl.event.length; e++) {
            if (actl.event[e]['e'] == event && (Array.isArray(actl.event[e]['f']) && actl.event[e]['f'].indexOf(triggeredByField.rbsid) > -1 || MC.isNull(actl.event[e]['f']))) {
              if (logic.indexOf(actl) == -1) {
                if (event == 'click') {
                  var behavior = MC.getFieldParamValue(triggeredByField.param, '@behavior')
                  if (!MC.isNull(behavior) && behavior !== '' && behavior !== 'formlogic') {
                    continue;
                  }
                }
                logic.push(actl);
              }
            }
          }
        }
        if (event === actl.name) { // call from timer
          if (logic.indexOf(actl) == -1) {
            logic.push(actl);
          }  
        }
      }
    }
    if (logic.length > 0) {
      let lStart = performance.now()
      let lStartDate = Date.now()
      var context = {};
      context['@event'] = event;
      context['@widgetName'] = triggeredByField ? triggeredByField.id : formData.name;
      context['@widgetPath'] = self.getFormFieldPath(formData, triggeredByField, '');
      context['@widgetIndex'] = triggeredByField ? MC.getFieldParamValue(triggeredByField.param, '@iteration') : null
      context['@widgetTarget'] = target;
      context['env'] = {};
      context['svl'] = {};
      context['vmt'] = {};
      for (var prop in self.context.data) {
        if (prop == 'env' || prop == 'svl' || prop == 'vmt') {
          context[prop] = self.context.data[prop]
        }
      }
      if (!MC.isNull(receivedData) && MC.isPlainObject(receivedData)) {
        context['eventData'] = receivedData;
      }
      if (!triggeredByField) {
        triggeredByField = true;
      }
      if (['keypress', 'keyup', 'keydown'].indexOf(event) > -1) {
        let e = options.e
        let kData = {}
        kData.key = e.key
        kData.isAlt = e.altKey
        kData.isCtrl = e.ctrlKey
        kData.isShift = e.shiftKey
        kData.isMeta = e.metaKey
        context.keyboardData = kData
      }
      loopLogics:
      for (var l = 0; l < logic.length; l++) {
        context.form = {};
        this.mapFormOutput(formData, context.form, triggeredByField, true, 'no', null, null)
        context.form.data = formData.data
        let condLog = []
        if (Array.isArray(logic[l].condition) && logic[l].condition.length > 0) {
          for (var i = 0; i < logic[l].condition.length; i++) {
            var expression = new Expression(logic[l].condition[i], context);
            var res = expression.evaluate();
            if (expression.getError()) {
              this.endOperationException('SYS_MappingExc', expression.getErrorMessage());
              return false;
            }
            if (this.debug('TRACE')) {
              condLog.push({result: res, trace: expression.getTrace()})
            }
            if (!res) {
              MCHistory.history(self, self.context.action, 'FORM LOGIC ' + logic[l].name, {'Input': context, 'Evaluated conditions': condLog.length > 0 ? condLog : null}, {start: lStartDate, end: Date.now(), duration: performance.now() - lStart})
              this.initLogicTimer(logic[l]);
              continue loopLogics;
            }
          }
        }
        if (['keypress', 'keyup', 'keydown'].indexOf(event) > -1) {
          options.e.preventDefault()
        }  
        var logContext = MC.extend(true, {}, context);
        let [input, trace] = self.mapToResultObject({mapping: logic[l].mapping}, {data: context})
        if (!input) {
          this.endOperationException('SYS_MappingExc', trace[0], logContext, null, null, trace[1])
          return
        }
        this.setFormFields(formData, input)
        this.reactFlow().setState({state: 'form', dimmer: false, formData: formData, runReady: false})
        MCHistory.history(self, self.context.action, 'FORM LOGIC ' + logic[l].name, {'Input': logContext, 'Evaluated conditions': condLog.length > 0 ? condLog : null, 'Output': input, 'Trace' : trace}, {start: lStartDate, end: Date.now(), duration: performance.now() - lStart})
        if (!MC.isNull(logic[l].dataAction) || !MC.isNull(logic[l].actionLink)) {
          this.initActionStartTime()
          if (self.context.action.kind !== 'form') {
            return
          }
          logic[l].formExecutionId = this.formExecutionId
          resolveUnload = false
          let dRes = self.callLogicAction(logic[l], triggeredByField)
          if (!MC.isNull(logic[l].dataAction)) {
            MCHistory.log(MCHistory.T_WARNING, 'Using "data action" in form logic is deprecated. Use corresponding form event instead.', this.debug())
          }
          if (!MC.isNull(dRes)) {
            self.reactFlow().setState({dialog: {flowName: dRes.flowName, input: dRes.input, trace: dRes.trace, actionCode: dRes.actionCode, triggeredByField: triggeredByField, size: dRes.size, 
              start: true, parentFlow: this, logic: logic[l], altMapping: dRes.altMapping}, runReady: false})
            MCHistory.history(this, this.getActionByCode(dRes.actionCode), 'DIALOG CALL', {'Input': dRes.input, 'Trace': dRes.trace, target: {...dRes.action.interface, ...{id: dRes.flowName}}}, {start: lStartDate, end: Date.now(), duration: performance.now() - lStart})
          }
          if (dRes === undefined) {
            continue loopLogics
          }
        }
        var behavior = logic[l].behavior;
        if (!MC.isNull(logic[l].behavior)) {
          if ('submit' == behavior) {
            this.focusedOnFirst = false;
            this.eventForm(triggeredByField, 'beforesubmit')
            MC.validateFieldTree(this.reactFlow().state.formData, triggeredByField, 0).then(function (valid) {
              if (valid) {
                self.submitForm(triggeredByField, true, logic[l].name)
              } else {
                self.reactFlow().forceUpdate();
                this.initLogicTimer(logic[l]);
              }
            }).catch(function (exception) {
              if (MC.isPlainObject(exception) && !MC.isNull(exception.type)) {
                this.endOperationException(exception.type, exception.message, exception.input, exception.output, exception.log);
              } else {
                this.endOperationException('SYS_UnrecoverableRuntimeExc', exception);
              }
            });
          } else {
            this.submitForm(triggeredByField, (behavior == 'cancel') ? false : true, logic[l].name)
          }
          return;
        } else if (MC.isNull(logic[l].dataAction) && MC.isNull(logic[l].actionLink)) {
          this.initLogicTimer(logic[l])
        }
      }
    }
    if (resolveUnload) {
      this.reactFlow().resolveUnload(formData)
    }
  }

  this.endDialog = function(output, message, notRerender) {
    let actionCode = this.reactFlow().state.dialog.actionCode
    let input = this.reactFlow().state.dialog.input
    let submitOpts = null
    let dAction = this.getActionByCode(actionCode)
    let dialogInState = this.reactFlow().state.dialog
    if (dAction && dAction.submitParent) {
      submitOpts = {}
      submitOpts.triggeredByField = dialogInState.triggeredByField
    }
    let logic = this.reactFlow().state.dialog.logic
    this.reactFlow().setState({dialog: null})
    if (!MC.isNull(message)) {
      this.endOperationException(output, message, input, null, null)
    } else if (!notRerender) {
      this.endEmbeddedDialog(actionCode, input, output, submitOpts, logic, dialogInState.altMapping)
    }
  }

  this.closeDialog = function() {
    const actionCode = this.reactFlow().state.dialog.actionCode
    this.addToContext(this.context.data, actionCode, null)
    let submitOpts = null
    const dAction = this.getActionByCode(actionCode)
    if (dAction.submitParent) {
      submitOpts = {}
      submitOpts.triggeredByField = this.reactFlow().state.dialog.triggeredByField
    }
    this.reactFlow().setState({dialog: null})
    if (submitOpts) {
      this.submitForm(submitOpts.triggeredByField, true, null)
    }
  }

  this.endEmbeddedDialog = function(actionCode, input, output, submitOpts, logic, altMapping) {
    this.addToContext(this.context.data, actionCode, output)
    this.progressLazyForm(this.reactFlow().state.formData, {'Input': input, 'Output': output}, null, logic, altMapping)
    if (submitOpts) {
      this.submitForm(submitOpts.triggeredByField, true, null)
    }
  }

  this.paginateForm = function(opts) {
    this.initActionStartTime()
    var action = this.context.action
    var output = {}
    this.mapFormOutput(this.reactFlow().state.formData, output, null, true, 'no', null, null)
    this.addToContext(this.context.data, action.code, output)
    this.reactFlow().setState({dimmer: this.reactFlow().state.loader == 'all', runReady: false})
    this.callAction(opts)
  }

  this.mapFormOutput = function(definition, contextTree, triggeredByField, allValues, iterations, repeaterRows, submitAction) {
    if (definition.formId) { // is form root
      if (triggeredByField) {
        contextTree['@submitTrigger'] = triggeredByField.id
        contextTree['@submitTriggerPath'] = this.getFormFieldPath(definition, triggeredByField, '')
        if (!MC.isNull(repeaterRows)) {
          contextTree['@submitTriggerIndex'] = repeaterRows;
        }
      }
      if (submitAction) {
        contextTree['@submitAction'] = submitAction
      }
      contextTree['@width'] = this.reactFlow().domElement.offsetWidth
      MC.extend(contextTree, definition.param)
    }
    if (definition.id === 'rows*') {
      if (Array.isArray(repeaterRows) && (!Array.isArray(iterations) || iterations.length < repeaterRows.length)) {
        let iterationsToPass = Array.isArray(iterations) ? repeaterRows.slice(0, iterations.length+1) : [repeaterRows[0]]
        this.mapFormOutputStep(definition.rows[iterationsToPass[iterationsToPass.length-1]], contextTree, triggeredByField, allValues, iterationsToPass, repeaterRows)
      } else {
        if (Array.isArray(definition.rows) && definition.rows.length > 0) {
          for (var i=0; i<definition.rows.length; i++) {
            let iterationsToPass = Array.isArray(iterations) ? [...iterations, i] : [i]
            this.mapFormOutputStep(definition.rows[i], contextTree, triggeredByField, allValues, iterationsToPass, true)
          }
        }
      }
    } else {
      this.mapFormOutputStep(definition, contextTree, triggeredByField, allValues, iterations, repeaterRows)
    }
  }

  this.setParamInFormOutput = function(object, prop, valueObj, iterations) {
    if (MC.isPlainObject(valueObj)) {
      object[prop] = {};
      for (var propIn in valueObj) {
        var param = valueObj[propIn];
        if (Array.isArray(param) && Array.isArray(iterations)) {
          this.setParamInFormOutput(object[prop], propIn, MC.getFieldParamValue(valueObj, propIn))
        } else {
          this.setParamInFormOutput(object[prop], propIn, param, iterations);
        }
      }
    } else {
      if (Array.isArray(iterations) && Array.isArray(object)) {
        object[iterations[iterations.length - 1]][prop] = valueObj;
      } else {
        object[prop] = valueObj;
      }
    }
  };

  this.mapFormOutputStep = function(definition, contextTree, triggeredByField, allValues, iterations, repeaterRow) {
    for (var i=0; i<definition.fields.length; i++) {
      var field = definition.fields[i];
      var values = {};
      let enabled = field.param && field.param['@enabled'] != false && field.param['@permitted'] != false
      if (field.fields && field.fields.length > 0 && enabled) {
        if (field.id === 'rows*') {
          if (!MC.isCorrespondingRepeater(field, triggeredByField)) {
            repeaterRow = null
          }
          values = []
          this.mapFormOutput(field, values, triggeredByField, allValues, iterations, repeaterRow, null)
        } else if (field.widget === 'dynamicPanel') {
          let dynamicOutput = {}
          this.mapFormOutput(field, dynamicOutput, triggeredByField, allValues, iterations, repeaterRow, null)
          values['fields'] = dynamicOutput
        } else {    
          this.mapFormOutput(field, values, triggeredByField, allValues, iterations, repeaterRow, null)
        }
      }
      if (allValues && enabled) {
        if (field.id !== 'rows*') {
          for (var prop in field.param) {
            var param = field.param[prop];
            this.setParamInFormOutput(values, prop, param, iterations);
          }
        } else {
          if (Array.isArray(repeaterRow)) {
            const repeaterRowIndex = Array.isArray(iterations) ? repeaterRow.length - iterations.length : 0
            if (Array.isArray(field.rows)) {
              for (let prop in field.rows[repeaterRow[repeaterRowIndex]].param) {
                if (values[0]) {
                  let param = field.rows[repeaterRow[repeaterRowIndex]].param[prop]
                  this.setParamInFormOutput(values, prop, param, [0])
                }
              }
            }
            values['@submittedRowIndex'] = repeaterRow[repeaterRowIndex] + 1
          } else {
            if (Array.isArray(field.rows)) {
              for (var r = 0; r < field.rows.length; r++) {
                for (let prop in field.rows[r].param) {
                  let param = field.rows[r].param[prop]
                  this.setParamInFormOutput(values, prop, param, [r])
                }
              }  
            }
          }
        }
        if (field.scriptedWidget && field.scriptedWidgetObject) {
          if (MC.isFunction(field.scriptedWidgetObject.getValue)) {
            var value = field.scriptedWidgetObject.getValue();
            for (prop in value) {
              values[prop] = value[prop];
            }
          }
        }
      }
      if (field == triggeredByField) {
        values['@submittedBy'] = true;
      }
      if (!MC.isNull(values)) {
        if (definition.id !== 'rows*') {
          contextTree[field.id == 'rows*' ? 'rows' : field.id] = values;
        } else {
          if (Array.isArray(iterations)) {
            var index = iterations[iterations.length - 1];
            if (Array.isArray(repeaterRow)) {
              index = 0;
            }
            if (!contextTree[index]) {
              contextTree[index] = {};
            }
            contextTree[index][field.id] = values;
          }
        }
      }
    }
  };

  this.getFormFieldPath = function(definition, triggeredByField, path) {
    if (!definition.fields) {
      return null
    }
    for (let i = 0; i < definition.fields.length; i++) {
      let field = definition.fields[i]
      let subpath = path + (path === '' ? '' : '/') + field.id
      if (triggeredByField && field.rbsid == triggeredByField.rbsid) {
        return subpath
      } else {
        let subres = this.getFormFieldPath(field, triggeredByField, subpath)
        if (subres !== null) {
          return subres
        }
      }
    }
    return null
  }

  this.setFormFields = function(definition, valueObj) {
    if (valueObj == null || valueObj == undefined) {
      return
    }
    if (Array.isArray(valueObj) && definition.id === 'rows*') {
      if (!Array.isArray(definition.rows)) {
        definition.rows = []
      }
      if (definition.rows.length > valueObj.length) {
        definition.rows = definition.rows.slice(0, valueObj.length)
      }
      for (let i=0;  i<valueObj.length; i++) {
        if (!definition.rows[i]) {
          let iToPass = definition.param['@iteration'] != undefined ? [...definition.param['@iteration'], i] : [i]
          definition.rows[i] = MC.copyFormField({id: definition.id, fields: definition.fields, param: definition.param, flow: definition.flow}, iToPass)
          FieldDef.setProto(definition.rows[i])
        }
        this.setFormFields(definition.rows[i], valueObj[i])
      }
    } else {
      if (valueObj == '' && definition.id === 'rows*' && definition.rows) { // deleting collection by empty
        definition.rows = []
        return
      } 
      for (let target in valueObj) {
        let value = valueObj[target]
        if (target == 'data') {
          definition.data = MC.extendFormField(true, false, definition.data, value)
          continue
        } else if (definition.widget == 'dynamicPanel' && target == 'fields') {
          definition.fields = []
          for (let f of value) {
            definition.fields.push(MC.extend(true, {}, f))
          }
          MC.setFieldsPropertyRecusively(definition, 'flow', definition.flow)
          MC.setFieldsPropertyRecusively(definition, 'onSubmit', definition.onSubmit)
          FieldDef.setProto(definition)
          MC.ensureIterations(definition, [])
          continue
        }
        let subField = null
        for (let i=0; i<definition.fields.length; i++) {
          if (definition.fields[i].id == target || definition.fields[i].id == (target + '*')) {
            subField = definition.fields[i]
            break
          }
        }
        if (subField) {
          this.setFormFields(subField, value)
        } else {
          if (definition.scriptedWidget) {
            definition.param[target] = value
          } else {
            if (MC.isPlainObject(value)) {
              definition.param[target] = MC.extendFormField(true, definition.param[target], value)
            } else if (!MC.isNull(value)) {
              if (value === '' && Array.isArray(definition.param[target])) {
                delete definition.param[target]
              } else {
                definition.param[target] = value
              }
            }
          }
        }
      }
    }
  }

  this.convertMockTreeData = function(outputTree, mockTree, contextTree, actionCode) {
    for (var i=0; i<outputTree.output.length; i++) {
      var param = outputTree.output[i];
      var key = param.name;
      if (key.endsWith('*')) {
        key = key.substring(0, key.length-1);
      }
      if (!MC.isNull(mockTree[key])) {
        var values;
        var mockValues = mockTree[key];
        if (Array.isArray(mockValues)) {
          var values = [];
          for (var m=0; m<mockValues.length; m++) {
            var value;
            if (param.output) {
              value = {};
              this.convertMockTreeData(param, mockValues[m], value, false);
            } else {
              if (mockValues[m].content) {
                value = MC.normalizeValue(mockValues[m].content, param.basictype);
              } else {
                value = MC.normalizeValue(mockValues[m], param.basictype);
              }
            }
            values.push(value);
          }
        } else {
          if (param.output) {
            values = {};
            this.convertMockTreeData(param, mockValues, values, false);
          } else {
            if (mockValues.content) {
              values =  MC.normalizeValue(mockValues.content, param.basictype);
            } else {
              values =  MC.normalizeValue(mockValues, param.basictype);
            }
          }
        }
        if (actionCode) {
          if (!contextTree[actionCode]) {
            contextTree[actionCode] = {}
          }
          contextTree[actionCode][key] = values
        } else {
          contextTree[key] = values
        }
      } else {
        if (param.mandat) {
          this.endOperationException('SYS_MappingExc', "Output parameter '" + param.name + "' must have value!");
        }
      }
    }
  };

  this.callLogicAction = function(logic, triggeredByField, dialog) {
    let action = null
    let altMapping = null
    let altMappingBack = null
    if (logic.dataAction) {
      action = this.getActionByCode(logic.dataAction)
    } else if (logic.actionLink) {
      if (Array.isArray(this.context.action.actionLink)) {
        action = this.context.action.actionLink.find(el => el.name == logic.actionLink)
        if (action && action.nextaction) {
          altMapping = action.altmapping
          altMappingBack = action.altmappingback
          action = this.getActionById(action.nextaction)
        }
      }
    }
    if (!action) {
      this.endOperationException('SYS_InvalidModelExc', "Action or event with code '" + (logic.dataAction || logic.actionLink) + "' not found!")
      return
    }
    if (action.kind != 'dialog' && action.kind != 'call') {
      this.endOperationException('SYS_InvalidModelExc', "Action '" + action.code + "' is not dialog or call action!")
      return
    }
    var formOutput = {}
    let repeaterRows = triggeredByField ? MC.getFieldParamValue(triggeredByField.param, '@iteration') : null
    this.mapFormOutput(this.reactFlow().state.formData, formOutput, triggeredByField, true, 'no', repeaterRows, logic.name ? logic.name : null)
    this.addToContext(this.context.data, this.context.action.code, formOutput)
    if (action.kind == 'dialog' || dialog) {
      if ('force' == dialog && action.kind != 'dialog') {
        this.endOperationException('SYS_InvalidModelExc', "Action '" + action.code + "' must be dialog action if you want show it in dialog!")
        return
      }
      let flowName = action.calls
      if (!flowName) {
        this.endOperationException('SYS_InvalidModelExc', "Action '" + (logic.dataAction || logic.actionLink) + "' calls no operation!")
        return
      }
      let [input, trace] = this.mapToResultObject(this.selectMapping(action, altMapping), this.context)
      if (!input) {
        this.endOperationException('SYS_MappingExc', trace[0], null, null, null, trace[1])
        return
      }
      return {flowName: flowName, input: input, size: action.dialogSize ? action.dialogSize : null, trace: trace, altMapping: altMappingBack, actionCode: action.code, action: action}
    } else {
      return this.callAction({action: action, altMappingBack: altMappingBack, altMapping: altMapping}, logic)
    }
  };

  this.callDialog = function(field) { 
    let lStart = performance.now()
    let lStartDate = Date.now()
    let dRes = this.callLogicAction({dataAction: MC.getFieldParamValue(field.param, '@dialogAction'), actionLink: MC.getFieldParamValue(field.param, '@dialogEvent')}, field, 'force')
    if (!MC.isNull(dRes)) {
      if (!MC.isNull(MC.getFieldParamValue(field.param, '@dialogAction'))) {
        MCHistory.log(MCHistory.T_WARNING, 'Using "@dialogAction" is deprecated. Use "@dialogEvent" with corresponding form event instead.', field.flow.debug())
      }
      this.reactFlow().setState({dialog: {flowName: dRes.flowName, input: dRes.input, trace: dRes.trace, actionCode: dRes.actionCode, triggeredByField: field, size: dRes.size, 
        start: true, parentFlow: this, altMapping: dRes.altMapping}})
      MCHistory.history(this, this.getActionByCode(dRes.actionCode), 'DIALOG CALL', {'Input': dRes.input, 'Trace': dRes.trace, target: {...dRes.action.interface, ...{id: dRes.flowName}}}, {start: lStartDate, end: Date.now(), duration: performance.now() - lStart})  
    }
  }  

  this.callAction = function(options, logic) {
    var self = this
    var action = this.context.action
    if (options) {
      action = options.action
      if (MC.isNull(action)) {
        this.endOperationException('SYS_InvalidModelExc', "Lazy data action with code '" + options.code + "' not found!")
        return
      }
    }
    if (!action.calls) {
      this.endOperationException('SYS_InvalidModelExc', "Call action '" + action.code + "' calls no operation, not supported!")
      return
    }
    let input = null
    let trace = null
    if (action.multiCall && this.multiInput && this.multiInput.length > 0) {
      input = this.multiInput.shift()
    } else {
      [input, trace] = this.mapToResultObject(this.selectMapping(action, options && typeof options != 'string' ? options.altMapping : null), this.context)
    }
    if (input) {
      if (action.leaveFlow) {
        this.leaveOperation(action.calls, input, trace)
      } else {
        if (action.multiCall) {
          if (!this.multiInput) {
            if (input['input'] && Array.isArray(input['input']) && input['input'].length > 0) {
              this.multiInputOrig = input
              this.multiInput = MC.extend(true, [], input['input'])
              this.multiTrace = trace
              input = this.multiInput.shift()
            } else {
              this.addToContext(this.context.data, action.code, null)
              this.progress({'Input': input, 'Trace': trace})
              return
            }
          }
        }
        const flow = new Flow(null)
        flow.setLang(self.lang).setConfPath(this.confPath).setParentFlow(self)
        if (action.interface.isFrontend) {
          flow.setFlowId(null)
          flow.setFlowConfiguration(this.confPath, action.calls)
        } else {
          flow.setFlowConfigurationProps(this.context.data.env.cfg, this.confPath, action.calls, this.confNsMap)
          flow.setServerSide(action.interface)
        }
        if (options) {
          flow.setLazyAction(action, logic, typeof options != 'string' ? options.altMappingBack : null)
        }
        if (MC.isFunction(self.afterRenderForm)) {
          flow.setAfterRenderFormFunction(self.afterRenderForm)
        }
        flow.setEnv(this.env)
        flow.setWantedLogLevel(this.wantedLogLevel)
        flow.loadAndStart(input, trace)
      }
    } else {
      this.endOperationException('SYS_MappingExc', trace[0], null, null, null, trace[1])
      return undefined
    }
  }

  this.runOnServer = function() {
    var input = {};
    if (this.flow.input && Array.isArray(this.flow.input)) {
      if (this.convertInputTreeData(this.flow, this.input, input)) {
        MC.replaceValues(input, "", null)
      } else {
        return;
      }
    }
    var url = ReactFlow.flowServerUrl + this.confPath;
    if (this.flowId) {
      url += '?id=' + this.flowId;
    } else {
      url += '?name=' + this.flowName;
    }
    if (this.debug('BASIC')) {
      url += '&includeid=true&loggingthreshold=' + this.logLevel
    }
    let method = 'POST';
    let content = JSON.stringify(input);
    if (this.cache) {
      method = 'GET'
      url += '&inputdata=' + encodeURIComponent(JSON.stringify(input))
      content = null;
    }
    MC.callServer(method, url, MC.getJsonType(), content, MC.getJsonType()).then(function (res) {
      try {
        var output = {};
        var content = res.content;
        var log = null;
        if (content) {
          try {
            output = JSON.parse(content);
          } catch (e) {
            self.endOperationException('SYS_IntegrationExc', 'Invalid server response: ' + content, input, null, null, self.inputMapTrace)
            return
          }
          if (!MC.isNull(output['fl:flowId']) && !MC.isNull(output['fl:flowLogId'])) {
            log = {flowId: output['fl:flowId'], flowLogId: output['fl:flowLogId']}
            delete output['fl:flowId']
            delete output['fl:flowLogId']
          }
        }
        if (res.status == 200 || res.status == 204) {
          MC.replaceValues(output, null, "")
          self.endOperation(output, log)
        } else {
          var type = 'SYS_IntegrationExc';
          if (output.errorName) {
            type = output.errorName;
          }
          var message = 'Calling server flow failed for url ' + url  + '! Status:' + res.status;
          if (output.errorMessage) {
            message = output.errorMessage;
          }
          self.endOperationException(type, message, input, output, log, self.inputMapTrace)
          return
        }
      } catch (e) {
        self.endOperationException('SYS_IntegrationExc', e.message, input, null, null, self.inputMapTrace)
        return
      }
    }).catch(function (err) {
      if (navigator.onLine) {
        self.endOperationException('SYS_IntegrationExc', 'Calling server flow failed for url ' + url + ': ' + err.message, input, null, null, self.inputMapTrace)
        return
      } else {
        self.endOperationException('SYS_SystemUnavailableExc', 'Internet connection is not available for url ' + url + ': ' + err.message, input, null, null, self.inputMapTrace)
        return
      }
    });
  };

  this.convertOutputTreeData = function(outputTree, valueTree, contextTree) {
    if (Array.isArray(valueTree) && valueTree.length > 0) {
      valueTree = valueTree[0]
    }
    for (let i=0; i<outputTree.output.length; i++) {
      let param = outputTree.output[i]
      let key = param.name
      if (MC.isNull(key)) {
        if (param.basictype == 'anyType' && !MC.isNull(valueTree)) {
          MC.extend(true, contextTree, valueTree)
        }
      } else {
        if (key.endsWith('*')) {
          key = key.substring(0, key.length-1)
        }
        if (valueTree && !MC.isNull(valueTree[key])) {
          try {
            let values
            let inValues = valueTree[key]
            if (param.name.endsWith('*')) {
              if (!Array.isArray(inValues)) {
                let arr = []
                arr.push(inValues)
                inValues = arr
              }
              values = []
              for (let m=0; m<inValues.length; m++) {
                if (typeof inValues[m] === 'undefined') continue
                let value;
                if (param.output && inValues[m] !== '') {
                  value = {};
                  if (!this.convertOutputTreeData(param, inValues[m], value)) {
                    return false
                  }
                } else {
                  value = MC.normalizeValue(inValues[m], param.basictype)
                }
                if (!MC.isNull(value)) {
                  values.push(value)
                }
              }
            } else {
              if (Array.isArray(inValues)) {
                inValues = MC.getFirstNotNull(inValues)
              }
              if (param.output && inValues !== '') {
                values = {}
                if (!this.convertOutputTreeData(param, inValues, values)) {
                  return false
                }
              } else {
                values = MC.normalizeValue(inValues, param.basictype)
              }
            }
            if (!MC.isNull(values)) {
              contextTree[key] = values
            }
          } catch (e) {
            e.message = 'Error building operation output: ' + e.message
            this.endOperationException('SYS_MappingExc', e)
            return false
          }
        } else {
          if (param.mandat) {
            this.endOperationException('SYS_MappingExc', "Output parameter '" + param.name + "' must have value!")
            return false
          }
        }
      }
    }
    return true
  }

  this.decisionAction = function() {
    try {
      var action = this.context.action;
      if (!action.branch) {
        this.endOperationException('SYS_InvalidModelExc', 'Decision action "' + action.code + '" must have at least one branch defined!');
        return;
      }
      var testedBranches = {};
      for (var i = 0; i < action.branch.length; i++) {
        var branch = action.branch[i];
        var passed = true;
        if (branch.expr && Array.isArray(branch.expr)) {
          for (var e = 0; e < branch.expr.length; e++) {
            var expression = new Expression(branch.expr[e], this.context.data);
            var res = expression.evaluate();
            if (expression.getError()) {
              MC.error(expression.getErrorMessage());
            }
            res = MC.castToScalar(res, 'boolean')
            if (res === undefined) { // $variable assign is ignored
              res = true
            }
            if (!res) {
              passed = false;
              break;
            }
          }
          if (this.debug('TRACE')) {
            testedBranches[branch.name] = {result: res, trace: expression.getTrace()};
          }
        }
        if (passed) {
          if (!branch.nextaction) {
            this.endOperationException('SYS_InvalidModelExc', 'Branch "' + branch.name + '" of decision action "' + action.code + '" must have next action defined!');
            return;
          }
          this.context.nextAction = this.getActionById(branch.nextaction)
          this.context.nextAltmapping = branch.altmapping
          var result = {};
          result['branch'] = branch.name;
          result['action'] = this.context.nextAction.code;
          this.progress({'Result': result, 'Tested branches': testedBranches});
          return;
        }
      }
      this.endOperationException('SYS_MappingExc', 'No branch of decision action "' + action.code + '" passed!');
      return;
    } catch (ex) {
      self.endOperationException('SYS_MappingExc', ex.message);
    }
  };

  this.endAction = function() {
    if (this.context.action.throwsException) {
      MCHistory.history(this, this.context.action, null, null, {start: this.context.actionStartDate, end: Date.now(), duration: performance.now() - this.context.actionStart})
      this.endOperationException(this.context.action.throwsException.name, "End action " + this.context.action.code + " with exception.");
    } else {
      let [output, trace] = this.mapToResultObject(this.selectMapping(this.context.action), this.context);
      if (output) {
        MCHistory.history(this, this.context.action, null, {'Output': output, 'Trace' : trace}, {start: this.context.actionStartDate, end: Date.now(), duration: performance.now() - this.context.actionStart})
        this.endOperation(output, null, this.context.action.redirect);
      } else {
        this.endOperationException('SYS_MappingExc', trace[0], null, null, null, trace[1])
        return
      }
    }
  };

  this.selectMapping = function(action, altMappingName) {
    if (!altMappingName) {
      altMappingName = this.context.altmapping
    }
    if (altMappingName) {
      if (Array.isArray(action.altMapping)) {
        for (let mapping of action.altMapping) {
          if (mapping.name == altMappingName) {
            return {name: altMappingName, mapping: mapping.mapping}
          }
        }
      }
      return {exception: 'Alternative mapping with name ' + altMappingName + ' not found in acion "' + action.code + '"!'}
    } else {
      return {mapping: action.mapping}
    }
  }

  this.preparePathToRedirect = function(output) {
    let path = output.path
    if (!MC.isNull(path)) {
      if (path.startsWith('/')) {
        path = path.substring(1)
      }
      let sep = path.indexOf('?') > -1 ? '&' : '?'
      if (output.parameters && MC.isPlainObject(output.parameters)) { 
        for (let key in output.parameters) {
          if (output.parameters.hasOwnProperty(key)) {
            path += sep + key + '=' + encodeURIComponent(output.parameters[key])
          }
          sep = '&'
        }
      }
      return path
    } else {
      this.endOperationException('SYS_MappingExc', 'Output of redirect operation not contains path!', null, output, null, null)
    }
  }

  this.endOperation = function(unorderedOutput, log, redirect) {
    var output = {};
    if (redirect) {
      output = unorderedOutput;
    } else if (unorderedOutput && this.flow.output) {
      if (!this.convertOutputTreeData(this.flow, unorderedOutput, output)) {
        return false
      }
    }
    if (redirect && this.reactFlow()) {
      const npath = this.preparePathToRedirect(output)
      this.reactFlow().routeTo(null, npath)
    } else if (!this.parentFlow) {
      if (!this.flow.action || this.serverSide) {
        let trace = this.flow.kind == 'decisiontable' ? log : null
        log = this.flow.kind == 'decisiontable' ? null : log
        MCHistory.history(this, null, 'OPERATION END', {'Output': output, 'Server log': log, 'Trace': trace}, {start: this.opStartDate, end: Date.now(), duration: performance.now() - this.opStart});
      }
      if (MC.isFunction(this.onEndFunction)) {
        if (this.reactFlow() && this.reactFlow().props.clearStateOnEnd) {
          this.reactFlow().setState({state: null, dimmer: false, runReady: false});
        }
        this.onEndFunction(output, undefined);
      } else {
        this.showOutput(output);
      }
    } else {
      if (!MC.isNull(this.lazyAction)) {
        this.parentFlow.addToContext(this.parentFlow.context.data, this.lazyAction.code, output)
        const formData = this.parentFlow.formData ? this.parentFlow.formData : this.parentFlow.reactFlow().state.formData
        this.parentFlow.progressLazyForm(formData, {'Input': this.input, 'Output': output, 'Server log': log, 'Trace': this.inputMapTrace, target: this.flow}, this.lazyAction, this.lazyActionLogic, this.lazyActionAltMapping)
      } else {
        if (['framework', 'function', 'decisiontable'].indexOf(this.flow.kind) > -1) {
          let trace = this.flow.kind == 'decisiontable' ? log : this.inputMapTrace
          MCHistory.history(this, null, 'OPERATION', {'Input': this.input, 'Output': output, 'Trace': trace}, {start: this.opStartDate, end: Date.now(), duration: performance.now() - this.opStart})
        }
        let action = this.parentFlow.context.action;
        if (!action && ['FWK_OperationExecute_FE', 'FWK_OperationWithConfigExecute_FE'].indexOf(this.parentFlow.flow.id) > -1) {
          if (MC.isFunction(this.parentFlow.onEndFunction)) {
            if (this.parentFlow.reactFlow() && this.parentFlow.reactFlow().props.clearStateOnEnd) {
              this.parentFlow.reactFlow().setState({state: null, dimmer: false, runReady: false})
            }
            this.parentFlow.onEndFunction(output, undefined)
            return
          } else {
            this.parentFlow = this.parentFlow.parentFlow
            action = this.parentFlow.context.action
            output = {output: output, executionId: this.instanceId}
          }
        }
        if (action.multiCall) {
          if (!this.parentFlow.multiOutput) {
            this.parentFlow.multiOutput = [];
          }
          this.parentFlow.multiOutput.push(output);
          if (this.parentFlow.multiInput.length > 0) {
            this.parentFlow.callAction();
            return;
          } else {
            output = {'output': this.parentFlow.multiOutput};
            this.parentFlow.multiInput = null;
            this.parentFlow.multiOutput = null;
            this.inputMapTrace = this.parentFlow.multiTrace; 
            this.parentFlow.multiTrace = null;
            this.input = this.parentFlow.multiInputOrig;
            this.parentFlow.multiInputOrig = null;
          }
        }
        this.parentFlow.addToContext(this.parentFlow.context.data, action.code, output)
        log = this.flow.kind == 'decisiontable' ? null : log
        this.parentFlow.progress({'Input': this.input, 'Output': output, 'Server log': log, 'Trace': this.inputMapTrace, executionId: this.instanceId, target: this.flow});
      }
    }
  };

  this.endOperationException = function(type, message, input, output, log, trace) {
    this.clearLogicTimers();
    if (!MC.isPlainObject(type)) {
      type = this.buildExceptionTypeObject(type);
    }
    if (this.flow) {
      if (this.context.action) {
        message = " operation " + this.flow.id + " / action " + this.context.action.code + " / " + message;
      } else {
        message = " operation " + this.flow.id + " / " + message;
      }
    }
    var exception = {};
    exception.errorName = type.name;
    exception.errorMessage = message;
    exception.errorCode = type.name;
    if (!MC.isNull(output)) {
      exception = output;
    }
    if (!this.context.data.env) {
      this.context.data.env = {}
    }
    this.context.data.env.exception = exception;
    let relevantException = false;
    if (this.context.action && this.context.action.exception) {
      relevantException = this.getRelevantException(type, this.context.action.exception);
    }
    if (relevantException) {
      MCHistory.log(MCHistory.T_EXCEPTION, type.name + ': ' + message, this.debug('BASIC'));
      this.context.nextAction = this.getActionById(relevantException.nextaction);
      this.context.nextAltmapping = relevantException.altmapping
      this.progress({'Input': input, 'Output': exception, 'Trace': trace, 'Server log': log, isException: true})
    } else {
      var startAction = null;
      if (this.flow && this.flow.action) {
        startAction = this.getStartAction();
      }
      let isThrowedFromEnd = false;
      if (this.context.action && this.context.action.kind === 'end' && this.context.action.throwsException && this.context.action.throwsException.name === type.name) {
        isThrowedFromEnd = true;
      }
      relevantException = false;
      if (startAction && startAction.exception) {
        relevantException = this.getRelevantException(type, startAction.exception);
      }
      if (!isThrowedFromEnd && relevantException) {
        MCHistory.log(MCHistory.T_EXCEPTION, type.name + ': ' + message, this.debug('BASIC'));
        this.context.nextAction = this.getActionById(relevantException.nextaction);
        this.context.nextAltmapping = relevantException.altmapping
        this.progress({'Input': input, 'Output': exception, 'Trace': trace, 'Server log': log, isException: true});
      } else {
        if (!this.parentFlow) {
          MCHistory.history(this, this.context.action, 'EXCEPTION END', {'Input': input, 'Output': exception, 'Server log': log, 'Trace': trace}, {start: this.opStartDate, end: Date.now(), duration: performance.now() - this.opStart});
          if (MC.isFunction(this.onEndFunction)) {
            if (this.reactFlow().props.clearStateOnEnd) {
              this.reactFlow().setState({state: null, dimmer: false});
            }
            this.onEndFunction(type.name, message);
          } else if (this.reactFlow()) {
            this.reactFlow().setState({state: 'exception', exception: {type: type.name, message: message}, dimmer: false, runReady: false});
          }
        } else {
          if (this.flow && this.flow.action && !this.flow.mock) {
            MCHistory.history(this, this.context.action, 'EXCEPTION END', {'Input': input, 'Output': exception, 'Server log': log, 'Trace': trace}, {start: this.opStartDate, end: Date.now(), duration: performance.now() - this.opStart});
          }
          this.parentFlow.endOperationException(type, message, input, exception, log, trace);
        }
      }
    }
  };

  this.buildExceptionTypeObject = function(type) {
    var exception = {name: type};
    exception.parent = [];
    if (this.flow && this.flow.exception && this.flow.exception[type]) {
      var curr = this.flow.exception[type];
      while (!MC.isNull(curr.parent)) {
        exception.parent.push(curr.parent);
        curr = this.flow.exception[curr.parent];
      }
    }
    return exception;
  };

  this.getRelevantException = function(thrown, caughtArr) {
    if (Array.isArray(caughtArr) && caughtArr.length > 0) {
      for (let caught of caughtArr) {
        if (thrown.name == caught.name || Array.isArray(thrown.parent) && thrown.parent.indexOf(caught.name) > -1) {
          return caught;
        }
      }
    }
    return false;
  };

  this.leaveOperation = function(calls, input, trace) {
    MCHistory.history(this, this.context.action, 'LEAVE FLOW', {'Output': input, 'Trace': trace}, {start: this.opStartDate, end: Date.now(), duration: performance.now() - this.opStart})
    if (!this.parentFlow) {
      this.setFlowId(null)
      this.setFlowConfiguration(this.confPath, calls)
      let cfg = this.context.data.env.cfg
      this.context = {data: {env: {}}}
      this.env = {}
      this.context.data.env.cfg = cfg //TODO: configuration should be deleted too, now throws js error
      this.flow = null
      this.loadAndStart(input)
    } else {
      this.parentFlow.leaveOperation(calls, input, trace)
    }
  }

  this.transformAction = function() {
    var action = this.context.action;
    let [input, trace] = this.mapToResultObject(this.selectMapping(action), self.context)
    if (input) {
      var output = {};
      if (!this.convertOutputTreeData(action, input, output)) {
        return false
      }
      this.addToContext(this.context.data, action.code, output);
      this.progress({'Input': input, 'Output': output, 'Trace' : trace});
    } else {
      this.endOperationException('SYS_MappingExc', trace[0], null, null, null, trace[1])
      return
    }
  };

  this.feedbackAction = function() {
    var action = this.context.action;
    var feedback = MC.extend(true, {}, action.feedback);
    if (MC.isNull(feedback)) {
      this.endOperationException('SYS_InvalidModelExc', "Feedback action '" + action.code + "' has no feedback assigned!");
      return;
    }
    let result = {};
    let trace;
    if (!MC.isNull(action.mapping)) {
      [result, trace] = this.mapToResultObject(this.selectMapping(action), self.context)
      if (!result) {
        this.endOperationException('SYS_MappingExc', trace[0], null, null, null, trace[1])
        return
      }
      var nresult = {};
      for (var prop in result) {
        nresult['@' + prop] = result[prop];
      }
      feedback.param = {value: nresult};
      feedback.title = MC.formatValue('', 'message', null, feedback.title, feedback)
      feedback.help = MC.formatValue('', 'message', null, feedback.help, feedback)
    }
    if (!Array.isArray(this.context.feedback)) {
      this.context.feedback = [];
    }
    this.context.feedback.push(feedback);
    this.progress({'Input': result, 'Trace' : trace});
  };

  this.showOutput = function(output) {
    if (this.debug()) {
      this.reactFlow().setState({state: 'output', output: output, dimmer: false, runReady: false})
    } else {
      this.reactFlow().setState({state: '', dimmer: false, runReady: false})
    }
  }

  this.makeObject = function(key, valueObj, checkNullInColl) {
    let newValue = null
    let newKey = null
    if (key.indexOf('/') > 0) {
      newKey = key.substring(0, key.indexOf('/'))
      if (newKey.endsWith("*") && Array.isArray(valueObj)) {
        newValue = []
        for (let i=0; i<valueObj.length; i++) {
          let res = this.makeObject(key.substring(key.indexOf('/')+1), valueObj[i], true)
          newValue.push(res)
        }
      } else {
        let res = this.makeObject(key.substring(key.indexOf('/')+1), valueObj, checkNullInColl);
        if (newKey.endsWith('*')) {
          newValue = []
          newValue.push(res)
        } else {
          newValue = res
        }
      }
    } else {
      newKey = key
      if (key.endsWith('*') && !Array.isArray(valueObj) && valueObj !== '') {
        newValue = [valueObj];
      } else {
        newValue = valueObj;
      }
      if (MC.isNull(valueObj) && checkNullInColl) {
        this.wasNullInBuiltObject = true
      }
    }
    let object = {}
    if (newKey.endsWith('*')) {
      newKey = newKey.substring(0, newKey.length-1)
    }
    object[newKey] = newValue
    return object
  }

  this.makeObjectRecursive = function(valueObj) {
    if (MC.isPlainObject(valueObj)) { 
      var object = {}
      for (var key in valueObj) {
        var value = valueObj[key]
        var newObject = this.makeObject(key, value, false);
        if (!MC.isNull(newObject)) {
          MC.extend(true, false, object, newObject)
        }
      }
      if (this.wasNullInBuiltObject) {
        object = MC.fixSparseColls(object)
        this.wasNullInBuiltObject = false
      }
      if (MC.isEmptyObject(object)) {
        return null
      } else {
        return object
      }
    } else {
      return valueObj
    }
  }

  this.mapToResultObject = function(mappingObj, context) {
    if (mappingObj.exception) {
      return [false, [mappingObj.exception, null]]
    }
    let result = {}
    let traceObj = {}
    if (mappingObj.name && this.debug('TRACE')) {
      traceObj['Used alternative mapping'] = mappingObj.name
    }
    if (mappingObj.mapping && Array.isArray(mappingObj.mapping)) {
      let opts = {function: this.flow.function}
      for (let mapping of mappingObj.mapping) {
        if (MC.isEmptyObject(mapping)) {
          continue
        }
        try {          
          let expression = new Expression(mapping, context.data, opts)
          let valueObj = expression.evaluate()
          if (this.debug('TRACE')) {
            const newTrace = expression.getTraceAsPaths()
            for (const path in newTrace) {
              if (traceObj[path]) {
                if (!Array.isArray(traceObj[path])) {
                  traceObj[path] = [traceObj[path]];
                }
                traceObj[path].push(newTrace[path])
              } else {
                traceObj[path] = newTrace[path]
              }
            }
          }
          if (expression.getError()) {
            MC.error(expression.getErrorMessage())
          }
          if (!MC.isNull(valueObj)) {
            valueObj = this.makeObjectRecursive(valueObj)
            if (!MC.isNull(valueObj)) {
              if (Array.isArray(valueObj) && MC.isEmptyObject(result)) { // case where array is mapped into root
                result = valueObj
              } else {
                for (let key in valueObj) {
                  result[key] = valueObj[key]
                }
              }
            }
          }
        } catch (ex) {
          return [false, [ex.message, traceObj]]
        }
      }
    }
    return [result, traceObj]
  }

  this.runMock = function() {
    try {
      var operation = this.flow;
      if (operation.output) {
        var output = {};
        if (Array.isArray(operation.mock) && operation.mock.length > 0) {
          // mapping into input for evaluating conditions
          for (var i=0; i<operation.mock.length; i++) {
            var passed = true;
            if (operation.mock[i].expr) {
              var expression = new Expression(operation.mock[i].expr[0], {input: this.input});
              if (!expression.evaluate()) {
                passed = false;
              }
              if (expression.getError()) {
                MC.error(expression.getErrorMessage());
              }
            }
            if (passed) {
              this.convertMockTreeData(operation, operation.mock[i].data, output);
              return output;
            }
          }
        }
      }
    } catch (ex) {
      self.endOperationException('SYS_MappingExc', ex.message);
    }
    return false;
  };

  this.runFunctionOperation = function() {
    var type = this.flow.functiontype;
    if (type == 'javascript') {
      if (!this.flow.functioncode) {
        this.endOperationException('SYS_InvalidModelExc', 'Code of function operation ' + this.flow.id + ' can not be empty!');
      }
      var input = this.input;
      var output = {};
      eval(this.flow.functioncode);
      this.endOperation(output);
    } else {
      this.endOperationException('SYS_InvalidModelExc', 'Unsupported function type: ' + type);
    }
  };

  this.runFrameworkOperation = function() {
    var self = this;
    if (this.flow.id == 'BRWS_ExternalUrlWindowOpen') {
      var input = this.input;
      var url = input.url;
      if (MC.isNull(url) || url === '') {
        this.endOperationException('SYS_InvalidModelExc', '"url" input parameter of BRWS_ExternalUrlWindowOpen is required!');
        return;
      }
      if (!MC.isNull(input.params) && MC.isPlainObject(input.params)) {
        for (var param in input.params) {
          url += (url.indexOf('?') < 0 ? '?' : '&') + param + '=' + input.params[param];
        }
      }
      var target = input.target;
      if (target == 'blank') {
        window.open(url);
        this.endOperation(null);
      } else if (target == 'top') {
        window.top.location.href = url;
      } else if (target == 'parent') {
        window.parent.location.href = url;
      } else {
        this.reactFlow().routeTo(null, url)
      }
    } else if (this.flow.id == 'FWK_EventSend') {
      var urlarr = window.location.href.split("/");
      if (this.input.parent === true && parent) {
        parent.postMessage(this.input, urlarr[0] + "//" + urlarr[2]);
      } else {
        window.postMessage(this.input, urlarr[0] + "//" + urlarr[2]);
      }
      this.endOperation(null);
    } else if (this.flow.id == 'FWK_EnvironmentRefresh') {
      if (this.context.data.env.cfg['fl:environmentOperation']) {
        MC.getEnvironmentContext(this.confPath, this.context.data.env.cfg['fl:environmentOperation']).then(function(context) {
          self.updateEnvContext(context);
          self.endOperation(null);
        });
      } else {
        self.updateEnvContext(null);
        self.endOperation(null);
      }
    } else if (['BRWS_LocalDataStore', 'BRWS_LocalDataGet', 'BRWS_LocalDataList', 'BRWS_LocalDataDelete'].indexOf(this.flow.id) > -1) {
      let res;
      switch (this.flow.id) {
        case 'BRWS_LocalDataStore': res = MCBrws.store(this.input.path, this.input.data); break;
        case 'BRWS_LocalDataGet': res = MCBrws.get(this.input.path); break;
        case 'BRWS_LocalDataList': res = MCBrws.list(this.input.path); break;
        case 'BRWS_LocalDataDelete': res = MCBrws.delete(this.input.path); break;
      }
      if (res.error) {
        this.endOperationException('SYS_InvalidModelExc', res.error);
      } else {
        this.endOperation(res.result);
      }
    } else if (this.flow.id == 'BRWS_ResizeImage') {
      let input = this.input;
      if (MC.isNull(input.data) || input.data === "") {
        this.endOperationException('SYS_InvalidModelExc', '"data" input parameter of BRWS_ResizeImage can not be null or empty!');
        return;
      }
      if (MC.isNull(input.contentType) || input.contentType === "") {
        this.endOperationException('SYS_InvalidModelExc', '"contentType" input parameter of BRWS_ResizeImage can not be null or empty!');
        return;
      }
      let quality = 0.75;
      if (input.quality && MC.isNumeric(input.quality) && input.quality >= 0 && input.quality <= 1) {
        quality = parseFloat(input.quality);
      }
      let maxWidth = 800;
      if (input.maxWidth && MC.isNumeric(input.maxWidth) && input.maxWidth > 0) {
        maxWidth = parseInt(input.maxWidth);
      }
      let maxHeight = 600;
      if (input.maxHeight && MC.isNumeric(input.maxHeight) && input.maxHeight > 0) {
        maxHeight = parseInt(input.maxHeight);
      }
      let image = new Image();
      image.onload = function() {
        var width = image.width;
        var height = image.height;
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }
        let canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, width, height);
        let output = {contentType: "image/jpeg"};
        let data = canvas.toDataURL('image/jpeg', quality);
        output.data = data.substring(data.indexOf(';base64,')+8);
        self.endOperation(output);
      };
      image.src = 'data:' + input.contentType + ';base64,' + input.data;
    } else if (this.flow.id == 'BRWS_ImageAdaptiveThreshold') {
      let input = this.input
      if (MC.isNull(input.data) || input.data === "") {
        this.endOperationException('SYS_InvalidModelExc', '"data" input parameter of BRWS_ImageAdaptiveThreshold can not be null or empty!')
        return
      }
      if (MC.isNull(input.contentType) || input.contentType === "") {
        this.endOperationException('SYS_InvalidModelExc', '"contentType" input parameter of BRWS_ImageAdaptiveThreshold can not be null or empty!')
        return
      }
      let ratio = 0.6
      if (input.ratio && MC.isNumeric(input.ratio) && input.ratio >= 0 && input.ratio <= 1) {
        ratio = parseFloat(input.ratio)
      }
      let image = new Image()
      image.onload = function() {
        let width = image.width
        let height = image.height
        let sourceCanvas = document.createElement('canvas')
        sourceCanvas.width = width
        sourceCanvas.height = height
        let ctx = sourceCanvas.getContext("2d")
        ctx.drawImage(image, 0, 0)
        let sourceImageData = ctx.getImageData(0, 0, width, height)
        let sourceData = sourceImageData.data
        let integral = new Int32Array(width * height)
        let x = 0, y = 0, lineIndex = 0, sum = 0
        for (x = 0; x < width; x++) {
          sum += sourceData[x << 2]
          integral[x] = sum
        }
        for (y = 1, lineIndex = width; y < height; y++, lineIndex += width) {
          sum = 0
          for (x = 0; x < width; x++) {
            sum += sourceData[(lineIndex + x) << 2]
            integral[lineIndex + x] = integral[lineIndex - width + x] + sum
          }
        }
        let s = width >> 4; // in fact it's s/2, but since we never use s...
        let canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = height
        let result = canvas.getContext('2d').createImageData(width, height)
        let resultData = result.data
        let resultData32 = new Uint32Array(resultData.buffer)
        x = 0; y = 0; lineIndex = 0
        for (y = 0; y < height; y++, lineIndex += width) {
          for (x = 0; x < width; x++) {
            let value = sourceData[(lineIndex + x) << 2]
            let x1 = Math.max(x - s, 0)
            let y1 = Math.max(y - s, 0)
            let x2 = Math.min(x + s, width - 1)
            let y2 = Math.min(y + s, height - 1)
            let area = (x2 - x1 + 1) * (y2 - y1 + 1)
            let localIntegral = integral[x2 + y2 * width]
            if (y1 > 0) {
              localIntegral -= integral[x2 + (y1 - 1) * width]
              if (x1 > 0) {
                localIntegral += integral[(x1 - 1) + (y1 - 1) * width]
              }
            }
            if (x1 > 0) {
              localIntegral -= integral[(x1 - 1) + (y2) * width];
            }
            if (value * area > localIntegral * ratio) {
              resultData32[lineIndex + x] = 0xFFFFFFFF
            } else {
              resultData32[lineIndex + x] = 0xFF000000
            }
          }
        }
        canvas.getContext("2d").putImageData(result, 0, 0)
        let output = {contentType: "image/png"}
        let data = canvas.toDataURL('image/png', 1)
        output.data = data.substring(data.indexOf(';base64,')+8)
        self.endOperation(output)        
      }
      image.src = 'data:' + input.contentType + ';base64,' + input.data
    } else if (['FWK_OperationExecute_FE', 'FWK_OperationWithConfigExecute_FE'].indexOf(this.flow.id) > -1) {
      let input = this.input
      if ((MC.isNull(input.operation) || input.operation === '') && (MC.isNull(input.operationId) || input.operationId === '')) {
        this.endOperationException('SYS_InvalidModelExc', `"operation" or "operationId" input parameter of ${this.flow.id} can not be null or empty!`)
        return
      }
      let confPath = this.confPath
      if ('FWK_OperationWithConfigExecute_FE' == this.flow.id) {
        if ((MC.isNull(input.flowConfig) || input.flowConfig === '')) {
          this.endOperationException('SYS_InvalidModelExc', '"flowConfig" input parameter of FWK_OperationWithConfigExecute_FE can not be null or empty!')
          return
        }
        confPath = input.flowConfig
      }
      const flow = new Flow(null)
      flow.setLang(self.lang).setConfPath(confPath).setParentFlow(self)
      if (!MC.isNull(input.operation)) {
        flow.setFlowConfiguration(confPath, input.operation)
      } else {
        flow.setFlowId(input.operationId)
      }
      if (MC.isFunction(self.afterRenderForm)) {
        flow.setAfterRenderFormFunction(self.afterRenderForm)
      }
      if (input.executionId) {
        flow.setInstanceId(input.executionId)
      }
      flow.setEnv(this.env)
      flow.setWantedLogLevel(this.wantedLogLevel)
      flow.loadAndStart(input.input, null)
    } else if (['BRWS_LocalStorageItemSet', 'BRWS_LocalStorageItemGet', 'BRWS_LocalStorageItemRemove'].indexOf(this.flow.id) > -1) {
      let input = this.input
      if (MC.isNull(input.key) || input.key === '') {
        this.endOperationException('SYS_InvalidModelExc', '"key" input parameter of "' + this.flow.id + '" can not be null or empty!')
        return
      }
      let res = null
      switch (this.flow.id) {
        case 'BRWS_LocalStorageItemSet':
          if (!MC.isNull(input.data)) {
            localStorage.setItem(input.key, JSON.stringify(input.data))
          } else {
            localStorage.removeItem(input.key)
          }
          break
        case 'BRWS_LocalStorageItemGet': 
          res = localStorage.getItem(input.key)
          res = {data: res ? JSON.parse(res) : null}
          break
        case 'BRWS_LocalStorageItemRemove': 
          localStorage.removeItem(input.key)
          break
      }
      this.endOperation(res);
    } else if (this.flow.id == 'BRWS_GetBase') {
      const base = document.querySelector('base').href.split('?')[0]
      this.endOperation({base: base.substring(0, base.lastIndexOf("/") + 1)})
    } else if (this.flow.id == 'BRWS_GetThisRi') {
      this.endOperation({ri: rbThisRi})
    } else if (this.flow.id == 'BRWS_GetRi') {
      let base = document.querySelector('base').href.split('?')[0]
      base = base.substring(0, base.lastIndexOf("/") + 1)
      this.endOperation({ri: window.location.href.substring(base.length)})
    } else if (this.flow.id == 'BRWS_SetRi') {
      let base = document.querySelector('base').href.split('?')[0]
      base = base.substring(0, base.lastIndexOf("/") + 1)
      let currRi = window.location.href.substring(base.length)
      let url = this.input.ri
      url = MC.ensureSystemParameters(currRi, url)
      if (this.input && url !== currRi) {
        window.history.pushState(null, 'title', base + url)
      }
      this.endOperation()
    } else if (this.flow.id == 'FWK_Wait_FE') {
      if ((MC.isNull(this.input.waitTime) || !MC.isNumeric(this.input.waitTime))) {
        this.endOperationException('SYS_InvalidModelExc', '"waitTime" input parameter of FWK_Wait_FE must be integer! Got: ' + this.input.waitTime)
        return
      }
      setTimeout(() => { self.endOperation()}, this.input.waitTime)
    } else if (this.flow.id == 'BRWS_OperationsPreload') {
      if (this.input.operationName && Array.isArray(this.input.operationName)) {
        MC.preloadFlowDefinitions(self.confPath, this.input.operationName, self.lang).then(() => {
         self.endOperation()
        }).catch((err) => {
          this.endOperationException('SYS_IntegrationExc', 'Error while preloading definition of operations! ' + err)
        })
      } else {
        this.endOperation()
      }
    } else if (this.flow.id == 'FWK_ListFlowLogEntries') {
      this.endOperation(MCHistory.listFLowLog(this.input.executionId))
    } else {
      this.endOperationException('SYS_InvalidModelExc', 'Unsupported framework operation: ' + this.flow.id);
    }
  };

  this.updateEnvContext = function(context) {
    this.env.context = context;
    if (!MC.isNull(context) && MC.isPlainObject(context.request) && !MC.isNull(context.request.language)) {
      this.setLang(context.request.language);
    }
    this.addToContext(self.context.data, 'env', self.env);
    if (this.parentFlow) {
      this.parentFlow.updateEnvContext(context);
    }
    if (this.debug('TRACE')) {
      MCHistory.history(this, null, 'ENVIRONMENT UPDATE', {'ENVIRONMENT:': this.env}, {end: Date.now()})
    }
  }

  this.initLogicTimer = function(logic) {
    const self = this
    if (MC.isNumeric(logic.timer) && logic.timer > 0) {
      this.logicTimers[logic.name] = setTimeout(() => { 
        self.eventForm(null, logic.name)
      }, 1000*logic.timer) 
    }
  }
  
  this.initLogicTimers = function() {
    if (this.reactFlow().state.formData.logic && Array.isArray(this.reactFlow().state.formData.logic)) {
      for (let logic of this.reactFlow().state.formData.logic) {
        this.initLogicTimer(logic)
      }
    }
  }
  
  this.clearLogicTimers = function() {
    if (!this.logicTimers) return
    for (let timerKey in this.logicTimers) {
      clearTimeout(this.logicTimers[timerKey])
    }
  }

  this.getCfgParameter = function(key) {
    if (this.context.data.env && this.context.data.env.cfg) {
      return this.context.data.env.cfg[key]
    }
  }

  this.runDecisionTableOperation = () => {
    let input = this.input || {}
    let inputdef = this.flow.input
    let output = {}
    let decisions = this.flow.decision
    let testedRows = []
    if (Array.isArray(decisions) && decisions.length > 0) {
      for (let row of decisions) {
        let rowTrace = {}
        let matches = true
        for (let inpar of inputdef) {
          let inputVal = MC.normalizeValue(input[inpar.name], inpar.basictype)
          let rowVal = MC.findByObjectParamValue(row.input, "name", inpar.name)
          if (rowVal) {
            rowVal = MC.normalizeValue(rowVal.val, inpar.basictype)
          }
          if (inputVal !== rowVal) {
            matches = false
          }
          if (this.debug('TRACE')) {
            rowTrace[inpar.name] = rowVal
          }
        }
        if (this.debug('TRACE')) {
          testedRows.push(rowTrace)
        }
        if (matches) {
          for (let out of row.output) {
            output[out.name] = out.val
          }
          break
        }
      }
    }
    if (MC.isEmptyObject(output)) {
      output = null
    }
    this.endOperation(output, {'Tested rows': testedRows})
  }

  this.getRootFlow = () => {
    return this.parentFlow ? this.parentFlow.getRootFlow() : this
  }

  this.reactFlow = () => {
    return this.getRootFlow().reactFlowObj
  }
 
  this.debug = (requestedLevel) => {
    if (!requestedLevel) {
      requestedLevel = 'MINIMAL'
    }
    const levels = ['MINIMAL', 'BASIC', 'DETAIL', 'TRACE']
    if (this.logLevel) {
      if (levels.indexOf(requestedLevel) <= levels.indexOf(this.logLevel)) {
        return true
      } else {
        return false
      }
    } else if (this.wantedLogLevel) {
      if (this.wantedLogLevel == 'AUTO') return requestedLevel
      if (levels.indexOf(requestedLevel) <= levels.indexOf(this.logLevel)) {
        return true
      } else {
        return false
      }
    }
    return false
  }

}

export {Flow}
