import React from "react";
import ReactDOM from "react-dom";

import {MC} from "../client/MC.js";
import {EditableLabel} from "./EditableLabel.jsx";
import {InteractiveField} from "./InteractiveField.jsx";
import {WidgetModel} from "./WidgetModel.js";

class Field extends React.Component {

  render() {
    var modeler = this.getModelerReact();
    var field = this.props.field;
    var cls = this.props.className;
    var isModelerActive = field.isModelerActive();
    var isInPalette = field.isInPalette;
    var isPlaceholder = field.isPlaceholder();
    var isInteractive = field.isInteractive;
    var isSelectedField = field.isSelectedField();


    if (isModelerActive && modeler.state.structuralMode && !isInPalette) {
      if (field.fields.length > 0 || modeler.state.widgetModelLoaded && WidgetModel.getSupportedChilds(WidgetModel.findWidgetByName(field.widget)).length > 0) {
        cls += " formModelerStructuralModeContainer";
      } 
      cls += " formModelerStructuralMode";
    }

    var onClick = MC.handleReactEvent.bind(this, field)
    var onMouseEnter = MC.handleReactEvent.bind(this, field)
    var onMouseLeave = MC.handleReactEvent.bind(this, field)
    var onFocus = MC.handleReactEvent.bind(this, field)
    var onBlur = MC.handleReactEvent.bind(this, field)
    var onMouseMove;
    var onMouseDown;
    var onMouseUp;
    var onContextMenu;
    var idLabel = "";

    if (isModelerActive) {
      onClick = this.handleClick;
      onMouseEnter = this.handleMouseEnter;
      onMouseLeave = this.handleMouseLeave;
      onMouseMove = this.handleMouseMove;
      onMouseDown = this.handleMouseDown;
      onMouseUp = this.handleMouseUp;
      onFocus = null;
      if (!isInPalette) {
        onContextMenu = this.handleContextMenu;   
      }

      if (field.getOption(["grid", "newLineAfter"]) == "yes") {
        cls += " newLineAfter";
      }
      
      if (field.getOption(["grid", "newLineBefore"]) == "yes") {
        cls += " newLineBefore";
      }

      if (modeler.state.structuralMode && !isInPalette) {
        var style = {textAlign: "left"};
        idLabel = <div key="idLabel" style={style} className="idLabel">{this.props.field.id}</div>;
        if (!isInPalette) {
          idLabel = <EditableLabel field={field} type="id" widget={idLabel} labelStr={field.id} path={["id"]}/>;
        }
      }
    }

    if (isModelerActive && isPlaceholder && !isInteractive && !isInPalette) {
      cls += " formModelerPlaceholder";
      return (<div className={cls} style={this.props.style} data-widget-id={this.props.dataWidgetId} data-widget-name={field.id}>
                {idLabel}
                {this.props.widget}
          </div>);
    } else {
        if (isModelerActive) {
          if (modeler.state.structuralMode || isInPalette) {
            if (field.fields.length > 0 || modeler.state.widgetModelLoaded && WidgetModel.getSupportedChilds(WidgetModel.findWidgetByName(field.widget)).length > 0) {
              cls += " formModelerTingeContainer";
            } else {
              cls += " formModelerTinge";
            }
          }
          if (isInteractive || (isSelectedField && !isInPalette)) {
            cls += " formModelerSelected";
          }
        }
        if (isInteractive) {
          var style = MC.extend({}, this.props.style, field.bbox);
          style.position = 'absolute';
          style.zIndex = 2;
          style.paddingLeft = "14px";
          style.paddingRight = "14px";
          return <InteractiveField
                      field={field}
                      className={cls}
                      style={style}
                      modelerReact={modeler}
                      idLabel={idLabel}
                      widget={this.props.widget}/>;
        } else {
          return <div className={cls}
                      style={this.props.style}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      onClick={onClick}
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                      onMouseMove={onMouseMove}
                      onMouseDown={onMouseDown}
                      onMouseUp={onMouseUp}
                      onContextMenu={onContextMenu}
                      data-widget-id={this.props.dataWidgetId}
                      data-widget-name={field.id}>
                    {idLabel}
                    {this.props.widget}
                 </div>;

        }
      }
    }

    handleClick = (event) => {
      event.stopPropagation();
    };

    handleContextMenu = (event) => {
      event.stopPropagation();
      event.preventDefault();
      if (!event.ctrlKey) { 
        var modeler = this.getModelerReact();
        var selectedFieldIds = modeler.state.selectedFieldIds;
        var id = this.props.field.rbsid;
        if (selectedFieldIds.indexOf(id) == -1) {
          modeler.setSelectedField(this.props.field.rbsid);
        }
        var $modeler = $(ReactDOM.findDOMNode(modeler));
        let offset = $modeler.offset(); //find("#innerModeler")
        var position = {x: event.pageX - offset.left, y: event.pageY - offset.top}
        modeler.showInspector(position);
      }
    };

    get$widgetBBox() {
      var $widget = $(ReactDOM.findDOMNode(this));
      var $form = $("#modeler .ui.form");
      var offset = $widget.offset();
      var left = $widget.offset().left - $form.offset().left;
      var top = $widget.offset().top - $form.offset().top;
      var height = $widget.outerHeight();
      var width = $widget.outerWidth();
      return {left: left,
              top: top,
              height: height,
              width: width};
    }

    makeInteractiveField = (event) => {
      var interactiveField = this.props.field.getCopy();
      interactiveField.bbox = this.get$widgetBBox();
      interactiveField.initPosition = {x: event.pageX, y: event.pageY};
      interactiveField.isInteractive = true;
      interactiveField.originalField = this.props.field;
      var isInPalette = this.props.field.isInPalette;
      var modeler = this.getModelerReact();
      if (isInPalette) {
        interactiveField.parent = modeler.state.form;
        interactiveField.grid = [];
        interactiveField.addGrid();
        delete interactiveField.isInPalette;
      }
      var isInTable = this.props.field.isInTable();
      if ((event.shiftKey) && !isInTable && !isInPalette) {
        interactiveField.offsetChange = true;
      } else if (this.isResizePossible(event)) {
        interactiveField.resizeChange = true;
      }

      if (interactiveField.offsetChange ||  interactiveField.resizeChange) {
        var initColumns = this.props.field.columns();
        var $node = $(ReactDOM.findDOMNode(this));
        var initWidth = parseInt($node.outerWidth(true));
        interactiveField.columnWidth = initWidth / initColumns;
      }

      return interactiveField;
    };

    handleMouseEnter = () => {
      var modelerReact = this.getModelerReact();
      if (!modelerReact.state.interactiveField) {
        document.body.style.cursor = "move";
      }
    };

    handleMouseLeave = () => {
      var modelerReact = this.getModelerReact();
      var parent = this.props.field.getParent();
      if (!modelerReact.state.interactiveField && ( typeof parent == "undefined" || parent.isTopLevel())) {
        document.body.style.cursor = "";
      }
    };

    handleMouseMove = (event) => {
      var modelerReact = this.getModelerReact();
      if (event.buttons != 0 && modelerReact.fieldMouseDown == this && !event.ctrlKey) {
        if (modelerReact.isDragPossible && !modelerReact.state.interactiveField) {
          var interactiveField = this.makeInteractiveField(event);
          modelerReact.setState({showPalette: false,
                                 interactiveField: interactiveField});
        }
      }
      if (!modelerReact.state.interactiveField) {
        if (this.isResizePossible(event)) {
          document.body.style.cursor = "ew-resize";
        } else {
          document.body.style.cursor = "move";
        }
        event.stopPropagation();
      }
    };

    isResizePossible = (event) => {
      var margin = 20;
      var $node = $(ReactDOM.findDOMNode(this));
      var isRight = event.pageX > ($node.offset().left + $node.outerWidth() - margin);
      var isInTable = this.props.field.isInTable();
      var isInPalette = this.props.field.isInPalette;
      var isInlineRepeater = this.props.field.isRepeater() && 'true' == this.props.field.getOption(["param", "@inline"]);
      return isRight && !isInTable && !isInPalette && !isInlineRepeater;
    };

    handleMouseDown = (event) => {
      if (event.buttons == 1) { // Pouze normální klik.
        var modelerReact = this.getModelerReact();
        modelerReact.fieldMouseDown = this;

        event.stopPropagation();
        if (!this.props.field.isInPalette) {
          modelerReact.setState({showPalette: false, showInspector: false});
          if (event.ctrlKey) {
            event.preventDefault()
            modelerReact.tongueSelectedFieldId(this.props.field.rbsid);
          } else {
            modelerReact.setSelectedField(this.props.field.rbsid);
          }

          console.log(this.props.field);
        }
      }

    };

    getModelerReact() {
      return this.props.field.getModelerReact();
    }
    
}

export {Field};
