import React from "react"

import {MC} from './MC.js'
import {MCHistory} from "./MCHistory.js"
import {ReactFlow} from "./ReactFlow.jsx"

class EmbeddedDialog extends React.Component {

  state = {flowName: null, start: false, input: {}}

  componentDidMount() {
    window.addEventListener("message", this.onMessage, false)
    this.operateReload(true)
  }

  componentDidUpdate() {
     this.operateReload(false)
  }

  componentWillUnmount () {
    window.removeEventListener("message", this.onMessage)
  }

  operateReload(init) {
    var field = this.props.data;
    if (MC.getFieldParamBooleanValue(field.param, '@reload')) {
      MC.putFieldParamValue(field.param, '@reload', false)
      this.startFlow()
    } else if (this.state.start) {
      this.setState({start: false, input: {}})
    } else if (init) {
      let savedState = field.flow.reactFlow().pickDestroyedChild(field.rbsid)
      if (savedState) {
        this.setState({savedState: savedState})
      }
    } else if (this.state.savedState) {
      this.setState({savedState: null})
    }
  }

  onMessage = (e) => {
    let self = this
    if (e.data && MC.isPlainObject(e.data)) {
      if ('MNC-INTERNAL.READY-RUN' === e.data.name && self.props.data.flow.instanceId === e.data.instanceId) {
        // start flow in e. dialog if auotload is set to true
        if (MC.getFieldParamBooleanValue(self.props.data.param, '@dataActionAutoLoad')) {
          this.startFlow()
        }
      }
    }
  }

  startFlow = () => {
    let lStart = performance.now()
    let lStartDate = Date.now()
    let field = this.props.data
    let flow = field.flow
    if (!flow) {
      flow.endOperationException('SYS_InvalidModelExc', 'Dialog widget was initialized badly! Flow instance must be set in data!')
      return
    }
    let config = {dataAction: MC.getFieldParamValue(field.param, '@dataAction'), actionLink: MC.getFieldParamValue(field.param, '@dataEvent')}
    let dRes = flow.callLogicAction(config, field, true)
    if (!MC.isNull(dRes)) {
      if (!MC.isNull(MC.getFieldParamValue(field.param, '@dataAction'))) {
        MCHistory.log(MCHistory.T_WARNING, 'Using "@dataAction" is deprecated. Use "@dataEvent" with corresponding form event instead.', field.flow.debug())
      }
      this.setState({flowName: dRes.flowName, start: true, input: dRes.input, trace: dRes.trace, actionCode: dRes.actionCode, altMapping: dRes.altMapping})
      MCHistory.history(flow, flow.getActionByCode(dRes.actionCode), 'EM-DIALOG CALL', {'Input': dRes.input, 'Trace': dRes.trace}, {start: lStartDate, end: Date.now(), duration: performance.now() - lStart, dialog: dRes})
    }
  }

  onEnd  = (output, message) => {
    let flow = this.props.data.flow
    MC.putFieldParamValue(this.props.data.param, '@visible', false)
    let dActionCode = this.state.actionCode
    let altMapping = this.state.altMapping
    this.setState({flowName: null, input: null, start: false, actionCode: null, altMapping: null})
    if (!MC.isNull(message)) {
      flow.endOperationException(output, message, this.state.input, null, null)
    } else {
      let submitOpts = null
      var dAction = flow.getActionByCode(dActionCode)
      if (dAction.submitParent) {
        submitOpts = {}
        submitOpts.triggeredByField = this.props.data
      }
      flow.endEmbeddedDialog(dActionCode, this.state.input, output, submitOpts, null, altMapping)
    }
  }

  render() {
    const flow = this.props.data.flow
    const loader = MC.getFieldParamValue(this.props.data.param, '@loader') || flow.reactFlow().state.loader || 'all'
    const debugInParam = MC.getFieldParamValue(this.props.data.param, '@debug')
    return <ReactFlow configuration={flow.confPath} flowName={this.state.flowName} env={flow.env} input={this.state.input} start={this.state.start} autoScrollUp={false} onEndFunction={this.onEnd} 
             embedded={true} console={debugInParam ? true : false} options={flow.reactFlow().props.options} parent={flow.reactFlow()} emdialogId={this.props.data.rbsid} configurationObject={flow.context.data.env.cfg} debug={debugInParam || flow.wantedLogLevel} 
             loader={loader} savedState={this.state.savedState}/>
  }

}

export {EmbeddedDialog}