import React from "react"

import {MC} from './MC.js'

class Label extends React.Component {

  render() {
    const field = this.props.field
    let value = this.props.value
    const labelPlacement = MC.getFieldParamValue(field.param, '@titlePlacement')
    if (labelPlacement == 'IN' && !this.props.inTable) {
      value = MC.getFieldParamValue(field.param, '@title')
      if (MC.isNull(value) || value === '') {
        value = this.props.value
      }
    }
    let cssclass = MC.getFieldParamValue(field.param, '@cssClass')
    let HeaderType =  MC.getFieldParamValue(field.param, '@headerType')
    if (MC.isNull(HeaderType)) {
      var escapeHtml = MC.getFieldParamValue(field.param, '@escapeHtml')
      if (escapeHtml) {
        return <span className={cssclass} id={this.props.id} ref={field.rbsid} data-widget-i-name={field.id}>{value}</span>
      } else {
        return <div className={cssclass} id={this.props.id} ref={field.rbsid} dangerouslySetInnerHTML={{__html: MC.customHtml(value)}} data-widget-i-name={field.id}></div>
      }
    } else {
      cssclass = MC.classes('ui header', cssclass)
      HeaderType = HeaderType.toLowerCase()
      return <HeaderType className={cssclass} id={this.props.id} ref={field.rbsid} data-widget-i-name={field.id}>{value}</HeaderType>
    }
  }

}

export {Label}