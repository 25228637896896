import ReactDOM from "react-dom"
import React from "react"

import UriTemplate from "../client/UriTemplate.js"
import {MC} from "../client/MC.js"
import {MCHistory} from "../client/MCHistory.js"

import {ErrorPage} from "./ErrorPage.jsx"
import {MiniApp} from "./MiniApp.jsx"
import {ReactFlow} from "../client/ReactFlow.jsx"

function MiniClientApp() {
  this.lang = 'en';
  this.component = null;
  this.configuration = null;
  this.debug = null;
  this.mainFlowName = null;
  this.appRi = null;
  this.path = null;
  this.query = {};
  this.error = {};
  this.config = {};
  this.runDispatch = false;
  this.start = false;
}

MiniClientApp.prototype.fetchConfiguration = function(defaultConf) {
  this.configuration = this.getUrlParameterByName("configuration") || defaultConf;
  return this;
};

MiniClientApp.prototype.getConfiguration = function() {
  return this.configuration;
};

MiniClientApp.prototype.setDebug = function() {
  var debugInUrl = this.getUrlParameterByName("jsi.debug") || this.getUrlParameterByName("debug")
  if (debugInUrl) {
    this.debug = debugInUrl
  }
  return this
};

MiniClientApp.prototype.getDebug = function() {
  return this.debug;
};

MiniClientApp.prototype.setDefaultMainFlowName = function(defaultMainFlow) {
  this.mainFlowName = defaultMainFlow;
  return this;
};

MiniClientApp.prototype.getMainFlowName = function() {
  if (!MC.isNull(this.config.dispatchOperation) && this.runDispatch) {
    return this.config.dispatchOperation;
  }
  return this.mainFlowName;
};

MiniClientApp.prototype.renderElement = function(elm) {
  return ReactDOM.render(elm, document.getElementById('content'));
};

MiniClientApp.prototype.loadData = function () {
  var self = this;
  if (!MC.isEmptyObject(this.config)) {
    return new Promise(function(resolve) { resolve(true); });
  }
  var url = ReactFlow.flowServerUrl + 'miniclientcfg?name=API_AppConfigGet&inputdata=' + encodeURIComponent(JSON.stringify({configPath: this.configuration}));
  if (this.query && this.query.debug) {
    url += '&includeid=true';
  }
  return MC.callServer('GET', url, MC.getJsonType()).then(function (res) {
    try {
      var output = {};
      if (res.content) {
        output = JSON.parse(res.content);
      }
      if (res.status == 200 || res.status == 204) {
        self.config = output;
        self.prepareConfigurationObject();
      } else {
        var message = '';
        if (output.errorName) {
          message += output.errorName + ': ';
        }
        message += 'Loading application configuration failed! Status:' + res.status;
        if (output.errorMessage) {
          message += ' ' + output.errorMessage;
        }
        self.setError(message, (new Error()).stack ? (new Error()).stack : null);
      }
    } catch (e) {
      self.setError(res.content, (new Error()).stack ? (new Error()).stack : null);
    }
  });
};

MiniClientApp.prototype.parseRi = function(appRi) {
  const pathAndQuery = appRi.split('#')[0]
  const fragment = appRi.split('#')[1]
  const path = pathAndQuery.split('?')[0]
  var queryString = pathAndQuery.split('?')[1]
  if (!this.isEqualRiIgnoreOrder(this.appRi, appRi)) {
    this.runDispatch = true
  } else {
    this.runDispatch = false
  }
  this.appRi = appRi
  this.path = path
  this.fragment = fragment
  if (this.path == '/' || this.path == './') {
    this.path = ''
  }
  this.query = this.queryParamsFromString(queryString)
}

MiniClientApp.prototype.entrypointsRouteTo = function(appRi) {
  const self = this
  if (!self.isError()) {
    self.setDebug()
    const entrypoint = self.getEntrypoint(appRi)
    if (!entrypoint.found) {
      self.setError("No entry point found for path '" + appRi + "'!", (new Error()).stack ? (new Error()).stack : null)
    } else if (entrypoint.redirect) {
      self.routeTo(entrypoint.appRi)
      return
    } else {
      self.start = true
      if (entrypoint.layoutFlowName) {
        self.mainFlowName = entrypoint.layoutFlowName
      } else {
        self.mainFlowName = entrypoint.mainFlowName
      }
      if (entrypoint.appRi != appRi) {
        // toggled slash
        self.appRi = entrypoint.appRi
        window.history.replaceState({'appRi': entrypoint.appRi}, 'title', self.getBaseHref() + entrypoint.appRi)
      }
    }
    if (!self.isError()) {
      let flowName = entrypoint.layoutFlowName ? entrypoint.mainFlowName : null 
      if (Array.isArray(entrypoint.preloadOperation) && entrypoint.preloadOperation.length > 0) {
        MC.preloadFlowDefinitions(self.configuration, entrypoint.preloadOperation, self.lang).then(() => {
          self.reactComponent = self.renderElement(React.createElement(MiniApp, {app: self, flowName}))
        })
      } else {
        self.reactComponent = self.renderElement(React.createElement(MiniApp, {app: self, flowName}))
      }
    } else {
      self.reactComponent = self.renderElement(React.createElement(ErrorPage, {app: self}))
    }
  }
}

MiniClientApp.prototype.routeToPath = function(appRi) {
  var self = this;
  if (self.isError())  {
    self.reactComponent = self.renderElement(React.createElement(ErrorPage, {app: self}));
  } else {
    self.loadData().then(function() {
      self.entrypointsRouteTo(appRi);
    }).catch(function(e) {
      self.setError(e.message, e.stack ? e.stack : null);
      self.reactComponent = self.renderElement(React.createElement(ErrorPage, {app: self}));
    })
  }
};

MiniClientApp.prototype.getMenuByName = function(name) {
  if (this.config.menu && Array.isArray(this.config.menu)) {
    for (let menu of this.config.menu) {
      if (name === menu.name) {
        if (Array.isArray(menu.items)) {
          for (let item of menu.items) {
            if (typeof item.url === 'string') {
              if (item.url.startsWith('/')) {
                item.url = item.url.substring(1)
              }
              if (this.appRi.startsWith(item.url) && item.url !== '' || (item.url === '' || item.url.startsWith('?')) && (this.appRi === '' || this.appRi.startsWith('?'))) {
                menu.activeKey = item.key
              }
            }
            if (Array.isArray (item.items)) {
              for (let sitem of item.items) {
                if (typeof sitem.url === 'string') {
                  if (sitem.url.startsWith('/')) {
                    sitem.url = sitem.url.substring(1)
                  }
                  if (this.appRi.startsWith(sitem.url) && sitem.url !== '' || (sitem.url === '' || sitem.url.startsWith('?')) && (this.appRi === '' || this.appRi.startsWith('?'))) {
                    menu.activeKey = sitem.key
                  }
                }
              }
              item['items'] = item.items
            }
          }  
        }
        return menu
      }
    }
  }
  return null
}

MiniClientApp.prototype.getEntrypoint = function(appRi) {
  const entrypoint = {};
  const appRiFragArr = appRi.split('#');
  if (appRiFragArr.length > 1) {
    appRi = appRiFragArr[0];
  }
  const appRiArr = appRi.split('?',2);
  let appRiToggledSlash = appRiArr[0].endsWith('/') ? appRiArr[0].substring(0, appRiArr[0].length - 1) : appRiArr[0] + '/';
  if (appRiArr.length > 1) {
    appRiToggledSlash += '?' + appRiArr[1];
  }
  const flowInUrl = this.getUrlParameterByName("flowName");
  if (flowInUrl) {
    entrypoint.mainFlowName = flowInUrl;
  } else {
    if (this.config && this.config.EntryPoint && Array.isArray(this.config.EntryPoint)) {
      const entryPts = this.config.EntryPoint;
      for (let i=0; i<entryPts.length; i++) {
        let template = entryPts[i].path;
        if (!template) {
          continue;
        }
        if (template.indexOf('?') == -1) {
          template += '{?params*}';
        }
        const urit = new UriTemplate(template);
        let params = urit.fromUri('/' + appRi);
        if (!params) {
          params = urit.fromUri('/' + appRiToggledSlash);
          entrypoint.appRi = appRiToggledSlash;
          entrypoint.redirect = true;
        } else {
          entrypoint.appRi = appRi;
          entrypoint.redirect = false;
        }
        if (appRiFragArr.length > 1) {
          entrypoint.appRi += '#' + appRiFragArr[1];
        }
        if (!!params) {
          entrypoint.found = true
          // entrypoint found
          if (entryPts[i].operation) {
            entrypoint.mainFlowName = entryPts[i].operation
          }
          entrypoint.layoutFlowName = entryPts[i].layoutOperation
          entrypoint.preloadOperation = entryPts[i].preloadOperation
          return entrypoint
        }
      }
    }
  }
  return entrypoint
}

MiniClientApp.prototype.routeTo = function(appRi) {
  appRi = appRi == './' ? '' : appRi
  if (typeof appRi !== 'undefined') {
    this.parseRi(appRi)
  }
  this.routeToPath(appRi)
}

MiniClientApp.prototype.run = function() {
  this.lang = rbLang;
  var appRi;
  var base = this.getBaseHref();
  appRi = window.location.href.substring(base.length);
  this.routeTo(appRi);
};

MiniClientApp.prototype.getBaseHref = function() {
  var base = document.querySelector('base').href;
  if (base.indexOf('?') > -1) {
    base = base.split('?',2)[0];
  }
  return base.substring(0, base.lastIndexOf("/") + 1);
};

MiniClientApp.prototype.getUrlParameterByName = function(name) {
  var url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

MiniClientApp.prototype.prepareConfigurationObject = function() {
  var conf = {};
  var config = this.config;
  if (config && config.configData && Array.isArray(config.configData)) {
    for (var i=0; i < config.configData.length; i++) {
      if (config.configData[i].key && config.configData[i].value) {
        conf[config.configData[i].key] = config.configData[i].value;
        if (Array.isArray(conf[config.configData[i].key]) && conf[config.configData[i].key].length == 1) {
          conf[config.configData[i].key] = conf[config.configData[i].key][0]
        }
      }
    }
  }
  if (config.environmentOperation) {
    conf['fl:environmentOperation'] = config.environmentOperation;
  }
  config.configData = conf;
};

MiniClientApp.prototype.getConfigurationObject = function() {
  return this.config.configData;
};

MiniClientApp.prototype.getQueryObject = function() {
  return this.query;
};

MiniClientApp.prototype.getInputObject = function(triggerStructured = false) {
  if (!MC.isNull(this.config.dispatchOperation) && this.runDispatch || triggerStructured) {
    return {path: this.path, parameters: this.getQueryObject()};
  } else {
    return this.getQueryObject();
  }
};

MiniClientApp.prototype.setError = function(mess, stack) {
  this.error = {msg: mess, stack: stack};
  console.error(stack ? mess + " - " + stack : mess);
};

MiniClientApp.prototype.isError = function() {
  return !MC.isEmptyObject(this.error);
};

MiniClientApp.prototype.log = function(mess) {
  MCHistory.log(MCHistory.T_INFO, mess, this.getDebug());
};

MiniClientApp.prototype.throwError = function(mess, stack) {
  this.setError(mess, stack);
  this.renderElement(React.createElement(ErrorPage, {app: this}));
};

MiniClientApp.prototype.getError = function() {
  return this.error;
};

MiniClientApp.prototype.onEndFlow = function(output, message) {
  if (typeof message == 'undefined') {
    this.log("Reloading application.");
    location.reload();
  } else {
    this.throwError(output.toString() + ': ' +  message.toString(), (new Error()).stack);
  }
}

MiniClientApp.prototype.obtainStart = function() {
  if (this.start) {
    this.start = false;
    return true;
  }
  return false;
}

MiniClientApp.prototype.isEqualRiIgnoreOrder = function(ri1, ri2) {
  if (MC.isNull(ri1) || MC.isNull(ri2)) {
    return ri1 == ri2
  }
  const path1 = ri1.split('?')[0]
  const path2 = ri2.split('?')[0]
  if (path1 !== path2) {
    return false
  }
  const queryParameters1 = this.queryParamsFromString(ri1)
  const queryParameters2 = this.queryParamsFromString(ri2)
  for (let param in queryParameters1) {
    if (queryParameters1[param] !== queryParameters2[param]) {
      return false
    }
  }
  return true
}

MiniClientApp.prototype.queryParamsFromString = function(ri) {
  let queryParameters = {}
  if (!ri) {
    return queryParameters
  }
  if (ri.indexOf('?') > -1) {
    ri = ri.split('?')
    ri = ri.length > 1 ? ri[1] : ''
  }
  let match
  const pl = /\+/g
  const search = /([^&=]+)=?([^&]*)/g
  const decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); }
  while (match = search.exec(ri)) {
    if (queryParameters[decode(match[1])]) {
      if (!Array.isArray(queryParameters[decode(match[1])])) {
        queryParameters[decode(match[1])] = [queryParameters[decode(match[1])]]
      }
      queryParameters[decode(match[1])].push(decode(match[2]))
    } else {
      queryParameters[decode(match[1])] = decode(match[2])
    }
  }
  return queryParameters
}

export {MiniClientApp}