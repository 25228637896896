import React from "react"

import {MC} from './MC.js'

class Media extends React.Component {

  render() {
    const field = this.props.data
    let cssclass = MC.getFieldParamValue(field.param, '@cssClass')
    cssclass = cssclass ? ' ' + cssclass : ''
    cssclass = 'ui image' + cssclass
    const contentType = MC.getFieldParamValue(field.param, '@contentType')
    const showAsLink = MC.getFieldParamValue(field.param, '@showAsLink')
    const value = MC.getFieldParamValue(field.param, 'value')
    if (showAsLink == true || field.widget == 'defaultviewer') {
      let fileName = MC.getFieldParamValue(field.param, '@fileName')
      if (MC.isNull(fileName)) {
        fileName = "..."
      }
      let uri = MC.getFieldParamValue(field.param, '@url')
      if (MC.isNull(uri)) {
        uri = 'data:' + contentType + ';base64,' + value
      } else {
        uri = MC.rebaseUrl(field.flow.flow.model, uri)
      }
      return <a href={uri} download={fileName} target="_blank" data-widget-i-name={field.id}><i className="file icon"/>{fileName}</a>
    } else if (field.widget == 'imageviewer') {
      let uri = MC.getFieldParamValue(field.param, '@url')
      if (!MC.isNull(uri)) {
        uri = MC.rebaseUrl(field.flow.flow.model, uri)
      } else if (!MC.isNull(value)) {
        uri = 'data:' + contentType + ';base64,' + value
      }
      if (MC.isNull(uri)) {
        return <span className={cssclass} data-widget-i-name={field.id}/>
      } else {
        return <img className={cssclass} src={uri} data-widget-i-name={field.id}/>
      }
    } else if (field.widget == 'pdfviewer') {
      let uri = MC.getFieldParamValue(field.param, '@url')
      if (!MC.isNull(uri)) {
        uri = MC.rebaseUrl(field.flow.flow.model, uri)
      } else if (!MC.isNull(value)) {
        uri = 'data:application/pdf;base64,' + value
      }
      if (MC.isNull(uri)) {
        return <span className={cssclass} data-widget-i-name={field.id}/>
      } else {
        return <embed src={uri} width={field.width} height={field.height} type="application/pdf" data-widget-i-name={field.id}/>
      }
    } else {
      return <span data-widget-i-name={field.id}>Unsupported content type: {contentType}</span>
    }
  }

}

export {Media}