import React from "react";
import ReactDOM from "react-dom";

import {MC} from "../client/MC.js";
import {Form} from "../client/Form.jsx";
import {ReactFlow} from "../client/ReactFlow.jsx";

import {Clipboard} from "./Clipboard.js";
import {Inspector} from "./Inspector.jsx";
import {Palette} from "./Palette.jsx";
import {FieldDef} from "./FieldDef.js";
import {WidgetModel} from "./WidgetModel.js";
import {Menu} from "./Menu.jsx";
import {addEmptyRbsId} from './library'

import './Modeler.css';
import './meta.css';

class Modeler extends React.Component {

  state = {undoStack: [],
    redoStack: [],
    savedPosition: 0,
    ghostMode: true,
    structuralMode: true,
    selectedFieldIds: [],
    form: this.props.form,
    active: true,
    widgetModelLoaded: false,
    formDefinitionLoaded: false,
    resolution: MC.getAvailableResolution("medium", this.props.form) || "medium",
    fieldPalette: null,
    showPalette: false,
    showInspector: false,
    palettePosition: null,
    inspectorPosition: null,
    interactiveField: null};

    constructor(props) {
      super(props)
      FieldDef.setProto(props.form)
      props.form.setParents()      
      document.body.style.overflow = "scroll"
      let self = this
      document.addEventListener("keydown", function (event) {
        if (event.target.localName != "input") {
          self.handleKeyDown(event)
        }
        if (event.key == "Enter" || event.key == "Escape") {
          self.cancelModal()
        }
      })
      this.props.form.flow.isModelerActive = true
    }

    handleKeyDown = (event) => {
      if ((event.key == "Delete" || event.key == "Backspace")) { //&& event.ctrlKey
        Clipboard.deleteSelectedFields(this);
      }
      if ((event.code == "KeyA" && event.ctrlKey)) {
        event.preventDefault()
        this.setSelectedFields(this.state.form.getChildFieldIds());
      }
      if ((event.code == "KeyZ" && event.ctrlKey)) {
        this.handleUndo();
      }
      if ((event.code == "KeyY" && event.ctrlKey)) {
        this.handleRedo();
      }
      if ((event.code == "KeyL" && event.ctrlKey)) {
        event.preventDefault()
        this.store(this.state.form);
        this.selectedFields().forEach(function(field) {
          var newLineAfter = field.getOption(["grid", "newLineAfter"]);
          field.setOption(["grid", "newLineAfter"], newLineAfter == "yes" ? "never" : "yes");
        });
        this.forceUpdate();
        this.formWasChanged();
      }
      if ((event.code == "KeyS" && event.shiftKey)) {
        if (this.isSavePossible()) {
          this.handleSave();
        }
      }
      if ((event.code == "KeyC" && event.ctrlKey)) {
        Clipboard.copySelectedFields(this);
      }
      if ((event.code == "KeyV" && event.ctrlKey)) {
        Clipboard.paseFieldsFromClipboard(this);
      }
      if ((event.code == "KeyX" && event.ctrlKey)) {
        Clipboard.copySelectedFields(this);
        Clipboard.deleteSelectedFields(this);
      }
    };

    componentDidUpdate() {
      var self = this;
      if(this.state.errorMessage) {
        var $modeler = $(ReactDOM.findDOMNode(this));
        $modeler.find('.message .close').on('click', function() {
            $(this).closest('.message').transition('fade');
            self.setState({errorMessage: null});
        });
      }
      var isSavePossible = !this.props.readOnly &&  !this.isSaved();
      modelerChanged(isSavePossible);
      if (this.resize) {
        this.resize = false;
        modelerChanged("resize")
      }
    }

    isSavePossible = () => {
      return !this.props.readOnly && !this.isSaved();
    };

    isSaved = () => {
      return this.state.savedPosition == this.state.undoStack.length;
    };

    componentDidMount() {
      var self = this;
      this.state.form.flow.modelerReact = this;
      this.resize = true;
      FieldDef.form = this.state.form;
      FieldDef.modeler = this;
      this.isDragPossible = true;
      WidgetModel.load(this.state.form.model, this.state.form.flow.lang).then(function(){
        self.setState({widgetModelLoaded: true});
      });

      this.getFormDefinition().then(function(definition) {
        self.state.form.setDefinition(definition);
        self.setState({formDefinitionLoaded: true});
      });

      $(document).mouseup(this.handleMouseup);

      $(document).add(parent.document).click(function(event) {
        var cont = $('#modelerContainer');
        if (!cont.is(event.target) && cont.has(event.target).length === 0) {
          self.handleClick();
        }
      });
      modelerChanged("loaded")
    }

    handleMouseup = (event) => {
      this.fieldMouseDown = null;
    };

    componentWillUnmount() {
      delete this.state.form.flow.modelerReact;
      delete this.state.form.flow.isModelerActive;
      $("body").css("overflow");
      $(document).off("mouseup");
      document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleClick = (event) => {
      this.setState({selectedFieldIds: [], showPalette: false, showInspector: false});
    };

    store = (form) => {
      var formCopy = form.getCopy();
      this.setState({undoStack: this.state.undoStack.concat([formCopy]),
                     redoStack: []});
      this.resize = true;
    };

    getFormCopy = () => {
      return this.state.form.getCopy();
    };

    setForm = (form) => {
      FieldDef.setProto(form);
      form.setParents()
      this.setState({form: form});
    };

    formWasChanged = () => {
      this.setForm(this.state.form);
    };

    setSelectedField = (fieldId) => {
      var inspectorTop = null;
      var $innerModeler = $("#innerModeler");
      var scrollY = window.scrollY;
      var innerModelerTop = $innerModeler.offset().top;
      if (scrollY > innerModelerTop) {
        inspectorTop = scrollY - innerModelerTop;
      }
      this.setState({selectedFieldIds: [fieldId], inspectorTop: inspectorTop});
    };

    setSelectedFields = (fieldIds) => {
      this.setState({selectedFieldIds: fieldIds});
    };

    tongueSelectedFieldId = (fieldId) => {
      var selectedFieldIds = this.state.selectedFieldIds;
      if (selectedFieldIds.indexOf(fieldId) == -1) {
        this.setState({selectedFieldIds: selectedFieldIds.concat([fieldId])});
      } else {
        this.setState({selectedFieldIds: selectedFieldIds.filter(function(id) {return id != fieldId})});
      }
    };

    selectedFields = () => {
      var form = this.state.form;
      var selectedFieldIds = this.state.selectedFieldIds;
      return selectedFieldIds.map(function(fieldId) {
        return form.findFieldByRbsId(fieldId);
      }).filter(function(field) {
        return field;
      });
    };

    handleUndo = () => {
      var undoStack = this.state.undoStack;
      if (undoStack.length != 0) {
        var redoStack = this.state.redoStack;
        var form = this.state.form;
        this.resize = true;
        this.setForm(undoStack[undoStack.length - 1])
        this.setState({undoStack: undoStack.slice(0,-1),
                       redoStack: redoStack.concat([form.getCopy()])});
      }
    };

    handleRedo = () => {
      var redoStack = this.state.redoStack;
      if (redoStack.length != 0) {
        var undoStack = this.state.undoStack;
        var form = this.state.form;
        this.resize = true;
        this.setForm(redoStack[redoStack.length - 1]);
        this.setState({redoStack: redoStack.slice(0,-1),
                       undoStack: undoStack.concat([form.getCopy()])})
      }
    };

    cancel = () => {
      this.setState({undoStack: this.state.undoStack.slice(0,-1)});
    };

    resetStacks = () => {
      this.setState({undoStack: [], redoStack: [], savedPosition: 0});
    };

    setErrorMessage = (errorMessage) => {
      this.setState({errorMessage: errorMessage});
    };

    handleSave = () => {
      var self = this;
      var form = this.state.form;
      this.setState({saveInProcess: true});
      this.getFormDefinition().then(function (formdef) {
        if (formdef) {
          var def = form.getDefinition();
          formdef.FormField = addEmptyRbsId(def.FormField);
          console.log(formdef); //JSON.stringify(formdef, null, 4));
          self.saveFormDefinition(formdef).then(function() {
            self.setState({savedPosition: self.state.undoStack.length,
                           saveInProcess: false});
            form.setToStored();
            self.formWasChanged();
          }).catch(function(err) {
            console.log(err);
            self.setErrorMessage("Chyba ukládání");
            self.setState({saveInProcess: false});
          });
        } else {
            self.setErrorMessage("Chyba ukládání");
            self.setState({saveInProcess: false});
        }

      }).catch(function(err) {
          console.log(err);
          self.setErrorMessage("Chyba ukládání");
          self.setState({saveInProcess: false});
      });
    };

    getFormDefinition = () => {
      let form = this.state.form
      return MC.callServer('GET', ReactFlow.flowServerUrl + 'miniclientcfg?name=API_FormGet&inputdata=' + encodeURIComponent(JSON.stringify({model: form.model, formid: form.formId})), MC.getJsonType()).then(function (res) {
        let def = JSON.parse(res.content).properties
        WidgetModel.ensureArray(def, ['FormField', 'FormFieldGrid', 'FormFieldParam'])
        return def
      })
    }

    saveFormDefinition = (formdef) => {
      let form = this.state.form
      let self = this
      let body = {model: form.model, formid: form.formId, definition: formdef}
      return MC.callServer('POST', ReactFlow.flowServerUrl + 'miniclientcfg?name=API_FormUpdate&loggingthreshold=DETAIL', '*/*', JSON.stringify(body), MC.getJsonType()).then(function(res) {
        if (res.status !== 200 && res.status !== 204) {
          console.log(res.content)
          self.setErrorMessage("Chyba ukládání")
          self.setState({saveInProcess: false})
        }
      }).catch(function(err) {
        console.log(err)
        self.setErrorMessage("Chyba ukládání")
        self.setState({saveInProcess: false})
      })
    }

    handleTongueGhostMode = () => {
      this.setState({ghostMode: !this.state.ghostMode});
    };

    tongueStructuralMode = () => {
      this.setState({structuralMode: !this.state.structuralMode});
    };

    handleChangeResolution = (resolution) => {
      this.resize = true;
      this.setState({resolution: resolution});
    };

    handleDeleteGrid = () => {
      this.store(this.state.form);
      this.state.form.deleteGrid(this.state.resolution);
      this.formWasChanged();
    };

    handleCreateGrid = () => {
      this.store(this.state.form);
      this.state.form.ensureGrid(this.state.resolution);
      this.state.form.addGrid(this.state.resolution);
      this.formWasChanged();
    };

    handleContextMenu = (event) => {
      event.preventDefault();
      var $modeler = $(ReactDOM.findDOMNode(this));
      var offset = $modeler.offset();
      this.setState({showPalette: true,
        showInspector: false,
        palettePosition: {x: event.pageX - offset.left, y: event.pageY - offset.top}});
    };

    showInspector = (position) => {
      this.setState({showInspector: true,
                     showPalette: false,
                     inspectorPosition: position});
    };

    handleCancelModal = (event) => {
      var modeler = this;
      event.preventDefault();
      modeler.cancelModal()
    };

    cancelModal = () => {
      var modeler = this;
      modeler.setState({showPalette: false, showInspector: false})
    };

    showPalette = () => {
      this.setState({showPalette: true, palettePosition: {x: 10, y: 80}});
    };

    handleDeleteSelectedFields = () => {
      var modeler = this;
      Clipboard.deleteSelectedFields(modeler);
    };

    render() {
      var self = this;
      var width;
      if (this.state.resolution == "x-small") {
        width = 576;
      } else if (this.state.resolution == "small") {
        width = 768;
      } else if (this.state.resolution == "medium") {
        width = 992;
      } else {
        width = 1400;
      }


        var style = {width: width};
        var isRedoStack = this.state.redoStack.length != 0;
        var isUndoStack = this.state.undoStack.length != 0;
        var isSavePossible = !this.props.readOnly && !this.isSaved();
        var autolayout = [];
        if (Array.isArray(this.state.form.autolayout)) {
          autolayout = this.state.form.autolayout;
        }
        var definedResolutions = ["x-small", "small", "medium", "large"].filter(function(resolution) {
          return MC.hasLayout(self.state.form, resolution)
        });
        var palette;
        var inspector;
        var modal;
        var container = $("#modeler .modelerContainer");
        if (this.state.showPalette) {
          var positionX = this.state.palettePosition.x;
          var positionY = this.state.palettePosition.y;
          var position = {x: Math.min(positionX, container.width() - 630), y: Math.min(positionY, container.height() - 540)}
          palette = <Palette position={position}
                             flow={this.state.form.flow}
                             widgetModelLoaded={this.state.widgetModelLoaded}/>;
                           modal = <div className="modal" onClick={this.handleCancelModal} onContextMenu={this.handleCancelModal}/>
        }
        if (this.state.showInspector) {
          var positionX = this.state.inspectorPosition.x;
          var positionY = this.state.inspectorPosition.y;
          var modelerWidth = container.width();
          var inspectorWidth;
          var large;
          if (modelerWidth < 850) {
            inspectorWidth = 450;
            large = false;
          } else {
            inspectorWidth = 850;
            large = true;
          }
          var position = {x: Math.min(positionX, modelerWidth - inspectorWidth), y: Math.min(positionY, container.height() - 700)}
          inspector = <Inspector
                       position={position}
                       width={inspectorWidth}
                       large={large}
                       model={this.state.form.model}
                       top={this.state.inspectorTop}
                       loaded={this.state.widgetModelLoaded && this.state.formDefinitionLoaded}
                       inspectedFields= {this.selectedFields()}
                       onSetSelectedField={this.setSelectedField}
                       onDeleteSelectedFields={this.handleDeleteSelectedFields}
                       onSetForm={this.setForm}
                       onStoreForm={this.store}
                       onGetFormCopy={this.getFormCopy}
                       onFormWasChanged={this.formWasChanged}
                       onCancelChange={this.cancel}/>
           modal = <div className="modal" onClick={this.handleCancelModal}  onContextMenu={this.handleCancelModal}/>
        }
        var errorMessage;
        if (this.state.errorMessage) {
          errorMessage = <div className="ui negative message">
                            <i className="close icon" />
                            <div className="header">
                                Chyba ukládání
                            </div>
                        </div>;
        }
        var modelerClasses = "meta column grow";
        if (this.state.structuralMode) {
           modelerClasses += " formModelerStructuralMode";
        }
        const element = document.getElementById("content-modeler")
        return (
          <div id="modeler" className={modelerClasses}>
            {errorMessage}
            <Menu onUndo={this.handleUndo}
                  onRedo={this.handleRedo}
                  onSave={this.handleSave}
                  onTongueGhostMode={this.handleTongueGhostMode}
                  tongueStructuralMode={this.tongueStructuralMode}
                  onChangeResolution={this.handleChangeResolution}
                  onDeleteGrid={this.handleDeleteGrid}
                  onCreateGrid={this.handleCreateGrid}
                  resolution={this.state.resolution}
                  setSelectedFields={this.setSelectedFields}
                  form={this.state.form}
                  definedResolutions={definedResolutions}
                  isSavePossible={isSavePossible}
                  saveInProcess={this.state.saveInProcess}
                  isUndoStack={isUndoStack}
                  isRedoStack={isRedoStack}
                  ghostMode={this.state.ghostMode}
                  modelerState={this.state}
                  modelerSetState={this.setState}
                  showPalette={this.showPalette}
                  autolayout={autolayout}/>
            {palette}
            {inspector}
            {modal}
            <div className="modelerContainer meta horizontal scroll grow column" onContextMenu={this.handleContextMenu}>
              <div style={style}
                   id="innerModeler"
                   className="meta paper grow column left component"
                   onClick={this.handleClick}>
                <Form form={this.state.form} element={element} embedded={this.props.embedded} runReady={true}/>
              </div>
            </div>
          </div>);
        }
}

export {Modeler}