import React from "react"
import ReactDOM from "react-dom"
import Decimal from "decimal.js"
import ReactRangeSlider from 'react-rangeslider'
import NumberFormat from 'react-number-format'

import {MC} from './MC.js'

import "./Slider.css"

class Slider extends React.Component {

  state = {value: MC.getFieldParamValue(this.props.data.param, 'value')}

  constructor(props) {
    super(props)
    this.initValue(this.props, false)
  }

  componentDidUpdate(prevProps) {
    if (!MC.objectEqual(prevProps.data.param, this.props.data.param)) {
      this.initValue(this.props, true)
    }
    if (this.props.focused) {
      const formatter = MC.getFieldParamValue(this.props.data.param, '@formatType')
      const disableFormatOnEdit = MC.getFieldParamBooleanValue(this.props.data.param, '@disableFormatOnEdit')
      if (!MC.isNull(formatter) && disableFormatOnEdit) { // texfield was probably replaced in DOM and must be refocused
        const input = ReactDOM.findDOMNode(this.refs.valueInput)
        if (input) {
          input.focus()
        }  
      }
    }  
  }

  initValue(props, toState) {
    let value = MC.getFieldParamValue(props.data.param, 'value')
    if (!MC.isModelerActive(props.data)) {
      if (MC.isNumeric(value)) {
        let min = new Number(MC.getFieldParamValue(this.props.data.param, '@min')).valueOf()
        if (MC.isNumeric(min) && value < min) {
          MC.error('Value of slider (' + value + ') can not be less than minimum (' + min + ')!')
          return
        }
        let max = new Number(MC.getFieldParamValue(this.props.data.param, '@max')).valueOf()
        if (MC.isNumeric(max) && value > max) {
          MC.error('Value of slider (' + value + ') can not be greater than maximum (' + max + ')!')
          return
        }
      } else {
        MC.error('Initial value of slider must be defined!')
      }
    }
    if (toState) {
      this.setState({value: value})
    }
  }

  handleChange = (value) => {
    this.putValue(value)
  }

  updateTextChange = (e) => {
    let value = e.target.value
    if (!MC.isNull(value)) {
      value = value.replace(/(?!-)[^0-9., ]/g, "")
      value = value.replace(/\s+/g, '')
      value = value.replace(",", ".")
      value = new Number(value).valueOf()
      if (["integer", "int", "long", "short"].indexOf(this.props.data.basictype) > -1) {
        value = Math.round(value)
      }
      let min = new Number(MC.getFieldParamValue(this.props.data.param, '@min')).valueOf()
      if (MC.isNumeric(min) && value < min) {
        value = min
      }
      let max = new Number(MC.getFieldParamValue(this.props.data.param, '@max')).valueOf()
      if (MC.isNumeric(max) && value > max) {
        value = max
      }
    } else {
      value = null
    }
    this.putValue(value)
    this.props.onBlur()
  }

  handleTextChange = (e) => {
    let formatter = MC.getFieldParamValue(this.props.data.param, '@formatType')
    const disableFormatOnEdit = MC.getFieldParamBooleanValue(this.props.data.param, '@disableFormatOnEdit') // for numeric keyboard on mobile phone
    if (formatter == 'number' && (!disableFormatOnEdit || !this.props.focused)) {
      this.setState({value: e.value})
    } else {
      this.setState({value: e.target.value})
    }
  }

  doStep(up) {
    let step = MC.getFieldParamValue(this.props.data.param, '@step')
    let value = this.state.value
    if (!MC.isNumeric(value)) {
      value = 0
    }
    if (!MC.isNumeric(step)) {
      step = 1
    }
    if (up) {
      value = Decimal.add(value, step)
      let max = MC.getFieldParamValue(this.props.data.param, '@max')
      if (MC.isNumeric(max) && value.greaterThan(max)) {
        value = new Decimal(max)
      }
    } else {
      value = Decimal.sub(value, step)
      let min = MC.getFieldParamValue(this.props.data.param, '@min')
      if (MC.isNumeric(min) && value.lessThan(min)) {
        value = new Decimal(min)
      }
    }
    this.putValue(value.toFixed())
  }

  putValue(newValue) {
    MC.putFieldParamValue(this.props.data.param, 'value', newValue)
    this.setState({value: newValue})
    MC.handleEvent(this.props.data, 'change')
  }

  render() {
    let min = new Number(MC.getFieldParamValue(this.props.data.param, '@min')).valueOf()
    let max = new Number(MC.getFieldParamValue(this.props.data.param, '@max')).valueOf()
    let step = MC.getFieldParamValue(this.props.data.param, '@step')
    if (MC.isNumeric(step)) {
      step = new Number(step).valueOf()
    } else {
      step = 1
    }
    let valueStyle = MC.getFieldParamValue(this.props.data.param, '@valueStyle')
    let valuePosition = MC.getFieldParamValue(this.props.data.param, '@valuePosition')
    if (['left', 'right', 'top', 'bottom'].indexOf(valuePosition) < 0) {
      valuePosition = 'right'
    }
    let asStepper = MC.getFieldParamBooleanValue(this.props.data.param, '@showAsStepper')
    let valueLabel = null
    let growValueCss = ""
    if (asStepper && ['left', 'right'].indexOf(valuePosition) > -1) {
      growValueCss = " flex-grow"
    }
    if (['readonly', 'textbox'].indexOf(valueStyle) > -1) {
      let value = this.state.value
      let cls
      if (valueStyle === 'readonly') {
        cls = "value " + valuePosition + growValueCss
      } else if (valueStyle === 'textbox') {
        cls = "valueInput " + valuePosition + growValueCss
        if (this.props.focused) {
          cls += " focused"
        }
      }
      const formatter = MC.getFieldParamValue(this.props.data.param, '@formatType')
      const disableFormatOnEdit = MC.getFieldParamBooleanValue(this.props.data.param, '@disableFormatOnEdit') // for numeric keyboard on mobile phone
      if (formatter == 'number' && (!disableFormatOnEdit || !this.props.focused)) {
        const displayType = valueStyle === 'readonly' ? 'text' : 'input'
        let decimalScale = Number(MC.getFieldParamValue(this.props.data.param, '@decimalScale'))
        if (MC.isNull(decimalScale)) {
          decimalScale = 2
        }
        const prefix = MC.getFieldParamValue(this.props.data.param, '@prefix')
        const suffix = MC.getFieldParamValue(this.props.data.param, '@suffix')
        valueLabel = <div className={cls}><NumberFormat ref="valueNumberFormat" key="valueInput" value={value} isNumericString={true} onFocus={this.props.onFocus} onBlur={this.updateTextChange}
                       decimalSeparator="," thousandSeparator=" " decimalScale={decimalScale} onValueChange={this.handleTextChange} prefix={prefix || ''} suffix={suffix || ''}
                       displayType={displayType}/></div>
      } else {
        if (valueStyle === 'readonly') {
          valueLabel = <div className={cls}>{value}</div>
        } else if (valueStyle === 'textbox') {
          const field = this.props.data
          const type = ['integer', 'int', 'long', 'short', 'byte', 'decimal', 'double', 'float'].indexOf(field.basictype) > -1 ? 'number' : 'text'
          const step = ['decimal', 'double', 'float'].indexOf(field.basictype) > -1 ? '0.01' : null
          valueLabel = <div className={cls}><input type={type} step={step} ref="valueInput" value={value} onBlur={this.updateTextChange} onChange={this.handleTextChange} onFocus={this.props.onFocus} key="valueInput"/></div>
        }
      }
    }
    let slider
    if (asStepper) {
      let plusCss = "right attached ui button"
      let minusCss = "ui left attached button"
      if (this.state.value >= max) {
        plusCss += " disabled"
      }
      if (this.state.value <= min) {
        minusCss += " disabled"
      }
      slider = (<div className="stepper-buttons">
                  <button className={minusCss} type="button" onClick={this.doStep.bind(this, false)}>-</button>
                  <button className={plusCss} type="button" onClick={this.doStep.bind(this, true)}>+</button>
                </div>)
    } else {
      let leftText = MC.getFieldParamValue(this.props.data.param, '@leftText')
      let rightText = MC.getFieldParamValue(this.props.data.param, '@rightText')
      let texts = {}
      if (!MC.isNull(leftText)) {
        texts[min] = leftText
      }
      if (!MC.isNull(rightText)) {
        texts[max] = rightText
      }
      let sliderCls = null
      if (['left', 'right'].indexOf(valuePosition) > -1) {
        sliderCls = "flex-grow"
      }
      let tooltip = MC.getFieldParamBooleanValue(this.props.data.param, '@valueTooltip')
      let value = new Number(this.state.value).valueOf()
      slider = <div className={sliderCls}><ReactRangeSlider onChange={this.handleChange} min={min} max={max} step={step} tooltip={tooltip} value={value} labels={texts}/></div>
    }
    let conatinerCls = "slider"
    if (['left', 'right'].indexOf(valuePosition) > -1) {
      conatinerCls += " flex-row flex-no-wrap"
    }
    if (['left', 'top'].indexOf(valuePosition) > -1) {
      return (
        <div className={conatinerCls} data-widget-i-name={this.props.data.id}>
          {valueLabel}
          {slider}
        </div>
      )
    } else {
      return (
        <div className={conatinerCls} data-widget-i-name={this.props.data.id}>
          {slider}
          {valueLabel}
        </div>
      )
    }
  }

}

export {Slider}