import {DateTime} from "luxon"

import {MC} from './MC.js'

const MCHistory = {

  historyArr: [],
  logArr: [],
  flowInstance: {},
  T_ERROR: 'ERROR',
  T_EXCEPTION: 'EXCEPTION',
  T_WARNING: 'WARNING',
  T_INFO: 'INFO',

  log: function(type, text, debug) {
    if (MC.isNull(type) || MC.isNull(text)) {
      console.error("Bad log function calling!")
    }
    if (debug) {
      this.logArr.push({type: type, mess: text, time: new Date()})
      if (type == this.T_ERROR) {
        console.error(this.T_ERROR + ' - ' + (new Date()).toISOString() + ': ' + text)
      } else if (type == this.T_EXCEPTION) {
        console.error(this.T_EXCEPTION + ' - ' + (new Date()).toISOString() + ': ' + text)
      } else if (type == this.T_WARNING) {
        console.warn(this.T_WARNING + ' - ' + (new Date()).toISOString() + ': ' + text)
      } else {
        console.log(this.T_INFO + ' - ' + (new Date()).toISOString() + ': ' + text)
      }
    }
  },

  history: function(flowObject, action, text, logObject, opts) {
    if (MC.isNull(flowObject)) {
      console.error("Bad log function calling!")
    }
    if (flowObject.debug('BASIC')) {
      this.putFlowInstanceInfo(flowObject)
      let flow = flowObject.flow
      let record = {}
      record.path = MCHistory.getFlowPath(flowObject)
      if (opts) {
        if (opts.start) {
          record.start = MC.luxonToDateTimeString({v: DateTime.fromMillis(opts.start)})
        }
        if (opts.end) {
          record.end = MC.luxonToDateTimeString({v: DateTime.fromMillis(opts.end)})
        }
        if (opts.duration) {
          record.duration = opts.duration.toFixed(3) + " ms"
        }
      }
      if (flow) {
        record.flow = flow.id
        record.flowKind = flow.kind
      }
      if (!MC.isNull(action)) {
        record.action = action.code
        record.kind = action.kind
      } else if (flow) {
        record.kind = flow.kind
      }
      if (logObject && logObject.target) {
        if (record.kind == 'call' || record.kind == 'dialog') {
          record.target = {name: logObject.target.id, component: logObject.target.model, type: 'operation', kind: logObject.target.kind}
        } else if (record.kind == 'form') {
          record.target = {name: logObject.target.id, component: logObject.target.model, type: 'form', kind: 'form'}
        } 
        delete logObject.target
      }
      record.text = text
      record.instanceId = flowObject.instanceId
      if (logObject && logObject.executionId) {
        record.executionActionId = logObject.executionId
        delete logObject.executionId
      }
      if (!MC.isNull(logObject) && MC.isPlainObject(logObject) && flowObject.debug('DETAIL')) {
        record.logObject = {}
        for (let root in logObject) {
          if (!MC.isNull(logObject[root])) { // filter null objects
            record.logObject[root] = {}
            MC.extend(true, record.logObject[root], logObject[root])
          }
        }
      }
      this.historyArr.push(record)
      console.log((record.path ? record.path + ' / ' : '') + record.flow + (record.action ? ' / ' + record.action : '')  + ' (' + record.kind + ') - ' + record.duration + ' (' + record.end + ')' + (record.text ? ' - ' + record.text : ''))
      if (!MC.isNull(record.logObject) && flowObject.debug('DETAIL')) {
        for (let root in record.logObject) {
          console.log('       ' + root + ':', record.logObject[root])
        }
      }
    }
  },

  putFlowInstanceInfo(flowObject) {
    if (!this.flowInstance[flowObject.instanceId]) {
      let operation = flowObject.flow ? {name: flowObject.flow.id, component: flowObject.flow.model, id: flowObject.flow.rbsid, kind: flowObject.flow.kind} : null
      let finfo = {flowConfiguration: flowObject.confPath, operation: operation, authenticatedUser: flowObject.env.system ? flowObject.env.system.userLoginId : null, parentExecutionId: flowObject.parentFlow ? flowObject.parentFlow.instanceId : null}
      this.flowInstance[flowObject.instanceId] = finfo
    }
  },

  getHistory: function() {
    return this.historyArr
  },

  getLog: function() {
    return this.logArr
  },

  clear: function() {
    this.historyArr = []
    this.logArr = []
    this.flowInstance = {}
    console.clear()
  },

  getFlowPath: function(flow) {
    if (flow.parentFlow) {
      var result = ''
      var sep = ''
      while (flow.parentFlow) {
        result =  flow.parentFlow.flow.id + sep + result
        sep = ' / '
        flow = flow.parentFlow
      }
      return result
    } else {
      return null
    }
  },

  listFLowLog: function(executionId) {
    let res = {executionId: executionId}
    if (this.flowInstance[executionId]) {
      res = MC.extend(res, this.flowInstance[executionId])
    }
    res.executedAction = []
    for (let rec of this.historyArr) {
      if (rec.instanceId == executionId) {
        let act = {action: {kind: rec.kind, name: rec.action}, executionId: [rec.executionActionId], start: rec.start, end: rec.end, duration: rec.duration, message: [rec.text]}
        if (rec.target) {
          act.action.target = rec.target
        }
        if (rec.logObject) {
          if (rec.logObject.Input) {
            act.input = {value: MC.objectToXML(rec.logObject.Input, 0)}
          }
          if (rec.logObject.Output) {
            act.output = [MC.objectToXML(rec.logObject.Output, 0)]
          }
          if (rec.logObject['Server log']) {
            act.serverSide = {flowId: rec.logObject['Server log'].flowId, flowLogId: rec.logObject['Server log'].flowLogId}
          }
          if (rec.logObject['Tested branches']) {
            act.condition = []
            for (let name in rec.logObject['Tested branches']) {
              act.condition.push({name: name, value: rec.logObject['Tested branches'][name].result, trace: rec.logObject['Tested branches'][name].trace})
            }
          }
          if (rec.logObject['Evaluated conditions']) {
            act.condition = []
            for (let name in rec.logObject['Evaluated conditions']) {
              act.condition.push({name: name, value: rec.logObject['Evaluated conditions'][name].result.result, trace: rec.logObject['Evaluated conditions'][name].trace})
            }
          }
          if (rec.logObject.Trace) {
            let mapping = []
            for (let path in rec.logObject.Trace) {
              mapping.push({target: path, value: MC.objectToXML(rec.logObject.Trace[path].result, 0), trace: rec.logObject.Trace[path]})
            }
            act.input = {value: act.input ? act.input.value : null, mapping: mapping} 
          }
        }
        res.executedAction.push(act)
      }
    }
    return res
  }

}

export {MCHistory}