import React from "react"
import ReactDOM from "react-dom"

import {FieldDef} from "../modeler/FieldDef.js"

import Dimmer from './semantic-widgets/Dimmer.jsx'
import {MC} from "./MC.js"
import {MCHistory} from "./MCHistory.js"
import {MCCache} from "./MCCache.js"
import {FlowInput} from "./FlowInput.jsx"
import {LogConsole} from "./LogConsole.jsx"
import {Dialog} from "./Dialog.jsx"
import {Form} from "./Form.jsx"
import {Flow} from "./Flow.js"
import {Message} from "./Message.jsx"

import './semantic-custom.css'
import './miniclient.css'

class ReactFlow extends React.Component {

  static flowTemplate = 'miniapp/def/{configuration}?id={flowId}&name={flowName}&lang={lang}'
  static flowServerUrl = 'miniapp/api/'
  flow = null
  domElement = null
  state = {dimmer: false, showLogConsole: false, showNoFE: false, inputData: null, debugBarPos: 'right', ...this.calculateStateFromProps(this.props)}
  children = {}
  destroyedChildren = {}

  componentDidMount() {
    if (this.props.parent && this.props.emdialogId) {
      this.props.parent.children[this.props.emdialogId] = this
    } else {
      window.addEventListener("beforeunload", this.onUnload, false)
    }
    if ((this.props.app || mncStartConfiguration && mncStartConfiguration.flowName) && !this.props.parent) { // routing only in app mode and root flow
      let base = document.querySelector('base').href.split('?')[0]
      base = base.substring(0, base.lastIndexOf("/") + 1)
      document.querySelector('base').href = base
      const currRi = window.location.href.substring(base.length)
      if (!window.history.state) {
        window.history.pushState({miniurl: currRi}, '', base + currRi)
      }
      window.onpopstate = this.onBackOrForwardButtonEvent
    }
    this.domElement = ReactDOM.findDOMNode(this.refs.container)
    this.startFlow()
  }

  componentDidUpdate(prevProps) {
    if (!MC.objectEqual(prevProps, this.props, true, ['flow', 'fields'])) {
      this.setState(this.calculateStateFromProps(this.props))
    }
    this.startFlow()
  }

  componentWillUnmount() {
    if (this.props.emdialogId && this.state.state === 'form') { // store state of form in em dialog before destructing resize
      this.props.parent.destroyedChildren[this.props.emdialogId] = {formData: this.state.formData, destroyedChildren: this.destroyedChildren, flow: this.flow}
    }
    this.flow = null
    if (!this.props.parent || !this.props.emdialogId) {
      window.removeEventListener("beforeunload", this.onUnload)
    } 
  }

  componentDidCatch(error) {
    if (this.flow) {
      this.flow.endOperationException('SYS_UnrecoverableRuntimeExc', error)
    } else {
      MC.error(error)
    }
  }

  calculateStateFromProps(props) {
    if (props.savedState) {
      let state = props.savedState
      if (this.flow == state.flow) {
        return
      }
      this.flow = state.flow
      this.flow.reactFlowObj = this
      if (state.destroyedChildren) {
        this.destroyedChildren = state.destroyedChildren
      }
      const loader = ['init', 'none'].indexOf(props.loader) > -1 ? props.loader : 'all'
      return {showInput: false, flowName: state.flow.flowName, configuration: state.flow.confPath, env: props.env, serverSide: props.serverSide, logLevel: props.debug,
        input: props.input, start: props.start, loader: loader, state: 'form', formData: state.formData, dimmer: false, runReady: false}
    } else {
      const configuration = props.configuration
      const flowName = props.flowName
      const showInput = !(configuration && flowName || MC.isPlainObject(props.configurationObject) &&  MC.isPlainObject(props.nsMap))
      const loader = ['init', 'none'].indexOf(props.loader) > -1 ? props.loader : 'all'
      return {showInput: showInput, flowName: flowName, configuration: configuration, env: props.env, serverSide: props.serverSide, logLevel: props.debug,
        input: props.input, runReady: false, start: props.start, loader: loader}
    }
  }

  startFlow(routing = false) {
    if (this.state.start || routing) {
      if (this.state.start) {
        this.setState({start: false})
      }
      if (this.flow) {
        this.flow.isRenderingInterupted = true
      }
      var flow = new Flow(this);
      if (MC.isPlainObject(this.props.configurationObject) || MC.isPlainObject(this.props.nsMap)) {
        flow.setFlowConfigurationProps(this.props.configurationObject, this.state.configuration, this.state.flowName, this.props.nsMap)
      } else if (routing) {
        flow.setFlowConfigurationProps(this.flow.env.cfg || {}, this.state.configuration, this.state.flowName, this.flow.confNsMap)
      } else {  
        flow.setFlowConfiguration(this.state.configuration, this.state.flowName)
      }
      flow.setLang(MC.getLang())
      var env = this.state.env;
      if (env) {
        flow.setEnv(env);
      }
      if (this.state.serverSide) {
        flow.setServerSide();
      }
      if (this.props.onEndFunction) {
        flow.setOnEndFunction(this.props.onEndFunction);
      }
      if (this.props.afterRenderFormFunction) {
        flow.setAfterRenderFormFunction(this.props.afterRenderFormFunction);
      }
      flow.setWantedLogLevel(this.state.logLevel)
      flow.loadAndStart(this.state.input, null)
      this.flow = flow
    }
  }

  showLogConsole = () => {
    this.setState({showLogConsole: true, start: false, runReady: false});
  };

  hideLogConsole = () => {
    this.setState({showLogConsole: false, start: false, runReady: false});
    document.querySelector('body').classList.remove('showing-modal')
  };

  toggleLogConsole = () => {
   if (this.state.showLogConsole) {
     this.hideLogConsole()
   } else {
     this.showLogConsole()
   }
  }  

  showInputPanel = () => {
    this.setState({showInput: true, showLogConsole: false, start: false, runReady: false});
  };

  clearLog = () => {
    MCHistory.clear()
    MCCache.clear()
  }

  clickRunFlow = (configuration, flowName, inputData, showNoFE, serverSide, logLevel) => {
    var env = {};
    var input = {};
    if (inputData) {
      var inputDataObj = MC.xmlToJson(MC.parseXml(inputData));
      if (inputDataObj.data) {
        if (inputDataObj.data.env && MC.isPlainObject(inputDataObj.data.env) && !MC.isEmptyObject(inputDataObj.data.env)) {
          env = inputDataObj.data.env;
        }
        if (inputDataObj.data.input) {
          input = inputDataObj.data.input;
        }
      }
    }
    var showInput = true;
    var start = false;
    if (configuration && flowName) {
      MCHistory.clear();
      MCCache.clear();
      showInput = false;
      start = true;
    }
    this.setState({configuration: configuration, flowName: flowName, inputData: inputData, input: input, showNoFE: showNoFE, serverSide: serverSide, logLevel: logLevel, showInput: showInput, env: env, start: start});
  };

  routeTo = (e, url) => {
    if (this.state.dialog) { // destroy opened dialog
      document.querySelector('body').classList.remove('showing-modal')
      this.flow.endDialog(null, null, true)
    }
    if (this.props.parent) {  
      this.props.parent.routeTo(e, url)
      return
    }
    if (e) {
      e.preventDefault()
    }
    let isRouting = false
    if (this.props.app || mncStartConfiguration && mncStartConfiguration.flowName) {
      if (url.indexOf('://') < 0) {
        if (url == "/") { // app space root
          url = ""
        }
        const base = document.querySelector('base').href.split('?')[0]
        const utils = new MC.URLUtils(url, base)
        const target = utils.protocol + '//' + utils.host + utils.href
        if (target.startsWith(base)) { // routing only links in appplication space, other links are normally navigated by browser
          isRouting = true
          this.isChangedAsPromise(url).then((changed) => {
            if (changed && !confirm(MC.formatMessage('beforeleave'))) {
              return
            } else {
              const currRi = window.location.href.substring(base.length)
              url = MC.ensureSystemParameters(currRi, url)
              if (!window.history.state || url !== currRi) {
                window.history.pushState({miniurl: url}, '', base + url)
              } 
              if (this.props.app) {
                this.props.app.routeTo(url)
              } else {
                this.startFlow(true)
              }
              return
            }
          })
        }
      }
    }
    if (!isRouting) {
      window.location.href = url
    }
  }

  isChanged = (url) => {
    if (this.state.state == 'form' && this.state.formData && this.state.formData.param) {
      MC.putFieldParamValue(this.state.formData.param, "@unloadLocation", url)
      this.state.formData.flow.eventForm(null, 'beforeunload')
      if (this.state.formData.param['@changed']) {
        return true
      }
    }
    if (!MC.isEmptyObject(this.children)) {
      for (let childKey in this.children) {
        if (this.children[childKey].isChanged(url)) {
          return true
        }
      }
    }
    return false
  }

  isChangedAsPromise = (url) => {
    let self = this
    return new Promise(function (resolve) {
      let promises = []
      if (self.state.state == 'form' && self.state.formData && self.state.formData.param) {
        MC.putFieldParamValue(self.state.formData.param, "@unloadLocation", url)
        let unloadPromiseObject = {}
        let promise = new Promise(function (resolve, reject) {
          unloadPromiseObject = {resolve: resolve, reject: reject}
        })
        self.unloadPromiseObject = unloadPromiseObject
        promises.push(promise)
        self.state.formData.flow.eventForm(null, 'beforeunload')
      }
      if (!MC.isEmptyObject(self.children)) {
        for (let childKey in self.children) {
          promises.push(self.children[childKey].isChangedAsPromise(url))
        }
      }
      if (promises.length > 0) {
        Promise.all(promises).then(function (results) {
          for (var i=0; i<results.length; i++) {
            if (results[i]) {
              resolve(true)
              return
            }
          }
          resolve(false)
        })
      } else {
        resolve(false)
      }
    })
  }

  resolveUnload(formData) {
    if (this.unloadPromiseObject) {
      this.unloadPromiseObject.resolve(formData.param['@changed'] ? true : false)
      this.unloadPromiseObject = null
    }
  }

  onUnload = (e) => {
    if (this.isChanged(null)) {
      let mess = MC.formatMessage('beforeleave')
      e.preventDefault()
      e.returnValue = mess
      return mess
    }
  }

  onBackOrForwardButtonEvent = (e) => {
    if (!e.state || e.state.miniurl == null) {
      return
    }
    if (this.props.app) {
      this.props.app.routeTo(e.state.miniurl)
    } else if (mncStartConfiguration && mncStartConfiguration.flowName) {
      this.startFlow(true)
    }
  }

  pickDestroyedChild = (id) => {
    if (!MC.isEmptyObject(this.destroyedChildren) && this.destroyedChildren[id]) {
      let res = this.destroyedChildren[id]
      delete this.destroyedChildren[id]
      return res
    }
    return false
  }

  moveDebugBar = () => {
    this.setState({debugBarPos: this.state.debugBarPos == 'left' ? 'right' : 'left'})
  }

  render() {
    var state = this.state.state;
    var content;
    if (state == 'exception') {
      if (this.flow.debug()) {
        var exception = this.state.exception;
        if (exception.message) {
          content = <div className="exceptionEnd">Exception <strong>{exception.type}</strong> thrown: {exception.message}</div>;
        } else {
          content = <div className="exceptionEnd">Exception <strong>{exception.type}</strong> thrown.</div>;
        }
      } else {
        content = <div className="exceptionEnd">Error!</div>;
      }
    } else if (state == 'output') {
      content = <div className="outputEnd"><strong>Output:</strong><br/> <pre>{JSON.stringify(this.state.output, null, 2)}</pre></div>;
    } else if (state == 'form') {
      FieldDef.setProto(this.state.formData)
      content = <Form form={this.state.formData} element={this.domElement} runReady={this.state.runReady} key="form" embedded={this.props.embedded}/>;
    }
    if (!content && this.state.dimmer) {
      content = <div><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p></div>
    }
    var inputPanel = null;
    var logConsole = null;
    if (this.props.debug && this.props.console) {
      if (this.state.showInput) {
        inputPanel = <div><FlowInput flowName={this.state.flowName} inputData={this.state.inputData} configuration={this.state.configuration} showNoFE={this.state.showNoFE} serverSide={this.state.serverSide}
                                onRun={this.clickRunFlow} configurationChanger={this.props.configurationChanger} logLevel={this.state.logLevel}/></div>
      } else {
        inputPanel = [];
        inputPanel.push(<button key="k1" className="ui icon button" onClick={this.showInputPanel}><i className="setting icon" title="Configuration panel"></i></button>);
        inputPanel.push(<button key="k2" className="ui icon button" onClick={this.toggleLogConsole}><i className="bug icon" title="Toggle log console"></i></button>);
        inputPanel.push(<button key="k3" className="ui icon button" onClick={this.clearLog}><i className="trash alternate icon" title="Clear log"></i></button>);
        if (this.state.formData && this.state.formData.flow && this.state.formData.flow.flow) {
          inputPanel.push(<a key="k4" className="ui icon button" href={'/' + this.state.formData.flow.flow.model + '/Form/' + this.state.formData.formId} target="_blank"><i className="paint brush icon" title="Open form in repository"></i></a>)
        }
        inputPanel = (
          <div className={MC.classes('mnc-debugbuttons', this.state.debugBarPos)}>
            <a className={MC.classes('ui blue top', this.state.debugBarPos == 'left' ? 'right' : 'left', 'attached label')} onClick={this.moveDebugBar}><i className={MC.classes('angle double', this.state.debugBarPos == 'left' ? 'right' : 'left', 'icon')}></i></a>
            {inputPanel}
          </div>)
      }
      logConsole = <LogConsole open={this.state.showLogConsole} onClose={this.hideLogConsole}/>;
    }
    let activeDimmer = this.state.dimmer;
    if (this.state.loader == 'none' || this.state.loader == 'init' && this.state.firstFormRendered) {
      activeDimmer = false
    }
    return (
      <div ref="container">
        {inputPanel}
        <div className={"dimmable" + (activeDimmer ? " dimmed" : "")}>
          <Dimmer className="inverted" active={activeDimmer}>
            <div className="ui text loader"></div>
          </Dimmer>
          {content}
          <Dialog key="modaldialog" dialog={this.state.dialog}/>
          <Message key="message" data={this.state.message}/>
        </div>
        {logConsole}
      </div> );
  }

}

if (!window.React) {
  window.React = React
}
if (!window.ReactDOM) {
  window.ReactDOM = ReactDOM
}

export {ReactFlow}