import React from "react"

import Dropdown from './semantic-widgets/Dropdown.jsx'
import Checkbox from "./semantic-widgets/Checkbox.jsx"
import {MC} from './MC.js'
import {MCCache} from './MCCache.js'
import {ReactFlow} from './ReactFlow.jsx'

class FlowInput extends React.Component {

  mounted = false
  state = {showNoFE: this.props.showNoFE, serverSide: this.props.serverSide, flowName: this.props.flowName, inputData: this.props.inputData,
    operationList: [], environment: null, configuration: this.props.configuration, logLevel: this.props.logLevel}

  componentDidMount() {
    const self = this
    MC.onRegisterReactRomponent(() => self.forceUpdate())
    if (self.state.configuration) {
      self.loadOperations(self.state.configuration, false, !self.state.showNoFE)
    }
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleSubmitWithInput = (e) => {
    e.preventDefault();
    this.run(true);
  };

  handleSubmitNoInput = (e) => {
    e.preventDefault();
    this.run(false);
  };

  run(withInput) {
    if (MC.isFunction(this.props.onRun)) {
      this.props.onRun(this.state.configuration, this.state.flowName, (withInput ? this.state.inputData : null), this.state.showNoFE, this.state.serverSide, this.state.logLevel);
    } else {
      MC.error('Run function is not set');
    }
  }

  changeConfigurationChar = () => {
    this.setState({configuration: this.refs.configuration.value});
  };

  changeConfiguration = (e) => {
    e.preventDefault();
    var configuration = this.refs.configuration.value;
    if (configuration == null || configuration.trim() == '') {
      alert('Please fill in Configuration!');
      return;
    }
    // test values
    if (configuration == 't1') {
      configuration = 'appmodeler;v=4/test';
    }
    this.setState({configuration: configuration, flowName: null, operationList: []});
    this.loadOperations(configuration, true, !this.state.showNoFE);
  };

  changeOperation = (e, combo) => {
    this.loadInput(combo.value);
    this.setState({flowName: combo.value});
  };

  changeInput = () => {
    this.setState({inputData: this.refs.inputData.value});
  };

  changeShowNoFE = (e, checkbox) => {
    this.setState({showNoFE: checkbox.checked});
    if (this.state.configuration) {
      this.loadOperations(this.state.configuration, true, !checkbox.checked);
    }
  };

  changeserverSide = (e, checkbox) => {
    this.setState({serverSide: checkbox.checked});
  };

  changeLogLevel = (event, data) => {
    this.setState({logLevel: data.value});
  };

  loadOperations(configuration, reload, feOnly) {
    var self = this;
    MC.getConfiguration(configuration, this.state.logLevel).then(function (conf) {
      if (conf && conf['fl:environmentOperation']) {
        MC.getEnvironmentContext(configuration, conf['fl:environmentOperation']).then(function(context) {
          if (!self.mounted) { return; }
          self.setState({environment: context});
          if (!reload && self.state.flowName && !self.state.inputData) {
            self.loadInput(self.state.flowName);
          }
        });
      }
      var url = ReactFlow.flowServerUrl + configuration;
      MC.callServer('GET', url, 'application/json').then(function (res) {
        let output = {};
        if (res.content) {
          output = JSON.parse(res.content);
        }
        if (res.status == 200 || res.status == 204) {
          if (output.props['fl:component']) {
            let groupList = [];
            let components = MC.asArray(output.props['fl:component']);
            for (let c=0; c<components.length; c++) {
              let component = components[c];
              if (!component['fl:operation']) {
                continue;
              }
              let operations = MC.asArray(component['fl:operation']);
              let operationList = [];
              for (let o=0; o<operations.length; o++) {
                let operation = operations[o];
                if (feOnly && operation.kind != 'frontend') {
                  continue;
                }
                operationList.push({title: operation.name + ' (' + operation.kind + ')', name: operation.name});
              }
              if (operationList.length > 0) {
                groupList.push({title: component.name, operationList: operationList});
              }
            }
            if (!self.mounted) { return; }
            self.setState({operationList: groupList});
          }
        } else {
          let message = '';
          if (output.errorName) {
            message += output.errorName + ': ';
          }
          message += 'Loading list operations failed! Status:' + res.status;
          if (output.errorMessage) {
            message += ' ' + output.errorMessage;
          }
          MC.error(message);
        }
      });
    });
  }

  getModelNsMap(items) {
    var result = {};
    for (var i=0; i<items.length; i++) {
      result[items[i]['prefix']] = items[i]['uri'];
    }
    return result;
  }

  getNsDefinition(nsMap) {
    var result = '';
    for (var pref in nsMap) {
      result += ' xmlns:' + pref + '="' + nsMap[pref] + '"';
    }
    return result;
  }

  getFlowDefinition(flowName) {
    const self = this;
    return new Promise(function(resolve, reject) {
      const url = ReactFlow.flowTemplate.replace('{configuration}', self.state.configuration).replace('{flowId}', '').replace('{flowName}', flowName).replace('{lang}', MC.getLang());
      const data = MCCache.get(url);
      if (data != null) {
        resolve(data);
      } else {
        MC.callServer('GET', url, MC.getJsonType()).then(function (result) {
          if (result.status == 200) {
            const def = JSON.parse(result.content);
            MCCache.put(url, def);
            resolve(def);
          } else {
            reject('Error in flow definition at RI ' + url  + '\n' + result.content);
          }
        });
      }
    });
  }

  loadInput(flowName) {
    var self = this;
    if (!flowName) {
      return;
    }
    this.setState({inputLoading: true});
    this.getFlowDefinition(flowName).then(function (flowData) {
      if (flowData.input) {
        var nsMap = {};
        self.getInputNsMap(flowData, self.getModelNsMap(flowData.ns), nsMap);
        if (!self.mounted) { return; }
        self.setState({inputData: '<data>\n  <input' + self.getNsDefinition(nsMap) + '>' + self.convertInputToXml(flowData, '    ') + '\n  </input>\n  <env>\n' + MC.objectToXML(self.state.environment, 2) + '  </env>\n</data>', inputLoading: false});
      } else {
        if (!self.mounted) { return; }
        self.setState({inputData: '<data>\n  <input>\n  </input>\n  <env>\n' + MC.objectToXML(self.state.environment, 2) + '  </env>\n</data>', inputLoading: false});
      }
    });
  }

  getInputNsMap(inputTree, nsMap, result) {
    for (var i=0; i<inputTree.input.length; i++) {
      var param = inputTree.input[i];
      var key = param.name;
      if (key.indexOf(':') > 0) {
        var pref = key.substring(0, key.indexOf(':'));
        if (!nsMap[pref]) {
          MC.error('Namespace with prefix ' + pref + ' is not defined!');
        }
        if (!result[pref]) {
          result[pref] = nsMap[pref];
        }
      }
      if (param.input) {
        this.getInputNsMap(param, nsMap, result);
      }
    }
  }

  convertInputToXml(inputTree, gaps) {
    var result = '';
    for (var i=0; i<inputTree.input.length; i++) {
      var param = inputTree.input[i];
      var key = param.name;
      if (key.endsWith('*')) {
        key = key.substring(0, key.length-1);
      }
      if (param.sample) {
        result += '\n' + gaps + '<'+ key + '>';
        result += param.sample;
        result += '</'+ key + '>';
      } else {
        for (var p = 0; p < 1; p++) {
          result += '\n' + gaps + '<'+ key + '>';
          if (param.input) {
            result += this.convertInputToXml(param, gaps + '  ');
            result += '\n' + gaps + '</'+ key + '>';
          } else {
            result += param.basictype;
            result += '</'+ key + '>';
          }
        }
      }
    }
    return result;
  }

  render() {
    var options = []
    options.push({value: '', text: ''}) 
    if (this.state.operationList.length > 0) {
      let data = this.state.operationList
      for (let d = 0; d < data.length; d++) {
        if (data[d].operationList.length > 0) {
          options.push({ value: '$$' + d, disabled: true, content: (<div className="headerText"><h5>{data[d].title}</h5></div>) })
          for (let i = 0; i < data[d].operationList.length; i++) {
            options.push({ value: data[d].operationList[i].name, text: data[d].operationList[i].title, content: (<React.Fragment>{data[d].operationList[i].title}</React.Fragment>) })
          }
        }
      }
    }
    var runable = (this.state.flowName ? true : false)
    let componentsHtml = null
    let extComponents = MC.getReactRomponents()
    if (!MC.isEmptyObject(extComponents)) {
      componentsHtml = (
        <div className="fields">
          <div className="ui sixteen wide field">Registered external widgets: {Object.keys(extComponents).join(', ')}</div>
        </div>)
    }
    return (
      <div className="ui icon message">
        <i className="setting icon"></i>
        <div className="content">
          <form className="ui form" id="inputForm" onSubmit={this.handleSubmitNoInput}>
            {componentsHtml}
            <div className="fields">
              <div className="ui ten wide field">
                <label htmlFor="configuration">Configuration</label>
                <div className="ui input">
                  <input type="text" name="configuration" ref="configuration" value={this.state.configuration ? this.state.configuration : ''} onChange={this.changeConfigurationChar} onBlur={this.changeConfiguration} disabled={!this.props.configurationChanger}/>
                </div>
              </div>
            </div>
            <div className="fields">
              <div className="ui eight wide field">
                <label htmlFor="flowName">Flow</label>
                <Dropdown className="selection" search value={this.state.flowName} options={options} onChange={this.changeOperation} 
                   disabled={this.state.operationList.length > 0 ? false : true} selectOnNavigation={false} selectOnBlur={false}/>
              </div>
              <div className="ui two wide field">
                <label>&nbsp;</label>
                <button className="ui icon button" onClick={this.changeConfiguration}>
                  <i className="refresh icon" title="Reload operation list"></i>
                </button>
              </div>
              <div className="ui four wide field">
                <label>All operations</label>
                <Checkbox toggle checked={this.state.showNoFE} onChange={this.changeShowNoFE}/>
              </div>
            </div>
            <div>
              <div className="ui field">
                <label htmlFor="inputData">Input data
                  <i className={"refresh icon mnc clickable" + (runable ? "" : " disabled") + (this.state.inputLoading ? " loading" : "")} title="Refresh default input data" onClick={() => this.loadInput(this.state.flowName)}></i>
                </label>
                <textarea name="inputData" ref="inputData" value={this.state.inputData ? this.state.inputData : ''} disabled={!runable} onChange={this.changeInput}></textarea>
              </div>
            </div>
            <div className="two inline fields">
              <div className="ui six wide field">
                <button className="ui right labeled icon button green" type="submit" ref="inputRun" disabled={!runable} onClick={this.handleSubmitNoInput}>
                  <i className="right play icon"></i>
                  Run
                </button>
                <button className="ui right labeled icon button teal" type="submit" ref="inputRunWithInput" disabled={!runable} onClick={this.handleSubmitWithInput}>
                  <i className="right play icon"></i>
                  Run with input
                </button>
              </div>
              <div className="ui five wide field">
                <label>Run on server</label>
                <Checkbox toggle checked={this.state.serverSide} onChange={this.changeserverSide}/>
              </div>
              <div className="ui five wide field">
                <label>Log level</label>
                <Dropdown className="selection" onChange={this.changeLogLevel} value={this.state.logLevel} selectOnNavigation={false} selectOnBlur={false} 
                  options={[{value: 'AUTO', text: 'AUTO (conf)'}, {value: 'MINIMAL', text: 'MINIMAL'}, {value: 'BASIC', text: 'BASIC'}, {value: 'DETAIL', text: 'DETAIL'}, {value: 'TRACE', text: 'TRACE'}]}/>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }

}

export {FlowInput};