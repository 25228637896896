import ReactDOM from "react-dom";

import {MC} from "../client/MC.js"

const Interactive = {
  handleMouseDown: function (field, event) {
    var modelerReact = this;
    modelerReact.fieldMouseDown = this;

    event.stopPropagation();
    if (!field.isInPalette) {
      modelerReact.setState({showPalette: false});
      if (event.shiftKey) {
        modelerReact.tongueSelectedFieldId(field.id);
      } else {
        modelerReact.setSelectedField(field.id);
      }

      console.log(field);
    }
  },
  handleMouseMove: function (field, targetReact, event) {
    var modelerReact = this;
    if (event.buttons != 0 && modelerReact.fieldMouseDown == this && !event.shiftKey) {
      if (modelerReact.isDragPossible && !modelerReact.state.interactiveField) {
        var interactiveField = Interactive.makeInteractiveField.bind(modeler)(field, event, targetReact);
        modelerReact.setState({showPalette: false,
                               interactiveField: interactiveField});
      }
    }
    if (!modelerReact.state.interactiveField) {
      if (Interactive.isResizePossible(event, field, targetReact)) {
        document.body.style.cursor = "ew-resize";
      } else {
        document.body.style.cursor = "move";
      }
      event.stopPropagation();
    }
  },
  get$widgetBBox: function(targetReact) {
    var $widget = $(ReactDOM.findDOMNode(targetReact));
    var $form = $("#modeler .ui.form");
    var offset = $widget.offset();
    var left = $widget.offset().left - $form.offset().left;
    var top = $widget.offset().top - $form.offset().top;
    var height = $widget.outerHeight();
    var width = $widget.outerWidth();
    return {left: left,
            top: top,
            height: height,
            width: width};
  },
  isResizePossible: function (event, field, targetReact) {
    var margin = 20;
    var $node = $(ReactDOM.findDOMNode(targetReact));
    var isRight = event.pageX > ($node.offset().left + $node.outerWidth() - margin);
    var isInTable = field.isInTable();
    var isInTabPanel = field.isInTabPanel();
    var isInPalette = field.isInPalette;
    return isRight && !isInTable && !isInPalette && !isInTabPanel;
  },
  makeInteractiveField: function(field, event, targetReact) {
    var modeler = this;
    var interactiveField = field.getCopy();
    interactiveField.bbox = Interactive.get$widgetBBox(targetReact);
    interactiveField.initPosition = {x: event.pageX, y: event.pageY};
    interactiveField.isInteractive = true;
    interactiveField.originalField = field;
    var isInPalette = field.isInPalette;
    if (isInPalette) {
      interactiveField.parent = modeler.state.form;
      interactiveField.grid = [];
      interactiveField.addGrid();
      delete interactiveField.isInPalette;
    }
    var isInTable = field.isInTable();
    if (event.shiftKey && !isInTable && !isInPalette) {
      interactiveField.offsetChange = true;
    } else if (Interactive.isResizePossible(event, field, targetReact)) {
      interactiveField.resizeChange = true;
    }

    if (interactiveField.offsetChange ||  interactiveField.resizeChange) {
      var initColumns = field.columns();
      var $node = $(ReactDOM.findDOMNode(targetReact));
      var initWidth = parseInt($node.outerWidth(true));
      interactiveField.columnWidth = initWidth / initColumns;
    }

    return interactiveField;
  },
  getInteractiveClasses: function(field) {
    var modeler = this;
    var cls = "";
    var isModelerActive = field.isModelerActive();
    var isInPalette = field.isInPalette;
    var isPlaceholder = field.isPlaceholder();
    var isInteractive = field.isInteractive;
    var isSelectedField = field.isSelectedField();

    if (isModelerActive && modeler.state.structuralMode) {
      cls += " formModelerStructuralMode";
    }
    if (isModelerActive && isPlaceholder && !isInteractive && !isInPalette) {
      cls += " formModelerPlaceholder";
    } else {
        if (isModelerActive) {
          if (modeler.state.structuralMode) {
            cls += " formModelerTinge";
          }
          if (isInteractive || (isSelectedField && !isInPalette)) {
            cls += " formModelerSelected";
          }
        }
    }
    return cls;
  }
};

export {Interactive};