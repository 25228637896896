import "@babel/polyfill"
import ReactDOM from "react-dom"
import "semantic-ui-offline/semantic.min.css"

import MiniclientConsole from "./MiniclientConsole.jsx"
import TestApp from "./TestApp.jsx"
import FormModeler from "./FormModeler.jsx"
import {MiniClientApp} from "./miniclient-app.js"

window.onload = function() {
  if (mncStartConfiguration && mncStartConfiguration.registerServiceWorker) {
    if ('serviceWorker' in navigator) { // only when browser supports SWs
      navigator.serviceWorker.register('sw.js').then(function() {
        console.log("Service worker sw.js registered")
      }).catch(error => {
        console.error("Error during service worker registration:", error)
      })
    }
  }
  if (mncStartConfiguration && mncStartConfiguration.isApp) {
    let mncapp = new MiniClientApp().fetchConfiguration(mncStartConfiguration.configuration).run()
  } else if (mncStartConfiguration && mncStartConfiguration.isTestApp) {
    ReactDOM.render(<TestApp/>, document.getElementById('content'))
  } else if (mncStartConfiguration && mncStartConfiguration.isFormModeler) {
    ReactDOM.render(<FormModeler/>, document.getElementById('content-modeler'))
  } else {
    ReactDOM.render(<MiniclientConsole/>, document.getElementById('content'))
  }
}